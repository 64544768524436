export const programCategory = [
  {
    "value": "INTERNSHIP",
    "name": "Magang",
    "color": "#633FB5"
  },
  {
    "value": "STUDENT_EXCHANGE",
    "name": "Pertukaran Mahasiswa",
    "color": "#4F8ADD"
  },
  {
    "value": "ENTREPRENEURSHIP",
    "name": "Kewirausahaan",
    "color": "#FF8811"
  },
  {
    "value": "HUMANITY",
    "name": "Proyek Kemanusiaan",
    "color": "#F41A51"
  },
  {
    "value": "COMMUNITY_SERVICE",
    "name": "Pembangunan Desa/KKN",
    "color": "#32AB6D"
  }
]