const LOAD_DETAIL_BERITA           = 'LOAD_DETAIL_BERITA'
const LOAD_DETAIL_BERITA_SUCCESS   = 'LOAD_DETAIL_BERITA_SUCCESS'
const LOAD_DETAIL_BERITA_FAILED    = 'LOAD_DETAIL_BERITA_FAILED'
const UNMOUNT_DETAIL_BERITA        = 'UNMOUNT_DETAIL_BERITA'
const initialState = {
  loading: true,
  data: null,
  message: null,
}
const beritaDetailReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_DETAIL_BERITA:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_DETAIL_BERITA_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_DETAIL_BERITA_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_DETAIL_BERITA:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}
export default beritaDetailReducer;