import React from 'react'
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import { Grid, Row, Col, Space, Typography, Image, Card, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import TP1 from '../../assets/img/home/tp-1.png';
import TP2 from '../../assets/img/home/tp-2.png';
import TP3 from '../../assets/img/home/tp-3.png';
import TP4 from '../../assets/img/home/tp-4.png';
import TP5 from '../../assets/img/home/tp-5.png';
import Ic1 from '../../assets/img/home/ic-1.png';
import Ic1A from '../../assets/img/home/ic-1-active.png';
import Ic2 from '../../assets/img/home/ic-2.png';
import Ic2A from '../../assets/img/home/ic-2-active.png';
import Ic3 from '../../assets/img/home/ic-3.png';
import Ic3A from '../../assets/img/home/ic-3-active.png';
import Ic4 from '../../assets/img/home/ic-4.png';
import Ic4A from '../../assets/img/home/ic-4-active.png';
import Ic5 from '../../assets/img/home/ic-5.png';
import Ic5A from '../../assets/img/home/ic-5-active.png';

const { Text } = Typography;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function AboutUs(props) {
  // const { xs, xl, xxl } = GetBreakPoint()
  const { xxl, xs } = GetBreakPoint()
  const { active, handleMenuProgram } = props

  const menu = [
    { key: '1', label: 'Kewirausahaan', img: TP1, link: `${Cookie.get('user') ? '/kandidat' : ''}/tentang-program/kewirausahaan`, icon: Ic1, iconA: Ic1A, text: 'Kebijakan Merdeka Belajar-Kampus Merdeka bertujuan untuk mendorong mahasiswa untuk mampu menguasai berbagai keilmuan sebelum memasuki dunia kerja. Dalam rangka menyiapkan mahasiswa dalam menghadapi tantangan perubahan sosial, budaya, dunia kerja dan kemajuan teknologi yang pesat, kompetensi mahasiswa juga harus disiapkan agar mampu beradaptasi dengan kebutuhan zaman. Kebijakan Merdeka Belajar-Kampus Merdeka diharapkan dapat menjadi jawaban atas tuntutan tersebut. Kampus Merdeka merupakan wujud pembelajaran di perguruan tinggi yang otonom dan fleksibel sehingga tercipta kultur belajar yang inovatif, tidak mengekang, dan sesuai dengan kebutuhan mahasiswa.' },
    { key: '2', label: 'Pertukaran Mahasiswa Merdeka', img: TP2, link: `${Cookie.get('user') ? '/kandidat' : ''}/tentang-program/pertukaran-mahasiswa-merdeka`, icon: Ic2, iconA: Ic2A, text: 'Merdeka Belajar-Kampus Merdeka memberikan peluang kepada mahasiswa untuk mencari pengalaman belajar di perguruan tinggi yang lain baik yang sesuai	dengan program studi maupun lintas program studi. Pengalaman belajar tersebut dapat dilakukan dengan menempuh pembelajaran di kampus lain dengan sistem pengakuan kredit (credit earning). Pengalaman pembelajaran lintas kampus diselenggarakan untuk membentuk beberapa sikap mahasiswa yang termaktub di dalam Peraturan Menteri Pendidikan dan Kebudayaan (Permendikbud) Nomor 3 Tahun 2020.' },
    { key: '3', label: 'Magang', img: TP3, link: `${Cookie.get('user') ? '/kandidat' : ''}/tentang-program/magang`, icon: Ic3, iconA: Ic3A, text: 'Salah satu bentuk kegiatan belajar di luar Perguruan Tinggi adalah magang/praktik kerja di industri atau tempat kerja lainnya. Selama ini mahasiswa kurang mendapat pengalaman kerja di industri/dunia profesi nyata sehingga kurang siap bekerja. Sementara magang yang berjangka pendek (kurang dari 6 bulan) sangat tidak cukup untuk memberikan pengalaman dan kompetensi industri bagi mahasiswa. Perusahaan yang menerima magang juga menyatakan magang dalam waktu sangat pendek tidak bermanfaat, bahkan mengganggu aktivitas di Industri. Kegiatan ini harus dilaksanakan dengan bimbingan dari dosen. Dengan adanya kegiatan ini diharapkan dapat memberikan pengalaman kontekstual lapangan yang nantinya dapat meningkatkan kompetensi mahasiswa secara utuh, siap kerja dan atau menciptakan lapangan kerja baru.' },
    { key: '4', label: 'Proyek Kemanusiaan', img: TP4, link: `${Cookie.get('user') ? '/kandidat' : ''}/tentang-program/proyek-kemanusiaan`, icon: Ic4, iconA: Ic4A, text: 'Salah satu bentuk kegiatan belajar di luar Perguruan Tinggi adalah proyek kemanusiaan dengan mitra baik dalam negeri maupun dari lembaga luar negeri. Selama ini mahasiswa kurang mendapat pengalaman kerja di lembaga mitra secara nyata sehingga kurang siap bekerja dan terjun ke lapangan. Sementara magang yang berjangka pendek (kurang dari 6 bulan) sangat tidak cukup untuk memberikan pengalaman dan kompetensi bagi mahasiswa. Mitra yang menerima magang dalam kurun waktu yang pendek kurang memberikan manfaat yang signifikan untuk Industri dan Mahasiswa yang bersangkutan. Kegiatan ini harus dilaksanakan dengan bimbingan dari Dosen.' },
    { key: '5', label: 'Membangun Desa / KKN', img: TP5, link: `${Cookie.get('user') ? '/kandidat' : ''}/tentang-program/membangun-desa-kkn`, icon: Ic5, iconA: Ic5A, text: 'Membangun Desa atau Kuliah Kerja Nyata (KKN) merupakan suatu bentuk pendidikan dengan cara memberikan pengalaman belajar kepada mahasiswa untuk hidup di tengah masyarakat di luar kampus, yang secara langsung bersama-sama masyarakat mengidentifikasi potensi dan menangani masalah sehingga diharapkan mampu mengembangkan potensi desa/daerah dan meramu solusi untuk masalah yang ada di desa. Kegiatan KKN diharapkan dapat mengasah softskill kemitraan, kerjasama tim lintas disiplin/keilmuan (lintas kompetensi), dan leadership mahasiswa dalam mengelola program pembangunan di wilayah perdesaan.' },
  ]
  return (
    <React.Fragment>
      <Row className={xs ? "mt-16" : "mt-32"}>
        <Col className="text-align-center" span={24}>
          <Space direction="vertical" size={16}>
            <Text className="fs-24 font-weight-500">Tentang Program</Text>
            <Text>Merdeka Belajar Kampus Merdeka atau yang biasa dikenal dengan sebutan MBKM merupakan kebijakan yang diterbitkan oleh Kementerian Pendidikan dan Kebudayaan Republik Indonesia. Kebijakan MBKM ini bertujuan untuk memberikan kesempatan bagi mahasiswa/i untuk mengasah kemampuan sesuai bakat dan minat mereka dengan terjun langsung ke dalam dunia kerja sebagai persiapan karir di masa depan. Tak hanya itu, program ini juga dilaksanakan untuk meningkatkan proses pembelajaran yang inovatif agar mahasiswa/i dapat meraih capaian pembelajaran mencakup aspek sikap, pengetahuan, dan keterampilan secara optimal.</Text>
          </Space>
        </Col>
        <Col className={xs ? "mt-32 mb-32" : "mt-64 mb-64"} span={24}>
          {
            xs ?
              <Space className="width-100 text-align-center" align='start'>
                {
                  menu.map(item => 
                    <Space key={item.key} className="cursor-pointer" direction="vertical" onClick={() => handleMenuProgram(item.key)}>
                      <Image
                        width={40}
                        height={40}
                        src={item.key === active ? item.iconA : item.icon}
                        preview={false}
                      />
                        <Text className={item.key === active ? "primary-color fs-11 line-height-05" : "light-color fs-11 line-height-05"}>{item.label}</Text>
                    </Space>
                  )
                }
              </Space>
            :
              <Row className="text-align-center">
                <Col span={2}></Col>
                {
                  menu.map(item => 
                    <Col key={item.key} span={4}>
                      <Space className="cursor-pointer" direction="vertical" onClick={() => handleMenuProgram(item.key)}>
                        <Image
                          width={40}
                          height={40}
                          src={item.key === active ? item.iconA : item.icon}
                          preview={false}
                        />
                          <Text className={item.key === active ? "primary-color" : "light-color"}>{item.label}</Text>
                      </Space>
                    </Col>
                    )
                }
                <Col span={2}></Col>
              </Row>
          }
        </Col>
        <Col span={24}>
          {
            xs ?
              menu.map((item,i) => 
                item.key === active ?
                  <Row key={i}>
                    <Col span={24}>
                      <Image
                        width={'100%'}
                        src={item.img}
                        preview={false}
                        style={{ objectFit: 'cover' }}
                      />
                    </Col>
                    <Col span={24}>
                      <Card bodyStyle={{ width: '100%' }}>
                              <Space key={item.key} direction="vertical" size={16}>
                                <Text className="fs-24 font-weight-500">{item.label}</Text>
                                <Text>{item.text}</Text>
                                <Link to={item.link}>
                                  <Button type="primary" style={{ height: 34, borderRadius: 16 }}>Lihat Detail <ArrowRightOutlined /></Button>
                                </Link>
                              </Space>
                      </Card>
                    </Col>
                  </Row>
                :
                  null
              )
            :
              menu.map((item,i) => 
                item.key === active ?
                  <Row key={i}>
                    <Col xxl={2} xl={1}></Col>
                    <Col xxl={9} xl={8} style={{ zIndex: 1 }}>
                      <Image
                        width={'100%'}
                        height={xxl ? 400 : 360}
                        src={item.img}
                        preview={false}
                        style={{ objectFit: 'cover' }}
                      />
                    </Col>
                    <Col xxl={11} xl={14}>
                      <Card style={{ marginTop: xxl ? 64 : 32, marginLeft: xxl ? -128 : -64, marginRight: xxl ? -128 : -64 }} bodyStyle={{ width: '100%', height: xxl ? 432 : 372, paddingTop: xxl ? 72 : 32, paddingLeft: xxl ? 192 : 112, paddingRight: xxl ? 64 : 48 }}>
                              <Space key={item.key} direction="vertical" size={16}>
                                <Text className="fs-24 font-weight-500">{item.label}</Text>
                                <Text>{item.text}</Text>
                                <Link to={item.link}>
                                  <Button type="primary" style={{ height: 34, borderRadius: 16 }}>Lihat Detail <ArrowRightOutlined /></Button>
                                </Link>
                              </Space>
                      </Card>
                    </Col>
                    <Col xxl={2} xl={1}></Col>
                  </Row>
              :
                null
            )
          }
        </Col>
      </Row>
    </React.Fragment>
  )
}