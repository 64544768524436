import React, { Component } from 'react';
import Cookie from 'js-cookie';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb, Button, Card, Space, Typography } from 'antd';
import { RightCircleFilled } from '@ant-design/icons';

const { Text } = Typography;

export default class MembangunDesaKkn extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'MBKM - Tentang Program - Membangun Desa / KKN');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Tentang Program - Membangun Desa / KKN</title>
        </Helmet>
        <Row>
          <Col span={24}>
            <Row className="pt-16 pb-64" gutter={[0,16]} style={{ width: '100%', backgroundColor: '#117137' }}>
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/" style={{ color: '#D9D9D9' }}>Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item style={{ color: '#D9D9D9' }}>Tentang Program</Breadcrumb.Item>
                  <Breadcrumb.Item style={{ color: '#FFFFFF' }}>Membangun Desa / KKN</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col className='mt-32' xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Card style={{ backgroundColor: 'transparent', borderStyle: 'none none none solid', borderLeft: '8px solid #FFFFFF' }} bodyStyle={{ padding: '40px 16px' }}>
                  <Space direction="vertical" size={0}>
                    <Text className="white-color fs-28">Program</Text>
                    <Text className="white-color fs-36 font-weight-bold">Membangun Desa / KKN</Text>
                  </Space>
                </Card>
              </Col>
            </Row>
            <Row className="mt-32">
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Space direction="vertical" size={16}>
                  <Text className="dark-color">Kebijakan Merdeka Belajar-Kampus Merdeka bertujuan untuk mendorong mahasiswa untuk mampu menguasai berbagai keilmuan sebelum memasuki dunia kerja. Dalam rangka menyiapkan mahasiswa dalam menghadapi tantangan perubahan sosial, budaya, dunia kerja dan kemajuan teknologi yang pesat, kompetensi mahasiswa juga harus disiapkan agar mampu beradaptasi dengan kebutuhan zaman. Kebijakan Merdeka Belajar-Kampus Merdeka diharapkan dapat menjadi jawaban atas tuntutan tersebut. Kampus Merdeka merupakan wujud pembelajaran di perguruan tinggi yang otonom dan fleksibel sehingga tercipta kultur belajar yang inovatif, tidak mengekang, dan sesuai dengan kebutuhan mahasiswa. Mahasiswa diberikan kebebasan mengambil SKS di luar program studi, tiga semester yang dimaksud berupa 1 semester kesempatan mengambil mata kuliah di luar program studi dan 2 semester melaksanakan aktivitas pembelajaran di luar perguruan tinggi.</Text>
                  <Text className="dark-color">Membangun Desa atau Kuliah Kerja Nyata (KKN) merupakan suatu bentuk pendidikan dengan cara memberikan pengalaman belajar kepada mahasiswa untuk hidup di tengah masyarakat di luar kampus, yang secara langsung bersama-sama masyarakat mengidentifikasi potensi dan menangani masalah sehingga diharapkan mampu mengembangkan potensi desa/daerah dan meramu solusi untuk masalah yang ada di desa. Kegiatan KKN diharapkan dapat mengasah softskill kemitraan, kerjasama tim lintas disiplin/keilmuan (lintas kompetensi), dan leadership mahasiswa dalam mengelola program pembangunan di wilayah perdesaan. Sejauh ini perguruan tinggi sudah menjalankan program KKN, hanya saja Satuan Kredit Semesternya (SKS) belum bisa atau dapat diakui sesuai dengan program kampus merdeka yang pengakuan kreditnya setara 6 – 12 bulan atau 20 – 40 SKS, dengan pelaksanaannya berdasarkan beberapa model. Diharapkan juga setelah pelaksanaan KKNT, mahasiswa dapat menuliskan hal-hal yang dilakukannya beserta hasilnya dalam bentuk tugas akhir.</Text>
                  <Text className="dark-color font-weight-bold">Tujuan program kegiatan membangun desa/KKN antara lain :</Text>
                  <ul>
                    <li>Memberikan kesempatan kepada mahasiswa untuk memanfaatkan ilmu pengetahuan, teknologi, dan keterampilan yang dimilikinya bekerjasama dengan banyak pemangku kepentingan di lapangan melalui keterlibatan mahasiswa</li>
                    <li>Membantu percepatan pembangunan di wilayah pedesaan bersama dengan Kementerian Desa, Pembangunan Daerah Tertinggal, dan Transmigrasi (PDTT)</li>
                  </ul>
                </Space>
              </Col>
            </Row>
            <Row className="mt-32 text-align-center">
              <Col xs={{ span: 24 }} lg={{ span: 10 , offset: 7 }} xxl={{ span: 6, offset: 9 }}>
                <Link className="dark-color" to={Cookie.get("user") ? "/kandidat/program" : "/daftar"}>
                  <Button type="primary" className="border-radius-8 text-align-left pt-16 pb-16 card-next-membangun">
                    <Row justify="space-around" align="middle">
                      <Col>
                        <Space direction='vertical' size={0}>
                          <Text className="white-color text-card-next-top">INGIN MENINGKATKAN KEPEDULIAN?</Text>
                          <Text className="white-color text-card-next-bottom">AYO DAFTARKAN DIRI KAMU</Text>
                        </Space>
                      </Col>
                      <Col className="text-align-right">
                        <RightCircleFilled className="text-card-next-icon" />
                      </Col>
                    </Row>
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
