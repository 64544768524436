
const LOAD_DATA_PROGRAM           = 'LOAD_DATA_PROGRAM'
const LOAD_DATA_PROGRAM_SUCCESS   = 'LOAD_DATA_PROGRAM_SUCCESS'
const LOAD_DATA_PROGRAM_FAILED    = 'LOAD_DATA_PROGRAM_FAILED'
const UNMOUNT_DATA_PROGRAM        = 'UNMOUNT_DATA_PROGRAM'
const initialState = {
  loading: true,
  meta: null,
  data: null,
  message: null,
}
const programReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_DATA_PROGRAM:
      return {
        ...state,
        loading: true,
        meta: null,
        data: null,
      }
    case LOAD_DATA_PROGRAM_SUCCESS:
      return { 
        ...state, 
        loading: false,
        meta: action.payload.meta,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_DATA_PROGRAM_FAILED:
      return { 
        ...state, 
        loading: true,
        meta: null,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_DATA_PROGRAM:
      return { 
        ...state, 
        loading: true,
        meta: null,
        data: null,
      }
    default:
      return state
  }
}
export default programReducer;