import React from 'react';
import moment from 'moment';
import 'moment/locale/id';
import { Row, Col, Typography, Card, Space, Divider, BackTop, Tabs, Image, Tag, Tooltip, Button, Modal, Form, Timeline, Empty } from 'antd';
import FormActivity from './formActivity'
import { programCategory } from '../../../../utils/constant/programCategory';
import EmptyLogo from '../../../../assets/img/EmptyLogo.png';
import { DownloadOutlined, FileTextOutlined, EditOutlined } from '@ant-design/icons';
import { courseCategory } from '../../../../utils/constant/courseCategory';

const { Text, Paragraph } = Typography;
const { TabPane } = Tabs;

export default function DetailStatusLamaranDesktop(props) {
  const {
    formRef,
    visible,
    visibleNotes,
    activeKey,
    handleCancel,
    showModal,
    showModalNotes,
    submitLoading,
    onFinish,
    status,
    handleTab,
    getDetail: { data }
  } = props

  return (
    <React.Fragment>
      <BackTop />
        <Row gutter={32}>
          <Col span={24}>
            <Card 
              className="mb-16 border-radius-6 box-shadow"
              bordered={false} 
            >
              <Row className="width-100">
                <Col span={22}>
                  <Space size={16}>
                    <Image
                      src={data.partnerLogoUrl ? data.partnerLogoUrl : EmptyLogo}
                      preview={false}
                      width={100}
                      height={100}
                      style={{ objectFit: 'contain' }}
                    />
                    <Space direction="vertical" size={0}>
                      <Text className="fs-15 dark-color" strong>{data.title}</Text>
                      <Text className="fs-15 dark-color">{data.partnerName}</Text>
                      <Text className="fs-15 dark-color">{data.cityName}, {data.countryName}</Text>
                    </Space>
                  </Space>
                </Col>
                <Col className="float-right" span={2}>
                  <Tag className="float-right" color="success">Aktif</Tag>
                </Col>
              </Row>

              <Divider />

              <Row className="mb-16">
                <Col span={12}>
                  <Space direction="vertical">
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12 dark-color">Kategori Program</Text>
                      <Text className="dark-color" strong>{programCategory.find(res => res.value === data.programCategory)?.name}</Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12 dark-color">Tanggal Kegiatan</Text>
                      <Text className="dark-color" strong>{data.startDate} - {data.endDate}</Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12 dark-color">Jumlah SKS</Text>
                      <Text className="dark-color" strong>{data.creditHour}</Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical">
                    {
                      data.positionsName ?
                        <Space direction="vertical" size={0}>
                          <Text className="fs-12 dark-color">Posisi Pilihan</Text>
                          <Text className="dark-color" strong>{data.positionsName}</Text>
                        </Space>
                      : null
                    }
                    {
                      data.fieldOfStudyName ?
                        <Space direction="vertical" size={0}>
                          <Text className="fs-12 dark-color">Jurusan Pilihan</Text>
                          <Text className="dark-color" strong>{data.fieldOfStudyName}</Text>
                        </Space>
                      : null
                    }
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12 dark-color">Durasi</Text>
                      <Text className="dark-color" strong>{data.duration} bulan</Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12 dark-color">Sertifikat</Text>
                      <Text className="dark-color" strong>{data.hasCertificate ? 'Tersedia' : 'Tidak Tersedia'}</Text>
                    </Space>
                  </Space>
                </Col>
              </Row>
            </Card>
            
            <Card 
              className="mb-16 border-radius-6 box-shadow"
              bordered={false}
            >
              <Tabs defaultActiveKey={activeKey} type='card' onTabClick={handleTab}>
                {
                  data.programDescription ?
                    <TabPane tab="Detail Program" key="1">
                      <Row className="mb-16">
                        <Col span={24}>
                          {
                            data.programDescription ?
                              <div style={{ color: '#5f6163' }} dangerouslySetInnerHTML={{ __html: data.programDescription }}></div>
                            :
                              null
                          }
                        </Col>
                      </Row>
                    </TabPane>
                  : null
                }
                {
                  data?.listDocument?.length > 0 ?
                    <TabPane tab="File Pendukung" key="2">
                      {
                        data?.listDocument?.map((item,i) => 
                          <Row key={i} className="mb-16">
                            <Col span={23}>
                              <Text className="primary-color" strong>{item.name}</Text>
                            </Col>
                            <Col span={1} className="float-right">
                              <Tooltip className="float-right" title="click to download file">
                                <a className="float-right" href={item.fileUrl} target="_blank" rel="noreferrer"><DownloadOutlined /></a>
                              </Tooltip>
                            </Col>
                            <Divider />
                          </Row>
                        )
                      }
                    </TabPane>
                  : null
                }
                {
                  status === 'diterima' ?
                    <TabPane tab="Mata Kuliah Konversi" key="3">
                      {
                        data?.listCourse?.map((item,i) => 
                          <Row key={i} className="mb-16">
                            <Col span={24}>
                              <Text className="primary-color" strong>{item.courseCode} - {item.courseName}</Text>
                            </Col>
                            <Col span={24}>
                              <Text strong>{courseCategory.find(res => res.value === item.courseCategory).name} - {item.courseSks}</Text>
                            </Col>
                            <Divider />
                          </Row>
                        )
                      }
                    </TabPane>
                  : null
                }
                {
                  status === 'diterima' ?
                    <TabPane tab="Pembimbing" key="4">
                      {
                        data?.listLecturer?.map((item,i) => 
                          <Row key={i} className="mb-16">
                            <Col span={24}>
                              <Text className="primary-color" strong>{`${item.lecturerFrontTitle ? `${item.lecturerFrontTitle}` : ''} ${item.lecturerFirstName} ${item.lecturerLastName}${item.lecturerBackTitle ? `, ${item.lecturerBackTitle}` : ''}`}</Text>
                            </Col>
                            <Col span={24}>
                              <Text strong>{item.lecturerNidn } - {item.lecturerInstitution} - {item.lecturerFieldOfStudy} </Text>
                            </Col>
                            <Col span={24}>
                              <Text strong>{item.role}</Text>
                            </Col>
                            <Divider />
                          </Row>
                        )
                      }
                    </TabPane>
                  : null
                }
                {
                  status === 'diterima' ?
                    <TabPane tab="Aktivitas" key="5">
                      <Row  className="mb-16">
                        <Col span={24}>
                          <Button onClick={() => showModal(null)} block>+ Tambah Log Aktivitas</Button>
                          <Modal
                            width={800} 
                            title={'Tambah Aktivitas'}
                            visible={visible} 
                            onCancel={handleCancel}
                            footer={false}
                            maskClosable={false}
                            destroyOnClose
                          >
                            <Form
                              layout="vertical"
                              ref={formRef}
                              onFinish={onFinish}
                            >
                              <FormActivity {...props} />
                              <Row>
                                <Col span={24}>
                                  <Space className="float-right">
                                    <Button onClick={handleCancel}>
                                      Batal
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={submitLoading}>
                                      Simpan
                                    </Button>
                                  </Space>
                                </Col>
                              </Row>
                            </Form>
                          </Modal>
                        </Col>
                        <Col span={24}>
                          <Timeline className='mt-32'>
                            {
                              data?.listActivity?.map((item,i) => 
                                <Timeline.Item key={i}>
                                  <Row>
                                    <Col span={3}>
                                      <Text className='primary-color font-weight-bold'>{moment(item.activityDate, 'DD/MM/YYYY').format('ll')}</Text>
                                    </Col>
                                    <Col span={19}>
                                      <Paragraph ellipsis={true ? { rows: 3, expandable: true, symbol: 'Lihat Selengkapnya'} : false}>
                                        {item.summaryReport}
                                      </Paragraph>
                                    </Col>
                                    <Col span={2}>
                                      <Space className='float-right'>
                                        {
                                          item.fileUrl ?
                                            <a href={item.fileUrl} target="_blank" rel='noreferrer'>
                                              <Tooltip title="Download">
                                                <DownloadOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} />
                                              </Tooltip>
                                            </a>
                                          : null
                                        }
                                        <Tooltip title="Edit Aktifitas" onClick={() => showModal(item)}>
                                          <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} />
                                        </Tooltip>
                                      </Space>
                                    </Col>
                                  </Row>
                                </Timeline.Item>
                              )
                            }
                          </Timeline>
                        </Col>
                      </Row>
                    </TabPane>
                  : null
                }
                {
                  status === 'diterima' ?
                    <TabPane tab="Penilaian" key="6">
                      {
                        data?.listAssessment?.length > 0 ?
                          data?.listAssessment?.map((item,i) =>
                            <Row align="middle" key={i}>
                              <Col span={12}>
                                <Space direction='vertical' size={0}>
                                  <Text className="primary-color" strong>{item.courseCode} - {item.courseName}</Text>
                                  <Text strong>{courseCategory.find(res => res.value === item.courseCategory).name} - {item.courseSks} SKS</Text>
                                </Space>
                              </Col>
                              <Col span={2}>
                                <Space direction='vertical'>
                                  <Text className="primary-color" strong>{item.letterGrade}</Text>
                                  <Text strong>{item.score}</Text>
                                </Space>
                              </Col>
                              <Col span={8}>
                                <Space direction='vertical'>
                                  <Text className="primary-color" strong>{item.lecturerName}</Text>
                                  <Text strong>pada {moment(item.assessmentDate, 'DD/MM/YYYY').format('ll')}</Text>
                                </Space>
                              </Col>
                              <Col span={2}>
                                <Tooltip className="float-right" onClick={showModalNotes}>
                                  <FileTextOutlined />
                                </Tooltip>
                                <Modal
                                  width={800} 
                                  title={'Notes'}
                                  visible={visibleNotes} 
                                  onCancel={handleCancel}
                                  footer={false}
                                  maskClosable={false}
                                  destroyOnClose
                                >
                                  <div dangerouslySetInnerHTML={{ __html: item.note ? item.note : 'N/A' }}></div>
                                </Modal>
                              </Col>
                              <Divider />
                            </Row> 
                          )
                        :
                          <Empty />
                      }
                    </TabPane>
                  : null
                }
              </Tabs>
            </Card>
          </Col>
        </Row>
    </React.Fragment>
  )
}