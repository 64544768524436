import React, { Component } from 'react';
import * as Containers from '../containers';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';
// import ReactGA from 'react-ga';
import { Loading } from '../components';
import { convertMenu } from '../utils/menuSelection';
import { PrivateRoute, AuthRoute } from './route';
import { checkAuth, checkToken } from '../redux/actions/auth/authAction';
import { BrowserRouter as Router, Switch } from "react-router-dom";
// import TagManager from 'react-gtm-module'
// import { Helmet } from "react-helmet";

// if(process.env.REACT_APP_ENV === "production"){
//   const tagManagerArgs = {
//     gtmId: 'GTM-5TPJGMG'
//   }
//   TagManager.initialize(tagManagerArgs)
//   ReactGA.initialize("UA-158765154-2");
// }

export class AppRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _menu: null
    }
  }

  componentDidMount() {
    const { actionCheckAuth, actionCheckToken } = this.props;
    const getToken = Cookie.get('user')
    localStorage.removeItem('imageCandidate')
    actionCheckAuth()
    if(getToken){
      actionCheckToken(() => {
        window.location.reload()
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    const { permission, loading, userType } = nextProps
    !loading && convertMenu(permission, userType, (response) => {
      this.setState({ _menu: response })
    })
  }

  render() {
    const { loading } = this.props;
    const { _menu } = this.state;
    
    if(loading){ 
      return <Loading />
    }

    // if(process.env.REACT_APP_ENV === "production"){
    //   <Helmet>
    //     <meta name="facebook-domain-verification" content="htx5ke5a0d44ityyczoad6o6advikg" />
    //     {/* Note: Verification will fail if the meta-tag code is outside of the <head> section or in a section loaded dynamically by JavaScript. */}
    //     {/* TASK 2: GOOGLE SEARCH CONSOLE
    //     Copy the meta tag below, and paste it into your site's home page. It should go in the <head> section, before the first <body> section. */}
    //     <meta name="google-site-verification" content="QGlYN5P5-tYs66MsO1SGzit84IF2WOvTXZUV4JpBWsE" />
    //   </Helmet>
    // }
    
    return (
      <Router>
        <Switch>
          <AuthRoute exact path='/' component={Containers.Home} passProps={this.props} private={false} />
          <AuthRoute exact path='/masuk' component={Containers.Login} passProps={this.props} private={false} />
          <AuthRoute exact path='/lupa-kata-sandi' component={Containers.ForgotPassword} passProps={this.props} private={false} />
          <AuthRoute exact path='/lupa-kata-sandi/verify' component={Containers.Verify} passProps={this.props} private={false} />
          <AuthRoute exact path='/ganti-kata-sandi' component={Containers.ChangePassword} passProps={this.props} private={false} />
          <AuthRoute exact path='/daftar' component={Containers.Register} passProps={this.props} private={false} />
          <AuthRoute exact path='/daftar/konfirmasi-email' component={Containers.ConfirmEmail} passProps={this.props} private={false} />
          <AuthRoute exact path='/program' component={Containers.Program} passProps={this.props} private={false} />
          <AuthRoute exact path='/program/detail/:id' component={Containers.ProgramDetail} passProps={this.props} private={false} />
          <AuthRoute exact path='/berita-dan-pengumuman' component={Containers.Berita} passProps={this.props} private={false} />
          <AuthRoute exact path='/berita-dan-pengumuman/detail/:id' component={Containers.BeritaDetail} passProps={this.props} private={false} />
          <AuthRoute exact path='/tentang-program/kewirausahaan' component={Containers.Kewirausahaan} passProps={this.props} private={false} />
          <AuthRoute exact path='/tentang-program/pertukaran-mahasiswa-merdeka' component={Containers.PertukaranMahasiswaMerdeka} passProps={this.props} private={false} />
          <AuthRoute exact path='/tentang-program/magang' component={Containers.Magang} passProps={this.props} private={false} />
          <AuthRoute exact path='/tentang-program/proyek-kemanusiaan' component={Containers.ProyekKemanusiaan} passProps={this.props} private={false} />
          <AuthRoute exact path='/tentang-program/membangun-desa-kkn' component={Containers.MembangunDesaKkn} passProps={this.props} private={false} />
          <AuthRoute exact path='/galeri' component={Containers.Galeri} passProps={this.props} private={false} />
          <AuthRoute exact path='/unduhan' component={Containers.Unduhan} passProps={this.props} private={false} />
          <AuthRoute exact path='/hubungi-kami' component={Containers.ContactUs} passProps={this.props} private={false} />
          {
            _menu?.map((response, i) => {
              return <PrivateRoute {...response} key={i} exact component={Containers[response.component]} passProps={this.props} />
            })
          }
        </Switch>
      </Router>
    )
  }
}
const mapStateToProps = (state) => ({
  loading     : state.authReducer.loading,
  authed      : state.authReducer.authed,
  permission  : state.authReducer.permission,
  userType    : state.authReducer.userType,
})
const mapDispatchToProps = {
  actionCheckAuth: checkAuth,
  actionCheckToken: checkToken
}
export default connect(mapStateToProps, mapDispatchToProps)(AppRoute)