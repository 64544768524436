import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Typography, Modal, Button, Input, Space, Form, Row, Col, Divider, DatePicker, Select, message } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { indexCountry, unmountIndexCountry } from '../../../../redux/actions/master/country/countryAction';
import { listProvinceByCountry, unmountlistProvinceByCountry } from '../../../../redux/actions/master/province/provinceAction';
import { listCityByProvince, unmountlistCityByProvince } from '../../../../redux/actions/master/city/cityAction';
import { subDistrictByCity, unmountSubDistrictByCity } from '../../../../redux/actions/master/subDistrict/subDistrictAction';
import { villageBySubDistrict, unmountVillageBySubDistrict } from '../../../../redux/actions/master/village/villageAction';
import { updateBiodata } from '../../../../redux/actions/profile/profileAction';
import moment from 'moment';
import { maritalStatus } from '../../../../utils/constant/maritalStatus';
import { categoryReligion } from '../../../../utils/constant/religion';

const { Text } = Typography;
const { Option } = Select;

export class PersonalInfo extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      submitLoading: false,
      birthdate: null,
      countryId: null,
      provinceId: null,
      cityId: null,
      subDistrictId: null,
      villageId: null,
    }
  }
  
  componentDidMount() {
    const { actionGetCountry, actionGetProvince, actionGetCity, actionGetSubDistrict, actionGetVillage, data: { countryId, provinceId, cityId, subDistrictId, villageId } } = this.props

    this.setState({
      countryId: countryId,
      provinceId: provinceId,
      cityId: cityId,
      subDistrictId: subDistrictId,
      villageId: villageId
    })

    if(subDistrictId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId), actionGetSubDistrict(cityId), actionGetVillage(subDistrictId))
    }else if(cityId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId), actionGetSubDistrict(cityId))
    }else if(provinceId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId))
    }else if(countryId){
      return (actionGetCountry(), actionGetProvince(countryId))
    }

    return actionGetCountry()
  }

  showModal = () => {
    const { data } = this.props
    this.setState({
      visible: true,
      birthdate: data.birthdate
    })
  };

  handleCancel = () => {
    const { actionGetCountry, actionGetProvince, actionGetCity, actionGetSubDistrict, actionGetVillage, data: { countryId, provinceId, cityId, subDistrictId } } = this.props
    
    this.setState({
      visible: false
    })

    if(subDistrictId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId), actionGetSubDistrict(cityId), actionGetVillage(subDistrictId))
    }else if(cityId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId), actionGetSubDistrict(cityId))
    }else if(provinceId){
      return (actionGetCountry(), actionGetProvince(countryId), actionGetCity(provinceId))
    }else if(countryId){
      return (actionGetCountry(), actionGetProvince(countryId))
    }
  };

  onChangeDate = (date, dateString) => {
    this.setState({
      birthdate: dateString ? dateString : null
    })
  }

  handleCountry = (value, option) => {
    const { provinceId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      countryId: optionSelected
    })

    if(provinceId !== 0){
      this.setState({
        provinceId: null,
        cityId: null,
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        province: null,
        city: null,
        subDistrict: null,
        village: null
      })
    }

    const { actionGetProvince } = this.props
    return actionGetProvince(option.key)
  }

  handleProvince = (value, option) => {
    const { cityId } = this.state
    const optionSelected = Number(option.key)

    this.setState({
      provinceId: optionSelected
    })

    if(cityId !== 0){
      this.setState({
        cityId: null,
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        city: null,
        subDistrict: null,
        village: null
      })
    }

    const { actionGetCity } = this.props
    return actionGetCity(option.key)
  }

  handleCity = (value, option) => {
    const { subDistrictId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      cityId: optionSelected
    })

    if(subDistrictId !== 0){
      this.setState({
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        subDistrict: null,
        village: null
      })
    }

    const { actionGetSubDistrict } = this.props
    return actionGetSubDistrict(option.key)
  }

  handleSubDistrict = (value, option) => {
    const { villageId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      subDistrictId: optionSelected
    })

    if(villageId !== 0){
      this.setState({
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        village: null
      })
    }

    const { actionGetVillage } = this.props
    return actionGetVillage(option.key)
  }

  handleVillage = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      villageId: optionSelected
    })
  }

  onFinish = async (values) => {
    const { actionUpdateBiodata, actionGetProfile } = this.props
    const { birthdate, countryId, provinceId, cityId, subDistrictId, villageId } = this.state

    values.birthdate = birthdate ? birthdate : null
    values.countryId = countryId
    values.provinceId = provinceId
    values.cityId = cityId
    values.subDistrictId = subDistrictId
    values.villageId = villageId
    
    return this.setState({ submitLoading: true }, () => {
      return actionUpdateBiodata(values, response => {
        if(response.code === 1000){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  render() {
    const { visible, submitLoading } = this.state
    const { getCountry, getProvince, getCity, getSubDistrict, getVillage, data: { birthplace, birthdate, marital, gender, religion, nationality, bloodType, height, weight, nik, countryName, provinceName, cityName, subDistrictName, villageName, zipCode, address } } = this.props
    const isFilled = birthplace || birthdate || marital || gender || religion || nationality || bloodType || height || weight || nik || countryName || provinceName || cityName || subDistrictName || villageName || zipCode || address
    
    return (
      <React.Fragment>
        <Card 
          className="border-radius-6 box-shadow" 
          title="Biodata" 
          bordered={false} 
          extra={<Button type="link" icon={<EditFilled />} onClick={this.showModal}>Edit</Button>} 
          headStyle={{ color: '#32AB6D', fontSize: 17 }}
          bodyStyle={isFilled ? {} : { textAlign: 'center', padding: 60 }}
        >
          {
            isFilled ?
              <React.Fragment>
                <Row className="mb-16">
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Tempat Lahir</Text>
                        <Text className="dark-color" strong>{birthplace ? birthplace : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Tanggal Lahir</Text>
                        <Text className="dark-color" strong>{birthdate ? birthdate : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Status Perkawinan</Text>
                        <Text className="dark-color" strong>{marital ? maritalStatus.find(res => res.value === marital)?.name : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Jenis Kelamin</Text>
                        <Text className="dark-color" strong>{gender ? gender === 'MALE' ? 'Pria' : 'Wanita' : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Agama</Text>
                        <Text className="dark-color" strong>{religion ? categoryReligion.find(res => res.value === religion)?.name : '-'}</Text>
                      </Space>
                    </Space>
                  </Col>
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Kewarganegaraan</Text>
                        <Text className="dark-color" strong>{nationality ? nationality : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Golongan Darah</Text>
                        <Text className="dark-color" strong>{bloodType ? bloodType : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Tinggi (cm)</Text>
                        <Text className="dark-color" strong>{height ? height : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Berat (kg)</Text>
                        <Text className="dark-color" strong>{weight ? weight : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Nomor KTP</Text>
                        <Text className="dark-color" strong>{nik ? nik : '-'}</Text>
                      </Space>
                    </Space>
                  </Col>
                </Row>
                <Text type="secondary">Alamat</Text>
                <Divider style={{ margin: '8px 0' }} />
                <Row className="mb-16">
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Negara</Text>
                        <Text className="dark-color" strong>{countryName ? countryName : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Provinsi</Text>
                        <Text className="dark-color" strong>{provinceName ? provinceName : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Kota</Text>
                        <Text className="dark-color" strong>{cityName ? cityName : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Kecamatan</Text>
                        <Text className="dark-color" strong>{subDistrictName ? subDistrictName : '-'}</Text>
                      </Space>
                    </Space>
                  </Col>
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Kelurahan</Text>
                        <Text className="dark-color" strong>{villageName ? villageName : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Kode Pos</Text>
                        <Text className="dark-color" strong>{zipCode ? zipCode : '-'}</Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Alamat Jalan</Text>
                        <Text className="dark-color" strong>{address ? address : '-'}</Text>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </React.Fragment>
            :
              <Text className="dark-color">Beri tahu kami beberapa data informasi pribadi Anda</Text>
          }
        </Card>

        <Modal 
          title="Biodata"
          visible={visible} 
          onCancel={this.handleCancel} 
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={600}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              birthplace: birthplace,
              birthdate: birthdate ? moment(birthdate, 'DD/MM/YYYY') : null,
              gender: gender,
              bloodType: bloodType,
              height: height ? height.toString() : null,
              weight: weight ? weight.toString(): null,
              marital: marital,
              religion: religion,
              nationality: nationality,
              nik: nik,
              country: countryName,
              province: provinceName,
              city: cityName,
              subDistrict: subDistrictName,
              village: villageName,
              zipCode: zipCode,
              address: address
            }}
          >
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Tempat Lahir</Text>
                <Form.Item 
                  name="birthplace"
                  validateFirst
                  rules={[
                    { pattern: /^[a-zA-Z ]*$/, message: 'Tidak dapat menggunakan karakter khusus dan angka!' },
                    { max: 50, message: 'Kolom ini tidak boleh lebih dari 50 karakter' },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Tanggal Lahir</Text>
                <Form.Item name="birthdate">
                  <DatePicker inputReadOnly className="width-100" size="small" format="DD/MM/YYYY" onChange={this.onChangeDate} defaultPickerValue={birthdate ? null : moment('2000-01-01')} />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={6}>
                <Text className="fs-12" type="secondary">Jenis Kelamin</Text>
                <Form.Item name="gender">
                  <Select 
                    className="width-100 border-radius-6"
                    size="small" 
                  >
                    <Option key="1" value="MALE">Pria</Option>
                    <Option key="2" value="FEMALE">Wanita</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Text className="fs-12" type="secondary">Golongan Darah</Text>
                <Form.Item name="bloodType">
                  <Select 
                    className="width-100 border-radius-6"
                    size="small" 
                  >
                    <Option key="1" value="A">A</Option>
                    <Option key="2" value="AB">AB</Option>
                    <Option key="3" value="B">B</Option>
                    <Option key="4" value="O">O</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Text className="fs-12" type="secondary">Tinggi (cm)</Text>
                <Form.Item 
                  name="height"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: 'Kolom ini hanya boleh berisi angka!' },
                    { min: 2, message: 'Kolom ini harus memiliki paling sedikit 2 karakter' },
                    { max: 3, message: 'Kolom ini tidak boleh lebih dari 3 karakter' },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Text className="fs-12" type="secondary">Berat (kg)</Text>
                <Form.Item 
                  name="weight"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: 'Kolom ini hanya boleh berisi angka!' },
                    { min: 2, message: 'Kolom ini harus memiliki paling sedikit 2 karakter' },
                    { max: 3, message: 'Kolom ini tidak boleh lebih dari 3 karakter' },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Status Perkawinan</Text>
                <Form.Item name="marital">
                  <Select 
                    className="width-100 border-radius-6"
                    size="small" 
                  >
                    <Option key="1" value="BELUM_KAWIN">Lajang</Option>
                    <Option key="2" value="KAWIN">Kawin</Option>
                    <Option key="3" value="CERAI_HIDUP">Bercerai</Option>
                    <Option key="4" value="CERAI_MATI">Janda/Duda</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Agama</Text>
                <Form.Item name="religion">
                  <Select 
                    className="width-100 border-radius-6"
                    size="small" 
                  >
                    <Option key="1" value="ISLAM">Islam</Option>
                    <Option key="2" value="CATHOLIC">Katolik</Option>
                    <Option key="3" value="PROTESTAN">Protestan</Option>
                    <Option key="4" value="BUDDHA">Buddha</Option>
                    <Option key="5" value="HINDU">Hindu</Option>
                    <Option key="6" value="OTHERS">Lainnya</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Text className="fs-12" type="secondary">Kewarganegaraan</Text>
                <Form.Item 
                  name="nationality"
                  validateFirst
                  rules={[
                    { pattern: /^[a-zA-Z ]*$/, message: 'Tidak dapat menggunakan karakter khusus dan angka!' },
                    { max: 50, message: 'Kolom ini tidak boleh lebih dari 50 karakter' },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="fs-12" type="secondary">NIK</Text>
                <Form.Item 
                  name="nik"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: 'Kolom ini hanya boleh berisi angka!' },
                    { len: 16, message: 'Kolom ini harus berisi 16 angka' },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Text className="fs-12" type="secondary">Alamat</Text>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Form.Item name="country">
                  <Select 
                    className="width-100" 
                    size="small" 
                    showSearch
                    onChange={this.handleCountry}
                    placeholder='Negara'
                  >
                    {
                      getCountry?.data?.map((item) => 
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="province">
                  <Select 
                    className="width-100" 
                    size="small" 
                    showSearch
                    onChange={this.handleProvince}
                    placeholder='Provinsi'
                  >
                    {
                      getProvince?.data?.map((item) => 
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Form.Item name="city">
                  <Select 
                    className="width-100" 
                    size="small" 
                    showSearch
                    onChange={this.handleCity}
                    placeholder='Kota'
                  >
                    {
                      getCity?.data?.map((item) => 
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="subDistrict">
                  <Select 
                    className="width-100" 
                    size="small" 
                    showSearch
                    onChange={this.handleSubDistrict}
                    placeholder='subDistrict'
                  >
                    {
                      getSubDistrict?.data?.map((item) => 
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col span={12}>
                <Form.Item name="village">
                  <Select 
                    className="width-100" 
                    size="small" 
                    showSearch
                    onChange={this.handleVillage}
                    placeholder='Desa / Kelurahan'
                  >
                    {
                      getVillage?.data?.map((item) => 
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item 
                  name="zipCode"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: 'Kolom ini hanya boleh berisi angka!' },
                    { max: 10, message: 'Kolom ini tidak boleh lebih dari 10 karakter' },
                  ]}
                >
                  <Input size="small" placeholder="Kode Pos" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8">
              <Col span={24}>
                <Form.Item 
                  name="address"
                  validateFirst
                  rules={[
                    { max: 75, message: 'Kolom ini tidak boleh lebih dari 75 karakter' },
                  ]}
                >
                  <Input size="small" placeholder="Alamat" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space className="float-right">
                <Button onClick={this.handleCancel}>Kembali</Button>
                <Button htmlType="submit" type="primary" loading={submitLoading}>Simpan</Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountIndexCountry, unmountlistProvinceByCountry, unmountlistCityByProvince, unmountSubDistrictByCity, unmountVillageBySubDistrict } = this.props
    return (unmountIndexCountry(), unmountlistProvinceByCountry(), unmountlistCityByProvince(), unmountSubDistrictByCity(), unmountVillageBySubDistrict())
  }
}

const mapStateToProps = (state) => ({
  getCountry: state.countryReducer,
  getProvince: state.provinceReducer,
  getCity: state.cityReducer,
  getSubDistrict: state.subDistrictReducer,
  getVillage: state.villageReducer
})

const mapDispatchToProps = {
  actionGetCountry: indexCountry,
  unmountIndexCountry: unmountIndexCountry,
  actionGetProvince: listProvinceByCountry,
  unmountlistProvinceByCountry: unmountlistProvinceByCountry,
  actionGetCity: listCityByProvince,
  unmountlistCityByProvince: unmountlistCityByProvince,
  actionGetSubDistrict: subDistrictByCity,
  unmountSubDistrictByCity: unmountSubDistrictByCity,
  actionGetVillage: villageBySubDistrict,
  unmountVillageBySubDistrict: unmountVillageBySubDistrict,
  actionUpdateBiodata: updateBiodata
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo)