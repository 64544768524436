import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Form, Input, Card, Button, Row, Col, Typography, Space, message } from 'antd';
import { forgotPassEmail } from '../../redux/actions/forgotPassword/forgotPasswordAction';
import { checkEmail } from '../../redux/actions/check/checkAction';

const { Text } = Typography

export class ForgotPassword extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false
    }
  }

  onFinish =  (values) => {
    const { actionCheckEmail, actionForgotPassEmail } = this.props
    
    return actionCheckEmail(values.email, response => {
      if(response){
        return this.setState({ submitLoading: true }, () => {
          return actionForgotPassEmail(values, response => {
            if(response.code === 1000){
              return this.setState({ submitLoading: false }, () => {
                localStorage.setItem("emailForgotPass", values.email)
                this.props.history.push('/lupa-kata-sandi/verify')
              })
            }else{
              return this.setState({ submitLoading: false }, () => message.error(response.message))
            }
          }, (err) => {
            return this.setState({ submitLoading: false }, () => message.error(err.message))
          })
        })
      }else{
        message.error(`We couldn’t find an account associated with ${values.email} Please check your email address`)
      }
    })
  }

  render() {
    const { submitLoading } = this.state

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'MBKM - Lupa Kata Sandi');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Lupa Kata Sandi</title>
        </Helmet>
        <Row className="display-flex" align="middle" justify="center">
          <Col>
            <Card className="card-forgot-password" bodyStyle={{ padding: 0, display: 'flex' }}>
              <Form 
                name="normal_login" 
                layout="vertical" 
                onFinish={this.onFinish}
              >
                <Row gutter={[16,16]}>
                  <Col className="fs-28" xs={24} sm={24} md={24}>
                    <Text className="primary-color">Lupa Kata Sandi</Text>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Text type="secondary">Email</Text>
                    <Form.Item 
                      className="mb-16"
                      name="email"
                      rules={[
                        { required: true, message: 'Kolom ini wajib diisi!' },
                        { type: 'email', message: 'Email tidak valid!' }
                      ]}
                    >
                      <Input 
                        type="email"
                        placeholder="Masukkan Email Anda"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Space className="width-100" direction="vertical">
                      <Form.Item className="mb-0">
                        <Button type="primary" htmlType="submit" block loading={submitLoading}>
                          Ubah Kata Sandi
                        </Button>
                      </Form.Item>
                      <Form.Item className="mb-0">
                        <Button href="/masuk" block>
                          Kembali
                        </Button>
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionCheckEmail: checkEmail,
  actionForgotPassEmail: forgotPassEmail
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)