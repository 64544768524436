import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const unmountIndexFileManagement = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_FILE_MANAGEMENT'})
}

export const indexFileManagement = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_FILE_MANAGEMENT' })
  return API.GET('/file-management/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_FILE_MANAGEMENT_SUCCESS', 
      payload: {
        data: response.data
      }
    })
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_FILE_MANAGEMENT_FAILED' }), 
    ))
  })
}

export const updateDownloader = (value, successCB, failedCB) => async dispatch => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/file-management/update', params).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}