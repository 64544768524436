import React, { Component } from 'react';
import { connect } from 'react-redux';
import { listProgram, unmountListProgram } from '../../redux/actions/program/programAction';
import ProgramList from '../../components/Program/List'
import { validatePost } from '../../utils/postValidationFunction';

export class Program extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
  
    this.state = {
      params: {
       page: 1,
       perpage: 12,
       category: null
      },
      visibleFilter: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  
    const { params } = this.state
    const { actionGetProgram } = this.props
  
    actionGetProgram(params, (res) => {
      var queryString = Object.keys(validatePost(res)).map(key => key + '=' + params[key]).join('&');
      this.props.history.push({
        search: queryString
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { params } = this.state

    if(prevState.params !== params){
      var queryString = Object.keys(validatePost(params)).map(key => key + '=' + params[key]).join('&');
      this.props.history.push({
        search: queryString
      })
    }
  }

  onSearch = (value) => {
    const { params } = this.state
    const { actionGetProgram } = this.props

    const paramsSearch = {
      search: value,
      page: 1,
    }

    const newParams = {...params, ...paramsSearch}

    return actionGetProgram(newParams, (res) => {
      this.setState({ params: res })
      window.scrollTo(0, 0);
    })
  }

  handleCategory = (checkedValues) => {
    const { params } = this.state
    const { actionGetProgram} = this.props

    const strCategory = checkedValues.join()

    const paramsCategory = {
      category: strCategory,
      page: 1
    }
    
    const newParams = {...params, ...paramsCategory}

    return actionGetProgram(newParams, (res) => {
      this.setState({ params: res })
      window.scrollTo(0, 0);
    })
  }

  handlePagination = (pageNumber) => {
    const { params } = this.state
    const { actionGetProgram } = this.props
    
    params.page = pageNumber

    window.scrollTo(0, 0);
    return actionGetProgram(params, (res) => {
      this.setState({ params: res })
    })
  }

  handleClearFilter = () => {
    const { params } = this.state
    const { actionGetProgram } = this.props

    params.page = 1
    params.search = null
    params.category = null

    return actionGetProgram(params, (res) => {
      this.setState({ params: res })
    })
  }

  showFilter = () => {
    this.setState({
      visibleFilter: true
    })
  }
  
  onCloseFilter = () => {
    this.setState({
      visibleFilter: false
    })
  }

  render() {
    const { getProgram } = this.props
    const { visibleFilter } = this.state

    const initialProps = {
      formRef: this.formRef,
      getProgram: getProgram,
      onSearch: this.onSearch,
      handleCategory: (checkedValues) => this.handleCategory(checkedValues),
      handlePagination: this.handlePagination,
      handleClearFilter: this.handleClearFilter,
      showFilter: this.showFilter,
      visibleFilter: visibleFilter,
      onCloseFilter: this.onCloseFilter
    }

    return (
      <ProgramList {...initialProps} {...this.props} />
    )
  }
}

const mapStateToProps = (state) => ({
  getProgram: state.programReducer
})

const mapDispatchToProps = {
  actionGetProgram: listProgram,
  unmountListProgram: unmountListProgram
}

export default connect(mapStateToProps, mapDispatchToProps)(Program)