import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import { contactUs } from '../../redux/actions/contactus/contactUsAction';
import { Row, Col, Typography, Card, Space, Divider, Input, Form, Button, message} from 'antd';
// import { MailOutlined, PhoneOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { EnvironmentOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { TextArea } = Input;


export class ContactUs extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props)
      
        this.state = {
          submitLoading: false
        }
      }
    onFinish = (values) => {
      const { actionContactUs } = this.props
      return this.setState({ submitLoading: true }, () => {
        return actionContactUs(values, response => {
          if(response.code === 1000){
            message.success('Berhasil mengirim pendapat Anda')
            return this.setState({ submitLoading: false }, () => {
              this.props.history.push(Cookie.get('user') ? '/kandidat/hubungi-kami' : '/hubungi-kami')
            })
          }else{
            message.error(response.message)
          }
        }, (err) => {
          return this.setState({ submitLoading: false }, () => message.error(err.message))
        })
      })
    }
    render() {
    const {  submitLoading } = this.state
    return (
      <React.Fragment>
        <Row gutter={[0,16]} style={{ backgroundColor: '#F2F2F2', paddingBottom: 24, paddingTop: 24}}>
           <Col xs={{ span: 24 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
              <Row>
                <Col span={24}>
                  <Text className='text-title-contact'>Hubungi Kami</Text>
                </Col>
              </Row>
              <Divider style={{ borderWidth: 5, borderColor: 'black', marginBottom: 32 }} />
              <Col span={24}>   
                <Row align="top">
                  <Col className='box-primary-contact' xxl={10} xl={12}>
                    <Space direction='vertical' size={16}>
                      {/* <Space className='box-contact' direction='horizontal' size={16}>
                        <MailOutlined size={50} style={{color: "#31A065", fontSize: 25}}/>
                        <Text className="dark-color text-contact">hello.mbkm@binawan.ac.id</Text>
                      </Space> */}
                      {/* <Space className='box-contact' direction='horizontal' size={16}>
                        <PhoneOutlined style={{color: "#31A065", fontSize: 25}}/>
                        <Text className="dark-color text-contact">+62 812 345 678</Text>
                      </Space> */}
                      <Space className='box-contact' direction='horizontal' size={16} align="start" >
                          <EnvironmentOutlined style={{color: "#31A065", fontSize: 25}} />
                          <Space direction='vertical' size={0}>
                            <Text className="dark-color text-contact">Gedung Perkantoran Binawan Lobby 1, Lantai 3</Text>
                            <Text className="dark-color text-contact">Jl. Raya Kalibata - Dewi Sartika, No 25 - 30</Text>
                            <Text className="dark-color text-contact">DKI Jakarta - 13630</Text>
                          </Space>
                      </Space>
                    </Space>
                  </Col>
                  <Col xxl={14} xl={12}>
                    <Card style={{ width: '100%', float: 'right' }} bodyStyle={{ margin: 16 }}>
                      <Text className='title-form'>Kami akan senang mendengar pendapat Anda</Text>
                      <Form 
                        ref={this.formRef}
                        onFinish={this.onFinish}
                        layout="vertical"
                      >
                        <Form.Item 
                          name="fullName"
                          label="Nama Lengkap" 
                          validateFirst
                          rules={[
                            {pattern: /^[a-zA-Z ]*$/, message: 'Karakter spesial dan angka tidak diperbolehkan' },
                            {min: 3, message: 'Minimal 3 karakter' },
                            {max: 50, message: 'Maksimal 50 karakter'},
                            {required: true, message: 'Kolom ini wajib diisi!'}
                          ]}
                        >
                          <Input placeholder="Masukkan Nama Lengkap Anda"/>
                        </Form.Item>
                        <Form.Item 
                          name="email"
                          label="Email" 
                          validateFirst
                          rules={[
                            { type: 'email', message: 'Email Anda tidak valid!' },
                            {required: true, message: 'Kolom ini wajib diisi!'}
                          ]}
                        >
                          <Input placeholder="Masukkan Email Anda"/>
                        </Form.Item>
                        <Form.Item 
                          name="message"
                          label="Pesan" 
                          fontSize={18}
                          validateFirst
                          rules={[
                            {max: 2000, message: 'Maksimal 2000 karakter'},
                            {required: true, message: 'Kolom ini wajib diisi!'}
                          ]}
                        >
                          <TextArea autoSize={{minRows: 3, maxRows: 8}} placeholder="Apa yang ingin Anda sampaikan"></TextArea>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" htmlType='submit' block loading={submitLoading}>Kirim Pesan</Button>
                        </Form.Item>
                      </Form>
                    </Card>
                  </Col>
                </Row>
              </Col>
           </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionContactUs: contactUs
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)