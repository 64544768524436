import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from '../../../../../components';
import StatusLamaranDetail from '../../../../../components/StatusLamaran/Detail';
import { detailApplications, unmountDetailApplications } from '../../../../../redux/actions/statusLamaran/statusLamaranAction';

export class index extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
    }
  }
  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props

    window.scrollTo(0, 0);
    
    actionGetDetail(params.id)
  }
  
  render() {
    const { getDetail } = this.props

    if(getDetail.loading){
      return <Loading />
    }
    
    return (
      <React.Fragment>
        <StatusLamaranDetail status="peninjauan" {...this.props} />
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountDetailApplications } = this.props
    return unmountDetailApplications()
  }
}

const mapStateToProps = (state) => ({
  getDetail: state.detailApplicationsReducer
})

const mapDispatchToProps = {
  actionGetDetail: detailApplications,
  unmountDetailApplications: unmountDetailApplications
}

export default connect(mapStateToProps, mapDispatchToProps)(index)
