import React from 'react';
import { Card, Typography, Modal, Form, Input, Space, Button, Row, Col, Checkbox, DatePicker, Select, Grid, Spin, Divider, Tooltip } from 'antd';
import { DownloadOutlined, EditOutlined} from '@ant-design/icons';
import moment from 'moment';
import './Document.css';

const { useBreakpoint } = Grid;
const { Text } = Typography;
const { TextArea } = Input;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}


export default function DocumentForm (props) {
  const { data, visible, submitLoading, current, showModal, handleCancel, onChangeStartDate, onChangeEndDate, handleChecked, formRef, onFinish, isDate, id, dataEdit, getDocumentTypes, uploadDocument } = props
  const { md } = GetBreakPoint()
  return (
    <React.Fragment>
      <Card
        className="border-radius-6 box-shadow" 
        title='Dokumen Pendukung' 
        bordered={false} 
        headStyle={{ color: '#32ab6d', fontSize: 17 }}
        bodyStyle={data?.listDocument?.length > 0 ? {} : { textAlign: 'center', padding: 60 }}
        actions={[
          <Text className="primary-color" onClick={() => showModal(null)}>Tambah Dokumen</Text>
        ]}
    >
      {
        data?.listDocument?.length > 0 ?
          data.listDocument.map((item, i) =>
            md ?
              <React.Fragment key={i}>
                <div style={{ marginBottom: 11, display: 'flex', justifyContent: 'space-between' }}>
                  <Space direction='vertical' size={0}>
                    <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                      {item.documentTypeName}
                    </Text>
                    <Text style={{ fontSize: 14 }}>
                      {item.documentNumber}
                    </Text>
                    <Text style={{ fontSize: 14 }}>
                      {
                        item.issueDate && item.expirationDate ?
                          `${moment(item.issueDate, 'MM/YYYY').format('MMM YYYY')} - ${moment(item.expirationDate, 'MM/YYYY').format('MMM YYYY')}`
                        : item.issueDate && item.current ?
                          `${moment(item.issueDate, 'MM/YYYY').format('MMM YYYY')} - No Expiration Date`
                        : item.current ?
                          'No Expiration Date'
                        : item.issueDate ?
                          `${moment(item.issueDate, 'MM/YYYY').format('MMM YYYY')} - N/A`
                        : item.expirationDate ?
                          `N/A - ${moment(item.expirationDate, 'MM/YYYY').format('MMM YYYY')}`
                        : null
                      }
                    </Text>
                  </Space>
                  <Space>
                    <a href={item.fileUrl} target="_blank" rel='noreferrer'>
                      <Tooltip title="Download Document">
                        <DownloadOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} />
                      </Tooltip>
                    </a>
                    <Tooltip title="Edit">
                      <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={() => showModal(item.id)} />
                    </Tooltip>
                  </Space>
                </div>
                <Divider />
              </React.Fragment>
            : 
              <React.Fragment key={i}>
                <div style={{ marginBottom: 11, display: 'flex', justifyContent: 'space-between' }}>
                  <Space direction='vertical' size={0}>
                    <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                      {item.documentTypeName}
                    </Text>
                    <Text style={{ fontSize: 14 }}>
                      {item.documentNumber}
                    </Text>
                    <Text style={{ fontSize: 14 }}>
                      {
                        item.issueDate && item.expirationDate ?
                          `${moment(item.issueDate, 'MM/YYYY').format('MMM YYYY')} - ${moment(item.expirationDate, 'MM/YYYY').format('MMM YYYY')}`
                        : item.issueDate && item.current ?
                          `${moment(item.issueDate, 'MM/YYYY').format('MMM YYYY')} - No Expiration Date`
                        : item.current ?
                          'No Expiration Date'
                        : item.issueDate ?
                          `${moment(item.issueDate, 'MM/YYYY').format('MMM YYYY')} - N/A`
                        : item.expirationDate ?
                          `N/A - ${moment(item.expirationDate, 'MM/YYYY').format('MMM YYYY')}`
                        : null
                      }
                    </Text>
                  </Space>
                  <Space>
                    <a href={item.fileUrl} target="_blank" rel='noreferrer'>
                      <Tooltip title="Download Document">
                          <DownloadOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} />
                      </Tooltip>
                    </a>
                    <Tooltip title="Edit">
                      <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={() => showModal(item.id)} />
                    </Tooltip>
                  </Space>
                </div>
                <Divider />
              </React.Fragment>
            )
        :
        <Text className="dark-color">Lengkapi dokumen data pribadi Anda agar perekrut dapat dengan mudah mengenal Anda</Text>
      }

      </Card>

      <Modal 
        title='Dokumen' 
        visible={visible} 
        onCancel={handleCancel} 
        footer={false}
        bodyStyle={{ paddingBottom: 4 }}
        centered
        width={600}
        destroyOnClose
      >
        <Form 
          ref={formRef}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            documentTypeId: id ? dataEdit?.documentTypeId : null,
            documentNumber: id ? dataEdit?.documentNumber : null,
            // file: id ? dataEdit?.file : null,
            issueDate: id && dataEdit?.issueDate ? moment(dataEdit?.issueDate, 'MM/YYYY') : null,
            expirationDate: id && dataEdit?.expirationDate ? moment(dataEdit?.expirationDate, 'MM/YYYY') : null,
            note: id ? dataEdit?.note : null
          }}
        >
          {
            md ?
              <React.Fragment>
                <Row gutter={16}>
                  <Col className="width-100 mt-min-16" span={12}>
                    <Space size={4}>
                      <Text className="fs-12" type="danger">*</Text>
                      <Text className="fs-12" type="secondary">Tipe Dokumen</Text>
                    </Space>
                    <Form.Item 
                      name="documentTypeId"
                      validateFirst
                      rules={[
                        { required: true,  message: 'Kolom ini wajib diisi!' }
                      ]}
                    >
                      <Select
                        size="small"
                        loading={getDocumentTypes?.loading}
                        notFoundContent={getDocumentTypes ? <Spin size="small" /> : null}
                      >
                        {
                          getDocumentTypes?.data && getDocumentTypes?.data?.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="width-100 mt-min-16" span={12}>
                    <Text className="fs-12" type="secondary">Nomor Dokumen</Text>
                    <Form.Item 
                      name="documentNumber"
                      validateFirst
                      rules={[
                        { max: 50, message: 'Kolom ini tidak boleh lebih dari 50 karakter' }
                      ]}
                    >
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                  <Col className="width-100 mt-min-16" span={24}>
                    <Form.Item name="current">
                      <Checkbox className="fs-12" onChange={handleChecked} checked={current}>Dokumen Ini Tidak Kedulawarsa</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col className="width-100 mt-min-16" span={12}>
                    <Text className="fs-12" type="secondary">Tanggal Terbit</Text>
                    <Form.Item name="issueDate">
                      <DatePicker 
                        size="small"
                        picker="month" 
                        placeholder="YYYY-MM" 
                        style={{ width: '100%' }}
                        onChange={onChangeStartDate} 
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} className="width-100 mt-min-16">
                    <Text className="fs-12" type="secondary">Tanggal Kedulawarsa</Text>
                    <Form.Item 
                      name="expirationDate"
                      validateStatus={isDate && !current ? "error" : null}
                      help={isDate && !current ? 'Tanggal kadaluarsa tidak boleh lebih awal dari tanggal terbit Anda' : null}
                    >
                      {
                        current ?
                        <Text className="fs-12" type="secondary">Kedulawarsa</Text>
                        :
                        <DatePicker 
                          size="small"
                          picker="month" 
                          placeholder="YYYY-MM" 
                          style={{ width: '100%' }}
                          onChange={onChangeEndDate} 
                        />
                      }
                    </Form.Item>
                  </Col>
                  <Col className="width-100 mt-min-16" span={24}>
                    {
                      dataEdit ?
                        <Form.Item 
                          name="file"
                          rules={[
                            {required: true, message: 'required'}
                          ]}
                        >
                          {uploadDocument}
                        </Form.Item>
                      :
                        <Form.Item 
                          name="file"
                          rules={[
                            {required: true, message: 'required'}
                          ]}
                        >
                          {uploadDocument}
                        </Form.Item>
                    }
                  </Col>
                </Row>
              </React.Fragment>
            :
              <React.Fragment>
                <Row gutter={16}>
                  <Col className="width-100 mt-min-16" span={24}>
                    <Space size={4}>
                      <Text className="fs-12" type="danger">*</Text>
                      <Text className="fs-12" type="secondary">Tipe Dokumen</Text>
                    </Space>
                    <Form.Item 
                      name="documentTypeId"
                      validateFirst
                      rules={[
                        { required: true,  message: 'Kolom ini wajib diisi!' }
                      ]}
                    >
                      <Select
                        size="small"
                        loading={getDocumentTypes?.loading}
                        notFoundContent={getDocumentTypes ? <Spin size="small" /> : null}
                      >
                        {
                          getDocumentTypes?.data && getDocumentTypes?.data?.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="width-100 mt-min-16" span={24}>
                    <Text className="fs-12" type="secondary">Nomor Dokumen</Text>
                    <Form.Item 
                      name="documentNumber"
                      validateFirst
                      rules={[
                        {max: 50, message: 'Kolom ini tidak boleh lebih dari 50 karakter'},
                      ]}
                    >
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                  <Col className="width-100 mt-min-16" span={24}>
                    <Form.Item name="current">
                      <Checkbox className="fs-12" onChange={handleChecked} checked={current}>Dokumen ini tidak Kedulawarsa</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col className="width-100 mt-min-16" span={24}>
                    <Text className="fs-12" type="secondary">Tanggal Terbit</Text>
                    <Form.Item name="issueDate">
                      <DatePicker 
                        size="small"
                        picker="month" 
                        placeholder="YYYY-MM" 
                        style={{ width: '100%' }}
                        onChange={onChangeStartDate} 
                      />
                    </Form.Item>
                  </Col>
                  <Col className="width-100 mt-min-16" span={24}>
                    <Text className="fs-12" type="secondary">Tanggal Kedulawarsa</Text>
                    <Form.Item 
                      name="expirationDate"
                      validateStatus={isDate && !current ? "error" : null}
                      help={isDate && !current ? ('Tanggal kadaluarsa tidak boleh lebih awal dari tanggal terbit Anda') : null}
                    >
                      {
                        current ?
                        <Text className="fs-12" type="secondary">{('profile.document.noExp')}</Text>
                        :
                        <DatePicker 
                          size="small"
                          picker="month" 
                          placeholder="YYYY-MM" 
                          style={{ width: '100%' }}
                          onChange={onChangeEndDate} 
                        />
                      }
                    </Form.Item>
                  </Col>
                  <Col className="width-100 mt-min-16" span={24}>
                    <Form.Item 
                      name="file"
                      // rules={[
                      //   { required: !showFileEdit ? true : false, message: 'required'}
                      // ]}
                    >
                      {uploadDocument}
                    </Form.Item>
                  </Col>
                </Row>
              </React.Fragment>
          }
          <Row>
            <Col className="width-100 mt-min-16" span={24}>
              <Text className="fs-12" type="secondary">Catatan</Text>
              <Form.Item 
                name="note"
                validateFirst
                rules={[
                  { max: 1000, message: ('max',{number:1000}) },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            {
              id ?
                <React.Fragment>
                  <Button className="float-right" htmlType="submit" type="primary" loading={submitLoading}>Edit</Button>
                </React.Fragment>
              :
                <Space className="float-right">
                  <Button onClick={handleCancel}>Batal</Button>
                  <Button htmlType="submit" type="primary" loading={submitLoading}>Simpan</Button>
                </Space>
            }
          </Form.Item>
        </Form>
      </Modal>

    </React.Fragment>
  )
}
