import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const unmountIndexProfile = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_PROFILE'})
}

export const indexProfile = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_PROFILE' })
  return API.GET('/profile').then((response) => {
    dispatch({
      type: 'LOAD_DATA_PROFILE_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_PROFILE_FAILED' }), 
    ))
  })
}

export const updateHeaderName = (value, successCB, failedCB) => async dispatch => {
  API.POST('/profile/edit-header-name', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateHeaderEmail = (value, successCB, failedCB) => async dispatch => {
  API.POST('/profile/edit-header-email', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateHeaderPhone = (value, successCB, failedCB) => async dispatch => {
  API.POST('/profile/edit-header-phone', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateHeaderCity = (value, successCB, failedCB) => async dispatch => {
  API.POST('/profile/edit-header-city', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const uploadAvatar = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/profile/update-photo', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateBiodata = (value, successCB, failedCB) => async dispatch => {
  API.POST('/profile/biodata', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateEducation = (value, successCB, failedCB) => async dispatch => {
  API.POST('/profile/education', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateAbout = (value, successCB, failedCB) => async dispatch => {
  API.POST('/profile/about-me', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateDocument = (value, successCB, failedCB) => async dispatch => {
  API.POST_FORM_DATA('/profile/update-document', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const addDocument = (value, successCB, failedCB) => async dispatch => {
  API.POST_FORM_DATA('/profile/add-document', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}