import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Layout, Menu, Grid, Row, Col, Button, Drawer, Space, Image, Dropdown, Avatar } from 'antd';
import Logo from '../../../assets/img/logo/LogoMBKM.png';
import { MenuOutlined, PoweroffOutlined, UserOutlined, CaretDownFilled } from '@ant-design/icons';

const { useBreakpoint } = Grid;
const { Header } = Layout;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export const index = (props) => {
  const {
    getHeader,
    // getCountNotification: { data },
    // onClickSider,
    // showNotification,
    visible,
    onClose,
    showDrawer,
    history,
    setLogout
  } = props
  const { md } = GetBreakPoint()
  const { pathname } = window.location

  const handleMenu = (e) => {
    if(e.key === '/keluar'){
      setLogout()
    }else{
      history.push(e.key)
      onClose()
    }
    // scroller.scrollTo(e, {
    //   duration: 800,
    //   delay: 0,
    //   smooth: "easeInOutQuart",
    // });
  }

  const items = [
    { label: 'Beranda', key: '/beranda' },
    {
      label: 'Tentang Program',
      key: 'aboutUsSection',
      children: [
        {
          label: 'Kewirausahaan',
          key: '/kandidat/tentang-program/kewirausahaan',
        },
        {
          label: 'Pertukaran Mahasiswa Merdeka',
          key: '/kandidat/tentang-program/pertukaran-mahasiswa-merdeka',
        },
        {
          label: 'Magang',
          key: '/kandidat/tentang-program/magang',
        },
        {
          label: 'Proyek Kemanusiaan',
          key: '/kandidat/tentang-program/proyek-kemanusiaan',
        },
        {
          label: 'Membangun Desa / KKN',
          key: '/kandidat/tentang-program/membangun-desa-kkn',
        },
      ],
    },
    { label: 'Program', key: '/kandidat/program' },
    { label: 'Galeri', key: '/galeri' },
    // { label: 'Berita & Pengumuman', key: '/kandidat/berita-dan-pengumuman' },
    { label: 'Unduhan', key: '/kandidat/unduhan' },
    { label: 'Hubungi Kami', key: '/kandidat/hubungi-kami' }
  ]

  const itemsMobile = [
    { label: 'Beranda', key: '/' },
    { label: 'Lihat Profil', key: '/profil' },
    {
      label: 'Tentang Program',
      key: 'aboutUsSection',
      children: [
        {
          label: 'Kewirausahaan',
          key: '/tentang-program/kewirausahaan',
        },
        {
          label: 'Pertukaran Mahasiswa Merdeka',
          key: '/tentang-program/pertukaran-mahasiswa-merdeka',
        },
        {
          label: 'Magang',
          key: '/tentang-program/magang',
        },
        {
          label: 'Proyek Kemanusiaan',
          key: '/tentang-program/proyek-kemanusiaan',
        },
        {
          label: 'Membangun Desa / KKN',
          key: '/tentang-program/membangun-desa-kkn',
        },
      ],
    },
    { label: 'Program', key: '/program' },
    { label: 'Galeri', key: '/galeri' },
    // { label: 'Berita & Pengumuman', key: '/berita-dan-pengumuman' },
    { label: 'Unduhan', key: '/unduhan' },
    { label: 'Hubungi Kami', key: '/hubungi-kami' },
    { label: 'Keluar', key: '/keluar' },
  ]


  
  const menu = (
    <Menu style={{ width: 150 }}>
      {/* <Menu.Item className="not-menu" key="avatar">
        <Space className="width-100 mb-8" key="avatar">
          {
            getHeader?.data?.profilePicUrl ?
              <Avatar src={getHeader?.data?.profilePicUrl} /> 
            :
              <Avatar style={{ backgroundColor: '#32AB6D' }} icon={<UserOutlined />} /> 
          }
          <Text className="dark-color" ellipsis={true} style={{ width: 200 }}>{getHeader?.data?.fullName}</Text>
        </Space>
      </Menu.Item> */}
      {/* <Menu.Item className="not-menu" key="/profile">
        <Link className="dark-color" to="/kandidat/profile">
          <Button type="primary" className="border-radius-20 width-100 mb-8" size="small" ghost>View Profile</Button>
        </Link>
      </Menu.Item>
      <Menu.Item className="dark-color" key="/account" onClick={onClickSettings} icon={<SettingOutlined />}>
        Account Settings
      </Menu.Item> */}
      <Menu.Item className="dark-color pt-8 pb-8 pr-16 pl-16" key="/profil" onClick={handleMenu} icon={<UserOutlined />}>
        Lihat Profil
      </Menu.Item>
      <Menu.Item className="dark-color pt-8 pb-8 pr-16 pl-16" key="/logout" onClick={setLogout} icon={<PoweroffOutlined />}>
        Keluar
      </Menu.Item>
    </Menu>
  )

  return (
    <React.Fragment>
      {
        md ?
          <React.Fragment>
            <Row style={{ backgroundColor: '#fff' }}>
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Header style={{ height: 70 }}>
                  <Row className="width-100">
                    <Col className="width-100">
                      <Link to="/">
                        <Image
                          width={140}
                          src={Logo}
                          preview={false}
                          style={{ marginTop: 4 }}
                        />
                      </Link>
                      <Space size={16} style={{ float: 'right', paddingTop: 4 }}>
                        {/* <Badge className="dark-color cursor-pointer" size="small" count={data} onClick={showNotification} style={{ fontSize: 10 }}>
                          <BellOutlined className="fs-20" />
                        </Badge> */}
                        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                          <Link to="#" className="ant-dropdown-link dark-color" onClick={e => e.preventDefault()}>
                            {
                              getHeader?.data?.photoUrl ?
                                <React.Fragment>
                                  <Avatar src={getHeader?.data?.photoUrl} /> <CaretDownFilled />
                                </React.Fragment>
                              :
                                <React.Fragment>
                                  <Avatar style={{ backgroundColor: '#32AB6D' }} icon={<UserOutlined />} /> <CaretDownFilled />
                                </React.Fragment>
                            }
                          </Link>
                        </Dropdown>
                      </Space>
                    </Col>
                  </Row>
                </Header>
              </Col>
            </Row>
            {
              pathname === '/masuk' || pathname === '/lupa-kata-sandi' || pathname === '/ganti-kata-sandi' || pathname === '/daftar' || pathname === '/daftar/konfirmasi-email' ?
                null
              :
                <Row className="header-outside" style={pathname === '/beranda' || pathname === '/kandidat/tentang-program/kewirausahaan' || pathname === '/kandidat/tentang-program/pertukaran-mahasiswa-merdeka' || pathname === '/kandidat/tentang-program/magang' || pathname === '/kandidat/tentang-program/proyek-kemanusiaan' || pathname === '/kandidat/tentang-program/membangun-desa-kkn' ? null :  {boxShadow: '1px 4px 8px #e6e6e6'}}>
                  <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }} style={{ backgroundColor: '#f1f1f1' }}>
                    <Header style={{ backgroundColor: '#f1f3f6', height: 70 }}>
                      <Row className="width-100">
                        <Col className="width-100">
                          <Space style={{ float: 'right', paddingTop: 4 }}>
                            <Menu
                              mode="horizontal"
                              defaultSelectedKeys={['/']}
                              selectedKeys={pathname}
                              onClick={handleMenu}
                              items={items}
                              disabledOverflow={true}
                              style={{ width: '100%', backgroundColor: 'transparent' }}
                            />
                          </Space>
                        </Col>
                      </Row>
                    </Header>
                  </Col>
                </Row>
            }
          </React.Fragment>
        :
          <Header breakpoint="md" className="header-mobile">
            <Link to={ pathname === '/employer' || pathname === '/employer/registration' || pathname === '/employer/registration/success' ? "/employer" : "/" }>
              <img className="logo" src={Logo} alt="..." />
            </Link>
            <Space>
              <Button className="margin-16" icon={<MenuOutlined />} onClick={showDrawer} />
            </Space>
            <Drawer
              placement="right"
              closable={false}
              onClose={onClose}
              open={visible}
              bodyStyle={{ padding: 0 }}
              width={300}
            >
              <Menu
                mode="inline"
                defaultSelectedKeys={['/']}
                selectedKeys={pathname}
                onClick={handleMenu}
                items={itemsMobile}
              />
            </Drawer>
          </Header>
      }

    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(index)