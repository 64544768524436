import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const indexHeader = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_HEADER' })
  return API.GET('/profile/header').then((response) => {
    dispatch({
      type: 'LOAD_DATA_HEADER_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_HEADER_FAILED' }), 
    ))
  })
}