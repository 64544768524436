import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Loading } from '../../../components';
import BeritaDetailComponent from '../../../components/Berita/Detail';
import { detailBerita, unmountDetailBerita } from '../../../redux/actions/berita/beritaAction'
import { dataLandingPage, unmountDataLanding } from '../../../redux/actions/landingPage/landingPageAction';

export class BeritaDetail extends Component {
  componentDidMount() {
    const { actionGetBeritaDetail, actionDataLanding, match: { params } } = this.props
    window.scrollTo(0, 0);
    actionGetBeritaDetail(params.id)
    actionDataLanding()
  }
  render() {
    const { getBeritaDetail } = this.props
    
    if(getBeritaDetail.loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Berita - Detail</title>
        </Helmet>
        <BeritaDetailComponent {...this.props}/>
      </React.Fragment>
    )
  }

  componentWillUnmount() {
    const {unmountDetailBerita} = this.props
    unmountDetailBerita()
  }
}

const mapStateToProps = (state) => ({
  getBeritaDetail : state.beritaDetailReducer,
  getDataLanding : state.landingPageReducer
})

const mapDispatchToProps = {
  actionGetBeritaDetail: detailBerita,
  unmountDetailBerita: unmountDetailBerita,
  actionDataLanding: dataLandingPage,
  unmountDataLanding: unmountDataLanding
}

export default connect(mapStateToProps, mapDispatchToProps)(BeritaDetail)