import React from 'react'
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import { Row, Col, Image } from 'antd';
import Background from '../../assets/img/home/bg.png';
import CTA from '../../assets/img/home/cta.png';

// const { useBreakpoint } = Grid;
// const { Text } = Typography;

// function GetBreakPoint() {
//   const screens = useBreakpoint()
//   return screens;
// }

export default function cta() {
  // const { xs, xxl } = GetBreakPoint()
  
  return (
    <React.Fragment>
      <Row style={{ width: '100%', backgroundColor: '#EBEBEB', backgroundImage: `url(${Background})`, backgroundSize: 'cover' }}>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 18, offset: 3 }} xxl={{ span: 14, offset: 5 }}>
          <Link to={Cookie.get('user') ? '/kandidat/program' : '/daftar'}>
            <Image
              className="mt-64 mb-64"
              width={'100%'}
              src={CTA}
              preview={false}
            />
          </Link>
        </Col>
      </Row>
    </React.Fragment>
  )
}
