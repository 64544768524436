import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { Row, Col, Breadcrumb, Space } from 'antd';
import DetailProgramDesktop from './Desktop';
import { ArrowLeftOutlined } from '@ant-design/icons';

// import DetailProgramMobile from './Mobile';
// const { useBreakpoint } = Grid;

// function GetBreakPoint() {
//   const screens = useBreakpoint()
//   return screens;
// }

export default function StatusLamaranDetail(initialProps) {
  const { getDetail, status } = initialProps
  // const { xs } = GetBreakPoint()

  if(process.env.REACT_APP_ENV === "production"){
    ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], `${getDetail?.data?.title} | MBKM`);
  }
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{getDetail.data.title} | MBKM</title>
      </Helmet>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Breadcrumb className="mb-16 mt-16">
            <Breadcrumb.Item>
              <Link to={'/beranda'}>Beranda</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/profil'>Profil</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {
                status === 'pengajuan' ?
                  <Link to='/profil/status-lamaran/pengajuan'>Pengajuan</Link>
                : status === 'peninjauan' ?
                  <Link to='/profil/status-lamaran/peninjauan'>Peninjauan</Link>
                : status === 'diterima' ?
                  <Link to='/profil/status-lamaran/diterima'>Diterima</Link>
                : null
              }
            </Breadcrumb.Item>
            <Breadcrumb.Item>{getDetail.data.title}</Breadcrumb.Item>
          </Breadcrumb>
          <Link to={`/profil/status-lamaran/${status === 'pengajuan' ? 'pengajuan' : status === 'peninjauan' ? 'peninjauan' : status === 'diterima' ? 'diterima' : null}`}>
          {/* <Link to={status === 'pengajuan' ? '/profile/status-lamaran/pengajuan' : status === 'diterima' ? '/profile/status-lamaran/diterima' : null}> */}
            <Space className="mb-16 fs-18" style={{ color: '#0D6E49' }}>
              <ArrowLeftOutlined /> Kembali
            </Space>
          </Link>
        </Col>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          {
            // xs ?
            //   <DetailProgramMobile {...initialProps} />
            // :
              <DetailProgramDesktop {...initialProps} />
          }
        </Col>
      </Row>
    </React.Fragment>
  )
}