import React from 'react';
import Cookie from 'js-cookie';
import { Row, Col, Space, Typography, Image, Tag, Grid, Divider } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/id';

const { Text } = Typography;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function DetailBerita(props) {
  const { getBeritaDetail: { data }, getDataLanding, history } = props
  const { xxl, xs } = GetBreakPoint()
  const onClickMore = (id) => {
    const { actionGetBeritaDetail } = props
    history.push(Cookie.get('user') ? `/kandidat/berita-dan-pengumuman/detail/${id}` : `/berita-dan-pengumuman/detail/${id}`)
    actionGetBeritaDetail(id)
    window.scrollTo(0, 0);
  }
  return (
    <React.Fragment>
      <Row style={{ backgroundColor: '#fff' }}>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Row className="mt-32 mb-64" gutter={xxl ? 128 : 32}>
            <Col span={12}>
              <Text className="fs-24 font-weight-500">Berita & Pengumuman</Text>
            </Col>
            <Divider style={{ border: xs ? '1px solid #000000' : '2px solid #000000', marginBottom: 32 }} />
            <Col xs={24} lg={14}>
              <Image
                width={'100%'}
                height={xs ? 250 : 500}
                src={data.mainImageUrl}
                preview={false}
                style={{ borderRadius: 16, objectFit: 'cover' }}
              />

              <Row className="mt-16">
                <Col span={24}>
                  <Space className='width-100' direction="vertical" size={4}>
                  <Tag color={data.newsCategory === "NEWS" ? "#633FB5" : "#FF8811"} className={"pr-8 pl-8 fs-11 border-radius-8" }>{data.newsCategory === "NEWS" ? "Berita" : "Pengumuman"}</Tag>
                    <Text className={xs ? "dark-color font-weight-500 fs-16" : "dark-color font-weight-500 fs-20"}>{data.title}</Text>
                    <Space size={32}>
                      <Space size={4}>
                        <ClockCircleOutlined className="fs-12" />
                        <Text className="dark-color fs-12">{moment(data.publishedDate, 'DD/MM/YYYY').format('LL')}</Text>
                      </Space>
                    </Space>
                    <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
                  </Space>
                </Col>
              </Row>
            </Col> 
            
            <Col xs={24} lg={10}>
              {
                getDataLanding?.data?.listNews.map((item, i) =>
                  <Col key={i} className="mb-16 cursor-pointer" span={24} onClick={() => onClickMore(item.id)}>
                    <Row justify="space-around" align="middle" gutter={[16,32]}>
                      <Col span={8}>
                        <Image
                          width={'100%'}
                          height={128}
                          src={item.mainImageUrl}
                          preview={false}
                          style={{ borderRadius: 8, objectFit: 'containt' }}
                        />
                      </Col>

                      <Col span={16}>
                        <Space className='width-100' direction="vertical" size={4}>
                        <Tag color={item.newsCategory === "NEWS" ? "#633FB5" : "#FF8811"} className={"pr-8 pl-8 fs-11 border-radius-8" }>{item.newsCategory === "NEWS" ? "Berita" : "Pengumuman"}</Tag>
                          <Text className="dark-color font-weight-500 fs-16">{item.title}</Text>
                          <Space size={32}>
                            {/* <Text className="light-color fs-12">Oleh {item.tag}</Text> */}
                            <Space size={4}>
                              <ClockCircleOutlined className="fs-12" />
                              <Text className="dark-color fs-12">{item.publishedDate}</Text>
                            </Space>
                          </Space>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                )
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}