import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { Card, Typography, Modal, Button, Input, Space, Form, Row, Col, Select, Spin, message } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { listInstitution, unmountIndexInstitution } from '../../../../redux/actions/master/institution/institutionAction';
import { listFieldOfStudy, unmountIndexFieldOfStudy } from '../../../../redux/actions/master/fieldOfStudy/fieldOfStudyAction';
import { updateEducation } from '../../../../redux/actions/profile/profileAction';

const { Text } = Typography;
const { Option } = Select;

export class Education extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      submitLoading:false,
      metaInstitution: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: ''
      },
      institutionId: null,
      fieldOfStudyId: null,
    }
    this.onSearchInstitution = debounce(this.onSearchInstitution.bind(this), 800)
    this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
  }

  componentDidMount() {
    const { data } = this.props;
    this.setState({ institutionId: data.institutionId, fieldOfStudyId: data.fieldOfStudyId })
  }

  onSearchInstitution = value => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;

    metaInstitution.page = 1
    metaInstitution.perpage = 10
    metaInstitution.search = value

    return actionListInstitution(metaInstitution)
  }

  onFocusInstitution = () => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;
    return actionListInstitution(metaInstitution)
  }

  onSearchFieldOfStudy = value => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;

    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;
    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onClickInstitution = (val, e) => {
    this.setState({ institutionId: Number(e.key) })
  }

  onClickFieldOfStudy = (val, e) => {
    this.setState({ fieldOfStudyId: Number(e.key) })
  }

  showModal = () => {
    this.setState({
      visible: true
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false
    })
  };

  onFinish = async (values) => {
    const { institutionId, fieldOfStudyId } = this.state
    const { actionUpdateEducation, actionGetProfile } = this.props
    values.institutionId = institutionId
    values.fieldOfStudyId = fieldOfStudyId
    return this.setState({ submitLoading: true }, () => {
      return actionUpdateEducation(values, response => {
        if(response.code === 1000){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
      })
    })
  }
  
  render() {
    const { visible, submitLoading } = this.state
    const { getInstitution, getFieldOfStudy, data } = this.props

    return (
      <React.Fragment>
        <Card 
          className="border-radius-6 box-shadow" 
          title="Profil Pendidikan" 
          bordered={false}
          extra={<Button type="link" icon={<EditFilled />} onClick={this.showModal}>Edit</Button>}
          headStyle={{ color: '#32AB6D', fontSize: 17 }}
          bodyStyle={data.institutionId ? {} : { textAlign: 'center', padding: 60 }}
        >
          {
            data.institutionId ?
              <Row className="mb-16">
                <Col span={12}>
                  <Space direction="vertical">
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Asal Perguruan Tinggi</Text>
                      <Text className="dark-color" strong>{data.institutionName ? data.institutionName : '-'}</Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Jurusan</Text>
                      <Text className="dark-color" strong>{data.fieldOfStudyName ? data.fieldOfStudyName : '-'}</Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Nomor Induk Mahasiswa</Text>
                      <Text className="dark-color" strong>{data.nim ? data.nim : '-'}</Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical">
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Semester Aktif</Text>
                      <Text className="dark-color" strong>{data.semester ? data.semester : '-'}</Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">Jumlah SKS yang Sudah Ditempuh</Text>
                      <Text className="dark-color" strong>{data.sksTaken ? data.sksTaken : '-'}</Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12" type="secondary">IPK Terakhir</Text>
                      <Text className="dark-color" strong>{data.gpa ? data.gpa : '-'}</Text>
                    </Space>
                  </Space>
                </Col>
              </Row>
            :
              <Text className="dark-color">Ayo segera isi data pendidikan kamu</Text>
          }
        </Card>

        <Modal 
          title="Profil Pendidikan"
          visible={visible} 
          onCancel={this.handleCancel} 
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={800}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              institutionId: data.institutionName,
              semester: data.semester,
              fieldOfStudyId: data.fieldOfStudyName,
              sksTaken: data.sksTaken,
              nim: data.nim,
              gpa: data.gpa
            }}
          >
            <Row className="mb-min-8" gutter={8}>
              <Col xs={24} lg={12}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">Asal Perguruan Tinggi</Text>
                </Space>
                <Form.Item 
                  name="institutionId"
                  validateFirst
                  rules={[
                    { required: true, message: 'Kolom ini wajib diisi!' },
                  ]}
                >
                  <Select
                    onFocus={this.onFocusInstitution}
                    onSearch={this.onSearchInstitution}
                    notFoundContent={getInstitution?.loading ? <Spin size="small" /> : null}
                    filterOption={false}
                    showSearch
                    placeholder="contoh: Binawan University"
                    onSelect={this.onClickInstitution}
                  >
                    {
                      getInstitution?.data?.map((res) => (
                        <Option key={res.id} value={res.name}>
                          {res.name}
                        </Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">Semester Aktif</Text>
                </Space>
                <Form.Item 
                  name="semester"
                  rules={[
                    { required: true, message: 'Kolom ini wajib diisi!' },
                    { pattern: /^[0-9]*$/, message: 'Kolom ini hanya boleh berisi angka!' },
                    { max: 3, message: 'Kolom ini tidak boleh lebih dari 3 karakter' },
                  ]}
                >
                  <Input size="small" placeholder="contoh: 6" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col xs={24} lg={12}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">Jurusan</Text>
                </Space>
                <Form.Item 
                  name="fieldOfStudyId"
                  validateFirst
                  rules={[
                    { required: true, message: 'Kolom ini wajib diisi!' },
                  ]}
                >
                  <Select
                    onFocus={this.onFocusFieldOfStudy}
                    onSearch={this.onSearchFieldOfStudy}
                    notFoundContent={getFieldOfStudy?.loading ? <Spin size="small" /> : null}
                    filterOption={false}
                    showSearch
                    placeholder="contoh: Keperawatan"
                    onSelect={this.onClickFieldOfStudy}
                  >
                    {
                      getFieldOfStudy?.data?.map((res) => (
                        <Option key={res.id} value={res.name}>
                          {res.name}
                        </Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">SKS Yang Sudah Ditempuh</Text>
                </Space>
                <Form.Item 
                  name="sksTaken"
                  rules={[
                    { required: true, message: 'Kolom ini wajib diisi!' },
                    { pattern: /^[0-9]*$/, message: 'Kolom ini hanya boleh berisi angka!' },
                    { max: 3, message: 'Kolom ini tidak boleh lebih dari 3 karakter' },
                  ]}
                >
                  <Input size="small" placeholder="contoh: 80" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-min-8" gutter={8}>
              <Col xs={24} lg={12}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">Nomor Induk Mahasiswa</Text>
                </Space>
                <Form.Item 
                  name="nim"
                  rules={[
                    { required: true, message: 'Kolom ini wajib diisi!' },
                    { pattern: /^[0-9]*$/, message: 'Kolom ini hanya boleh berisi angka!' },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">IPK Terakhir</Text>
                </Space>
                <Form.Item 
                  name="gpa"
                  rules={[
                    { required: true, message: 'Kolom ini wajib diisi!' },
                    { pattern: /^[0-9a-zA-Z.]*$/, message: 'Hanya titik (.) yang diperbolehkan, Tidak boleh ada spasi' },
                    { max: 4, message: 'Kolom ini tidak boleh lebih dari 4 karakter' },
                  ]}
                >
                  <Input size="small" placeholder="contoh: 3.50" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space className="float-right">
                <Button onClick={this.handleCancel}>Kembali</Button>
                <Button htmlType="submit" type="primary" loading={submitLoading}>Simpan</Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
  // componentWillUnmount(){
  //   const { unmountIndexInstitution, unmountIndexFieldOfStudy } = this.props
  //   return (unmountIndexInstitution(), unmountIndexFieldOfStudy())
  // }
}

const mapStateToProps = (state) => ({
  getInstitution: state.institutionReducer,
  getFieldOfStudy: state.fieldOfStudyReducer
})

const mapDispatchToProps = {
  actionListInstitution: listInstitution,
  unmountIndexInstitution: unmountIndexInstitution,
  actionListFieldOfStudy: listFieldOfStudy,
  unmountIndexFieldOfStudy: unmountIndexFieldOfStudy,
  actionUpdateEducation: updateEducation
}

export default connect(mapStateToProps, mapDispatchToProps)(Education)