
const LOAD_DATA_NEWS           = 'LOAD_DATA_NEWS'
const LOAD_DATA_NEWS_SUCCESS   = 'LOAD_DATA_NEWS_SUCCESS'
const LOAD_DATA_NEWS_FAILED    = 'LOAD_DATA_NEWS_FAILED'
const UNMOUNT_DATA_NEWS        = 'UNMOUNT_DATA_NEWS'
const initialState = {
  loading: true,
  meta: null,
  data: null,
  message: null,
}
const newsReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_DATA_NEWS:
      return {
        ...state,
        loading: true,
        meta: null,
        data: null,
      }
    case LOAD_DATA_NEWS_SUCCESS:
      return { 
        ...state, 
        loading: false,
        meta: action.payload.meta,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_DATA_NEWS_FAILED:
      return { 
        ...state, 
        loading: true,
        meta: null,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_DATA_NEWS:
      return { 
        ...state, 
        loading: true,
        meta: null,
        data: null,
      }
    default:
      return state
  }
}
export default newsReducer;