import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Loading } from '../../../../../components';
import { Space, Upload, Button, Typography, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import StatusLamaranDetail from '../../../../../components/StatusLamaran/Detail';
import { detailApplications, unmountDetailApplications } from '../../../../../redux/actions/statusLamaran/statusLamaranAction';
import { addActivity, updateActivity } from '../../../../../redux/actions/activity/activityAction';

const { Text } = Typography;
export class index extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      visible:false,
      visibleNotes:false,
      activeKey: '1',
      file: null,
      showFileEdit: false,
      dataEdit: null
    }
  }
  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props

    window.scrollTo(0, 0);
    
    actionGetDetail(params.id)
  }

  showModal = (val) => {
    this.setState({ 
      visible: true, 
      dataEdit: val,
      file: null,
      showFileEdit: val ? true : false,
    })
  };

  showModalNotes = () => {
    this.setState({ visibleNotes: true })
  };

  handleCancel = () => {
    this.setState({ 
      visible: false, 
      visibleNotes: false,
      file: null,
      showFileEdit: false,
    })
  };

  onFinish = (values) => {
    const { actionAddActivity, actionUpdateActivity, actionGetDetail, getDetail, match: { params } } = this.props
    // const { dataEdit, file, showFileEdit } = this.state
    const { dataEdit, file } = this.state
    values.participantId = getDetail.data.id
    values.activityDate = moment(values.activityDate).format('DD/MM/YYYY')
    if(values.file){
      values.file = file
    }else{
      delete values.file
    }
    // if(values.file || showFileEdit){
      if(dataEdit){
        values.id = dataEdit.id
        return this.setState({ submitLoading: true }, () => {
          return actionUpdateActivity(values, response => {
            if(response.code === 1000){
              message.success('Aktivitas Anda berhasil diubah')
              actionGetDetail(params.id)
              this.setState({ submitLoading: false, visible: false, activeKey: '5' }, () => {
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false }, () => message.error(err.message))
          })
        })
      }else{
        return this.setState({ submitLoading: true }, () => {
          return actionAddActivity(values, response => {
            if(response.code === 1000){
              message.success('Aktivitas Anda berhasil ditambahkan')
              actionGetDetail(params.id)
              this.setState({ submitLoading: false, visible: false, activeKey: '5' }, () => {
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false }, () => message.error(err.message))
          })
        })
      }
    // }else{
    //   message.error('Dokumen tidak boleh kosong!')
    // }
  }
  
  handleTab = (e) => {
    this.setState({ activeKey: e })
  }

  handleUpload(){
    return {
      withCredentials: true,
      // accept:".pdf",
      beforeUpload: file => {
        const validateSize = file.size >= 5000000;
        if (validateSize) {
          message.error('Max file size is 5MB!');
          return false
        }
        // if (file.type !== 'application/pdf') {
        //   message.error('PDF file only!');
        //   return false
        // }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            file: file,
          })
          message.info('Upload Success!');
        }
        return false;
      },
    }
  }

  handleRemove = (file) => {
    this.setState({ 
      file: null,
      showFileEdit: false,
    })
    message.success(`${file.name} file removed successfully`);
  }

  render() {
    const { getDetail } = this.props
    const { visible, visibleNotes, submitLoading, activeKey, dataEdit, file, showFileEdit } = this.state
    
    const addFileList = [
      {
        uid: '-1',
        name: file?.name,
        status: 'done'
      }
    ]

    const editFileList = [
      {
        uid: '-1',
        name: dataEdit?.fileName,
        status: 'done',
        url: dataEdit?.fileUrl,
        thumbUrl: dataEdit?.fileUrl,
      }
    ]
    
    const uploadDocument = (
      <Upload 
        {...this.handleUpload()} 
        onRemove={this.handleRemove}
        listType="picture" 
        maxCount={1}
        showUploadList={file || dataEdit ? true : false}
        fileList={file ? addFileList : showFileEdit ? editFileList : []}
      >
        <Space direction='vertical' size={0}>
          <Button icon={<UploadOutlined />}>
            <Text>Upload Dokumen</Text>
          </Button>
          {/* <Text className="fs-12" type="secondary">Document Format PDF Only, Max file size is 5MB.</Text> */}
          <Text className="fs-12" type="secondary">Max file size is 5MB.</Text>
        </Space>
      </Upload>
    )

    const initialProps = {
      status: 'diterima',
      visible: visible,
      visibleNotes: visibleNotes,
      submitLoading: submitLoading,
      activeKey: activeKey,
      dataEdit: dataEdit,
      showModal: this.showModal,
      showModalNotes: this.showModalNotes,
      handleCancel: this.handleCancel,
      onFinish: this.onFinish,
      handleTab: this.handleTab,
      uploadDocument: uploadDocument
    }

    if(getDetail.loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <StatusLamaranDetail {...initialProps} {...this.props} />
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountDetailApplications } = this.props
    return unmountDetailApplications()
  }
}

const mapStateToProps = (state) => ({
  getDetail: state.detailApplicationsReducer
})

const mapDispatchToProps = {
  actionGetDetail: detailApplications,
  actionAddActivity: addActivity,
  actionUpdateActivity: updateActivity,
  unmountDetailApplications: unmountDetailApplications
}

export default connect(mapStateToProps, mapDispatchToProps)(index)
