import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';
import { Layout } from 'antd';
import Header from './Header';
import Footer from './Footer';
import { checkAuth, checkToken, setLogout } from '../../redux/actions/auth/authAction';
import { indexHeader } from '../../redux/actions/header/headerAction';

const { Content } = Layout;

export class MainPrivate extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      sidebar: true,
      params: {
        page: 1,
        perpage: 100
      },
      visibleNotification: false
    }
  }
  
  componentDidMount() {
    const { actionCheckToken, actionGetHeader } = this.props
    const getToken = Cookie.get('user')
    
    if(!getToken){
      window.location.reload()
    }

    if(!localStorage.getItem('imageCandidate')){
      actionGetHeader()
      localStorage.setItem('imageCandidate', true);
    }

    return actionCheckToken((response) => {
      if(!response){
        window.location.reload()
      }
    })
  }

  showDrawer = () => {
    this.setState({
      visible: true
    })
  }

  onClose = () => {
    this.setState({
      visible: false
    })
  }

  onClickSider = () => {
    const { sidebar } = this.state
    this.setState({
      sidebar: sidebar === true ? false : true
    })
  }

  showNotification = () => {
    this.setState({
      visibleNotification: true
    })
  }

  onCloseNotification = () => {
    this.setState({
      visibleNotification: false
    })
  }

  setLogout = () => {
    const { actionSetLogout } = this.props;
    
    if(localStorage.getItem('path-interview')){
      localStorage.removeItem('path-interview')
    }
    
    return new Promise((resolve, reject) => {
      actionSetLogout(() => { resolve() }, () => reject())
    });
  }

  render() {
    const { children } = this.props
    const { visible } = this.state
    const initialProps = {
      visible: visible,
      showDrawer: this.showDrawer,
      onClose: this.onClose,
      history: this.props.history,
      setLogout: this.setLogout
    }

    return (
      <Layout style={{ position: 'relative', minHeight: '100vh', backgroundColor: '#F8F8F8' }}>
        <Header {...initialProps} {...this.props} />
        <Content>
          { children }
        </Content>
        <Footer style={{ position: 'absolute', bottom: 0, width: '100%', height: '2.5rem' }} />
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  getHeader: state.headerReducer,
  // listNotification: state.notificationReducer,
  // getCountNotification: state.countNotificationReducer
})

const mapDispatchToProps = {
  actionCheckAuth: checkAuth,
  actionCheckToken: checkToken,
  actionSetLogout: setLogout,
  actionGetHeader: indexHeader
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPrivate)