import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const unmountListNews = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_NEWS'})
}

export const unmountDetailNews = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_DETAIL'})
}


export const listNews = (meta, successCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_NEWS' })
  return API.GET('/news/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_NEWS_SUCCESS', 
      payload: {
        data: response.data,
        meta: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
    return successCB(meta)
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_NEWS_FAILED' }), 
    ))
  })
}

export const detailNews = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_NEWS' })
  const params = {
    'id': id
  }
  API.GET('/news/getById/', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_ACADEMIC_NEWS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_NEWS_FAILED' }), 
      ))
    )
  })
}
