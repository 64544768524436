import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, Space, Form, Input, Select, Button, Upload, Spin, message } from 'antd';
import { DeleteOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export class index extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      showFile: false,
    }
  }

  handleUpload(){
    return {
      withCredentials: true,
      // accept:".pdf",
      beforeUpload: file => {
        const validateSize = file.size >= 5000000;
        if (validateSize) {
          message.error('Max file size is 5MB!');
          this.setState({ showFile: false })
          return false
        }
        // if (file.type !== 'application/pdf') {
        //   message.error('PDF file only!');
        //   this.setState({ showFile: false })
        //   return false
        // }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            documentFile: file,
            showFile: true
          })
          message.info('Upload Success!');
        }
        return false;
      },
    }
  }

  render() {
    const { showFile } = this.state
    const { getProgramDetail: { data }, getProfile, getInstitution, getFieldOfStudy, onFocusInstitution, onFocusFieldOfStudy, onSearchInstitution, onSearchFieldOfStudy, onClickInstitution, onClickFieldOfStudy, onDelete } = this.props
    return (
      <React.Fragment>
        <Row className="mb-min-8" gutter={8}>
          {
            data.listPositions ?
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-15" type="danger">*</Text>
                  <Text className="fs-15" type="secondary">Posisi Tersedia</Text>
                </Space>
                <Form.Item 
                  name="positionsId"
                  validateFirst
                  // rules={[
                  //   { required: true, message: 'Kolom ini wajib diisi!' },
                  // ]}
                >
                  <Select
                    filterOption={false}
                    placeholder="Pilih Posisi"
                  >
                    {
                      data?.listPositions?.map((res, i) => (
                        <Option key={i} value={res.positionId}>
                          {res.positionName}
                        </Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
            : data.listFieldOfStudy ?
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-15" type="danger">*</Text>
                  <Text className="fs-15" type="secondary">Jurusan Tersedia</Text>
                </Space>
                <Form.Item 
                  name="fieldOfStudyId"
                  validateFirst
                  // rules={[
                  //   { required: true, message: 'Kolom ini wajib diisi!' },
                  // ]}
                >
                  <Select
                    filterOption={false}
                    placeholder="Pilih Jurusan"
                  >
                    {
                      data?.listFieldOfStudy?.map((res, i) => (
                        <Option key={i} value={res.fieldOfStudyId}>
                          {res.fieldOfStudyName}
                        </Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
            : null
          }
        </Row>

        <Row className="mb-min-8" gutter={8}>
          <Col span={24}>
            <Space size={4}>
              {/* <Text className="fs-15" type="danger">*</Text> */}
              <Text className="fs-15" type="secondary">Lampiran</Text>
            </Space>
            <Form.List name="listDocument" initialValue={data.listDocument}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Row key={field.fieldKey} gutter={[16,0]}>
                      <Col span={17}>
                        <Form.Item 
                          {...field}
                          name={[index, 'name']}
                          // rules={[
                          //   { required: true, message: 'This is a required field' },
                          // ]}
                        >
                          <Input disabled={index < data?.listDocument?.length} />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item 
                          {...field}
                          name={[index, 'file']}
                          fieldKey={[index, 'file']}
                          // rules={[
                          //   { required: true, message: 'This is a required field' },
                          // ]}
                        >
                          <Upload 
                            {...this.handleUpload()} 
                            maxCount={1}
                            showUploadList={showFile}
                          >
                            <Button icon={<UploadOutlined />}><Text>Upload Dokumen</Text></Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        {
                          fields.length > 1 ?
                            <Form.Item>
                              <DeleteOutlined onClick={() => {
                                remove(field.name);
                              }} />
                            </Form.Item>
                          : null
                        }
                      </Col>
                    </Row>
                  ))}
                  <Form.Item className='mt-8'>
                    {/* <Text className='fs-15 light-color'>Document Format PDF Only, Max file size is 5MB.</Text> */}
                    <Text className='fs-15 light-color'>Max file size is 5MB.</Text>
                    <Button type="dashed" onClick={() => { add() }} block icon={<PlusOutlined />}>
                      Tambah Lampiran
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        
        {
          data.isGroup ? 
            <Row className="mb-min-8" gutter={8}>
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-15" type="danger">*</Text>
                  <Text className="fs-15" type="secondary">Peserta</Text>
                </Space>
                <Form.List name="listMember" initialValue={['','']}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <Row key={index} gutter={[16,0]} align="middle">
                          <Col span={6}>
                            <Form.Item 
                              {...field}
                              label="Nama"
                              name={[index, 'name']}
                              initialValue={index === 0 ? getProfile?.data?.fullName : null}
                              // rules={[
                              //   { required: true, message: 'This is a required field' },
                              // ]}
                            >
                              <Input disabled={index===0} />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item 
                              {...field}
                              label="NIM"
                              name={[index, 'nim']}
                              initialValue={index === 0 ? getProfile?.data?.nim : null}
                              // rules={[
                              //   { required: true, message: 'This is a required field' },
                              // ]}
                            >
                              <Input disabled={index===0} />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item 
                              {...field}
                              label="Asal Kampus"
                              name={[index, 'institutionId']}
                              initialValue={index === 0 ? getProfile?.data?.institutionName : null}
                              // rules={[
                              //   { required: true, message: 'This is a required field' },
                              // ]}
                            >
                              <Select
                                onFocus={onFocusInstitution}
                                onSearch={onSearchInstitution}
                                notFoundContent={getInstitution?.loading ? <Spin size="small" /> : null}
                                filterOption={false}
                                showSearch
                                onSelect={onClickInstitution}
                                disabled={index===0}
                              >
                                {
                                  getInstitution?.data?.map((res) => (
                                    <Option index={index} key={res.id} value={res.name}>
                                      {res.name}
                                    </Option>
                                  ))
                                }
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item 
                              {...field}
                              label="Jurusan"
                              name={[index, 'fieldOfStudyId']}
                              initialValue={index === 0 ? getProfile?.data?.fieldOfStudyName : null}
                              // rules={[
                              //   { required: true, message: 'This is a required field' },
                              // ]}
                            >
                              <Select
                                onFocus={onFocusFieldOfStudy}
                                onSearch={onSearchFieldOfStudy}
                                notFoundContent={getFieldOfStudy?.loading ? <Spin size="small" /> : null}
                                filterOption={false}
                                showSearch
                                onSelect={onClickFieldOfStudy}
                                disabled={index===0}
                              >
                                {
                                  getFieldOfStudy?.data?.map((res) => (
                                    <Option index={index} key={res.id} value={res.name}>
                                      {res.name}
                                    </Option>
                                  ))
                                }
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item 
                              {...field}
                              label="Semester"
                              name={[index, 'semester']}
                              initialValue={index === 0 ? getProfile?.data?.semester : null}
                              // rules={[
                              //   { required: true, message: 'This is a required field' },
                              // ]}
                            >
                              <Select 
                                className="width-100 border-radius-6"
                                disabled={index===0} 
                              >
                                <Option key="1" value={1}>1</Option>
                                <Option key="2" value={2}>2</Option>
                                <Option key="3" value={3}>3</Option>
                                <Option key="4" value={4}>4</Option>
                                <Option key="5" value={5}>5</Option>
                                <Option key="6" value={6}>6</Option>
                                <Option key="7" value={7}>7</Option>
                                <Option key="8" value={8}>8</Option>
                                <Option key="9" value={9}>9</Option>
                                <Option key="10" value={10}>10</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={1}>
                            {
                              index > 1 ?
                                <Form.Item>
                                  <DeleteOutlined onClick={() => {
                                    remove(field.name);
                                    onDelete(index)
                                  }} />
                                </Form.Item>
                              : null
                            }
                          </Col>
                        </Row>
                      ))}
                      <Form.Item className='mt-8'>
                        <Button type="dashed" onClick={() => { add() }} block icon={<PlusOutlined />}>
                          Tambah Peserta
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
          : null
        }
        <Row className="mb-min-8" gutter={8}>
          <Col span={24}>
            <Text className="fs-15" type="secondary">Informasi Tambahan</Text>
            <Form.Item 
              name="additionalInfo"
            >
              <TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(index)