import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Space, Typography, Image, Grid } from 'antd';
import iconCheck from '../../../assets/img/check.svg';

const { Text } = Typography;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function modal(props) {
  const { sm } = GetBreakPoint()

  return (
    <React.Fragment>
      {
        sm ?
        <Row>
          <Col span={10}>
            <Image
              width={200}
              src={iconCheck}
              preview={false}
            />
          </Col>
          <Col span={14}>
            <Space className="mt-64" direction="vertical">
              <Space direction="vertical">
                <Text className="fs-20 primary-color" strong>Berhasil!</Text>
                <Text className="fs-1 dark-color">Pengajuan Anda telah dikirim ke Tim MBKM</Text>
              </Space>
              <Text className="fs-13 dark-color">Anda dapat melihat pengajuan Anda kapanpun di 
                <Link className="ml-4 fs-13 info-color" to="/profil/status-lamaran/pengajuan">
                  halaman pengajuan
                </Link>
              </Text>
            </Space>
          </Col>
        </Row>
        :
          <Row>
            <Col span={9}>
              <Image
                width={130}
                src={iconCheck}
                preview={false}
              />
            </Col>
            <Col span={14}>
              <Space className="mt-64" direction="vertical">
                <Space direction="vertical">
                  <Text className="fs-20 primary-color" strong>Berhasil!</Text>
                  <Text className="fs-1 dark-color">Pengajuan Anda telah dikirim ke Tim MBKM</Text>
                </Space>
                <Text className="fs-13 dark-color">Anda dapat melihat pengajuan Anda kapanpun di
                  <Link className="ml-4 fs-13 info-color" to="/kandidat/pengajuan">
                    halaman pengajuan
                  </Link>
                </Text>
              </Space>
            </Col>
            <Col span={1}></Col>
          </Row>
      }
    </React.Fragment>
  )
}
