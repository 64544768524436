import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Form, Input, DatePicker } from 'antd';

const { TextArea } = Input;

export class index extends Component {
  render() {
    const { dataEdit, uploadDocument } = this.props
    return (
      <React.Fragment>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              name="activityDate"
              label="Tanggal Aktivitas"
              validateFirst
              initialValue={dataEdit?.activityDate ? moment(dataEdit.activityDate, 'DD/MM/YYYY') : null}
              rules={[
                { required: true, message: 'Kolom ini wajib diisi!' }
              ]}
            >
              <DatePicker placeholder="Pilih tanggal" style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="summaryReport"
              label="Laporan Ringkas"
              validateFirst
              initialValue={dataEdit?.summaryReport ? dataEdit.summaryReport : null}
              rules={[
                { required: true, message: 'Kolom ini wajib diisi!' },
                { max: 1000, message: '1000 characters only' }
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item 
              name="file"
              label="Dokumen Pendukung"
              // rules={[
              //   { required: !dataEdit, message: 'This is a required field' },
              // ]}
            >
              {uploadDocument}
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(index)