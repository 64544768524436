import React from 'react';
import Cookie from 'js-cookie';
import { Row, Col, Space, Typography, Image, Tag, Grid, Divider, Input, Form, Pagination, Skeleton, Empty } from 'antd';
import { ClockCircleOutlined,  DoubleRightOutlined} from '@ant-design/icons';

const { Search } = Input;
const { Text } = Typography;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function ListBerita(props) {
  const { xxl, lg, xs } = GetBreakPoint()
  const { 
    getNews,
    getDataLanding,
    onSearch,
    onChangeTag,
    history,
    handlePagination,
    selectedTag
  } = props

  const onClickMore = (id) => {
    history.push(Cookie.get('user') ? `/kandidat/berita-dan-pengumuman/detail/${id}` : `/berita-dan-pengumuman/detail/${id}`)
  }

  return (
    <React.Fragment>
      <Row style={{ backgroundColor: '#fff' }}>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Row className={xs ? "mt-8" : "mt-32 mb-64"} gutter={xxl ? 128 : xs ? 0: 32}>
            <Col span={24}>
              <Text className={xs ? "fs-20 font-weight-500" : "fs-24 font-weight-500"}>Berita & Pengumuman</Text>
            </Col>
            <Divider style={{ border: '2px solid #000000', marginBottom: xs ? 16 : 32, marginTop: xs ? 8 : 16 }} />
            <Col span={24}>
              <Row className='width-100' gutter={xs ? 0 : 16}>
                <Col className='width-100' xs={{ span: 24 }} lg={{ span: 14 }}>
                  {
                    getNews?.loading ? 
                      <Skeleton className='width-100' active avatar />
                    :
                      getNews?.meta?.total > 0 ?
                        getNews?.data?.map((item, i) =>
                          <Row key={i}>
                            <Col span={24}>
                              <Image
                                width={'100%'}
                                height={xs ? 250 : 500}
                                src={item.mainImageUrl}
                                preview={false}
                                style={{ borderRadius: 16, objectFit: 'containt' }}
                              />

                              <Row className="mt-16">
                                <Col span={24}>
                                  <Space className='width-100' direction="vertical" size={4}>
                                    <Tag color={item.newsCategory === "NEWS" ? "#633FB5" : "#FF8811"} className={"pr-8 pl-8 fs-11 border-radius-8" }>{item.newsCategory === "NEWS" ? "Berita" : "Pengumuman"}</Tag>
                                    <Text className="dark-color font-weight-500 fs-20 cursor-pointer" onClick={() => onClickMore(item.id)}>{item.title}</Text>
                                    <Space size={32}>
                                      <Space size={4}>
                                        <ClockCircleOutlined className="fs-12" />
                                        <Text className="dark-color fs-12">{item.publishedDate}</Text>
                                      </Space>
                                    </Space>
                                    <Text>
                                      <div className="news-ellpisis" style={{ textOverflow: 'ellipsis' }} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                      <Text className="primary-color cursor-pointer" onClick={() => onClickMore(item.id)}>Baca Selengkapnya <DoubleRightOutlined /></Text>
                                    </Text>
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                            <Divider />
                          </Row>
                        ) 
                      : 
                        <Empty />
                  }
                </Col>
                {
                  xs ? 
                    <Col className='mb-32' span={24}>
                      <Pagination onChange={handlePagination} current={getNews?.meta?.page} pageSize={getNews?.meta?.perpage || 12} total={getNews?.meta?.total} />
                    </Col>
                  : null
                }
                <Col className='width-100' xs={{ span: 24 }} lg={{ span: 10 }}>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="search">
                        <Search
                          placeholder="Cari Berita atau Pengumuman .. "
                          allowClear
                          enterButton="Cari"
                          onSearch={onSearch}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Space className='width-100' direction='vertical' size={12} >
                        <Text className='dark-color' strong>Kategori</Text>
                        <Text className={selectedTag === 'ALL' ? "primary-color cursor-pointer" : "cursor-pointer"} onClick={() => onChangeTag('ALL')}>Semua</Text>
                        <Divider style={{marginTop: 0, marginBottom: 0 }}/>
                        <Text className={selectedTag === 'NEWS' ? "primary-color cursor-pointer" : "cursor-pointer"} onClick={() => onChangeTag('NEWS')}>Berita</Text>
                        <Divider style={{marginTop: 0, marginBottom: 0 }}/>
                        <Text className={selectedTag === 'ANNOUNCEMENT' ? "primary-color cursor-pointer" : "cursor-pointer"} onClick={() => onChangeTag('ANNOUNCEMENT')}>Pengumuman</Text>
                        <Divider style={{marginTop: 0, marginBottom: 30 }}/>
                      </Space>
                    </Col>
                  </Row>
                  <Row justify="space-around" align="middle" gutter={[16,32]}>
                    <Col span={24}>
                      <Text strong>Berita & Pengumuman Terkini</Text>
                    </Col>
                  </Row>
                  <Row gutter={[16,0]}>
                    {
                      getDataLanding?.data?.listNews.map((item, i) =>
                        <Col key={i} className="mb-16 cursor-pointer" span={24} onClick={() => onClickMore(item.id)}>
                          <Row justify="space-around" align="middle" gutter={[16,32]}>
                            <Col span={8}>
                              <Image
                                width={'100%'}
                                height={xs ? 100 : 128}
                                src={item.mainImageUrl}
                                preview={false}
                                style={{ borderRadius: 8, objectFit: 'containt' }}
                              />
                            </Col>

                            <Col span={16}>
                              <Space className='width-100' direction="vertical" size={4}>
                              <Tag color={item.newsCategory === "NEWS" ? "#633FB5" : "#FF8811"} className={"pr-8 pl-8 fs-11 border-radius-8" }>{item.newsCategory === "NEWS" ? "Berita" : "Pengumuman"}</Tag>
                                <Text className={xs ? "dark-color font-weight-500 fs-14" : "dark-color font-weight-500 fs-16"}>{item.title}</Text>
                                <Space size={32}>
                                  {/* <Text className="light-color fs-12">Oleh {item.tag}</Text> */}
                                  <Space size={4}>
                                    <ClockCircleOutlined className="fs-12" />
                                    <Text className="dark-color fs-12">{item.publishedDate}</Text>
                                  </Space>
                                </Space>
                              </Space>
                            </Col>
                          </Row>
                        </Col>
                      )
                    }
                  </Row>
                </Col>
              </Row>
            </Col>
            {
              lg ?
                <Col span={24}>
                  <Pagination onChange={handlePagination} current={getNews?.meta?.page} pageSize={getNews?.meta?.perpage || 12} total={getNews?.meta?.total} />
                </Col>
              : null
            }
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}
