import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Form, Input, Card, Button, Row, Col, Typography, Space, Statistic, message } from 'antd';
import { forgotPassCode, forgotPassResend } from '../../../redux/actions/forgotPassword/forgotPasswordAction';

const { Countdown } = Statistic;
const { Text } = Typography

export class Verify extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      isCountdown: true,
      countdown: Date.now() + 60 * 2000
    }
  }
  
  onFinish =  (values) => {
    const { actionForgotPassCode } = this.props

    values.email = localStorage.getItem('emailForgotPass')
    
    return this.setState({ submitLoading: true }, () => {
      actionForgotPassCode(values, response => {
        if(response.code === 1000){
          this.setState({ submitLoading: false }, () => {
            this.props.history.push('/ganti-kata-sandi')
          })
        }else{
          this.setState({ submitLoading: false })
          message.error(response.message)
        }
      }, (err) => {
        this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  onClickResend = () => {
    const { actionForgotPassResend } = this.props

    const value = {
      email: localStorage.getItem('emailForgotPass')
    }

    actionForgotPassResend(value, response => {
      message.success(response.message)
      this.setState({
        isCountdown: true,
        countdown: Date.now() + 60 * 2000
      })
    }, (err) => {
      message.error(err)
    })
  }

  onFinishCountdown = () => {
    this.setState({
      isCountdown: false
    })
  }

  render() {
    const { submitLoading, isCountdown, countdown } = this.state
    
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'MBKM - Lupa Kata Sandi - Verifikasi Email');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Lupa Kata Sandi - Verifikasi Email</title>
        </Helmet>
        <Row className="display-flex" align="middle" justify="center">
          <Col>
            <Card className="card-forgot-password" bodyStyle={{ padding: 0, display: 'flex' }}>
              <Form onFinish={this.onFinish}>
                <Space className="width-100" direction="vertical" size="small">
                  <Text className="dark-color font-weight-normal mb-0 fs-26" level={2}>Kami Mengirimkan Kode OTP ke Email Anda</Text>
                  <Text className="dark-color font-weight-normal">Masukkan kode yang kita kirim ke email {localStorage.getItem('emailForgotPass')}</Text>
                </Space>
                <Form.Item className="text-align-center" name="activationCode">
                  <Input className="input-code text-align-center mt-32 mb-16" placeholder="_  _  _  _  _  _" size="large" />
                </Form.Item>
                <Button className="width-100 mb-8" htmlType="submit" type="primary" loading={submitLoading}>Kirim</Button>
                <Space direction="vertical" size={0}>
                  <Text className="fs-11 text-align-left" type="secondary">Jika anda tidak menerima email dari kami, periksa kembali di folder spam/sampah.</Text>
                  <Space size={4}>
                    <Text className="fs-11 text-align-left" type="secondary">Tidak menerima kode? </Text>
                    {
                      isCountdown ?
                      <Countdown value={countdown} onFinish={this.onFinishCountdown} format="mm:ss" valueStyle={{ fontSize: 11 }} />
                      :
                      <Text className="fs-12 info-color cursor-pointer" onClick={this.onClickResend}>Kirim ulang kode</Text>
                    }
                  </Space>
                </Space>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionForgotPassCode: forgotPassCode,
  actionForgotPassResend: forgotPassResend
}

export default connect(mapStateToProps, mapDispatchToProps)(Verify)