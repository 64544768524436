import axios from 'axios';
import { env } from './index';
import Cookie from 'js-cookie';

export const GET = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With':' XMLHttpRequest'
  }
  const headerWithAuth = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With':' XMLHttpRequest',
    'X-Authorization': `Bearer ${getToken}`
  }
  return new Promise((resolve ,reject) => {
    axios.get(`${env}${path}`, {
      headers: getToken ? headerWithAuth : header,
      params: payload
    }).then((response) => {
      let data = response.data;
      if(response.status === 200){
        return resolve(data);
      }else{
        const error = { message: 'error' }
        return reject(error)
      }
    }).catch((err) => {
      return reject(err.response.data)
    })
  })
}

export const POST = (path, payload) => {
  const getToken = Cookie.get('user');

  const header = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With':' XMLHttpRequest'
  }
  const headerWithAuth = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With':' XMLHttpRequest',
    'X-Authorization': `Bearer ${getToken}`
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: getToken ? headerWithAuth : header
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data);
    })
  })
}

export const POST_EMAIL = (path, payload) => {
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header,
      params: payload
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    })
  })
}

export const POST_WITH_PARAMS = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With':' XMLHttpRequest'
  }
  const headerWithAuth = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With':' XMLHttpRequest',
    'X-Authorization': `Bearer ${getToken}`
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: getToken ? headerWithAuth : header,
      params: payload
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data);
    })
  })
}

export const POST_FORM_DATA = (path, payload) => {
  const getToken = Cookie.get('user');
  return new Promise((resolve, reject) => {
    const data = new FormData();

    if(path === '/profile/update-photo'){
      for (const [val, name] of Object.entries(payload)) {
        if(val === "photoFile"){
          data.append(val, name);
        }
      }
    }else if(path === '/participant/apply'){
      for (const [name,val] of Object.entries(payload)) {
        if(name === 'programId'){
          data.append(name, val);
        }
        if(name === 'positionsId'){
          if(val){
            data.append(name, val);
          }
        }
        if(name === 'fieldOfStudyId'){
          if(val){
            data.append(name, val);
          }
        }
        if(name === 'listDocument'){
          if(val){
            val.map((item, i) => 
              item.id ?
                data.append(`listDocument[${i}].documentId`, item.id)
              : null
            )
            val.map((item, i) => 
              item.name ?
                data.append(`listDocument[${i}].name`, item.name)
              : null
            )
            val.map((item, i) => 
              item.file ? 
                data.append(`listDocument[${i}].file`, item.file.file)
              : null
            )
          }
        }
        if(name === 'listMember'){
          if(val){
            val.map((item, i) => 
              item.name ? 
                data.append(`listMember[${i}].name`, item.name)
              : null
            )
            val.map((item, i) =>
              item.nim ?
                data.append(`listMember[${i}].nim`, item.nim)
              : null
            )
            val.map((item, i) => 
              item.institutionId ? 
                data.append(`listMember[${i}].institutionId`, item.institutionId)
              : null
            )
            val.map((item, i) => 
              item.fieldOfStudyId ? 
                data.append(`listMember[${i}].fieldOfStudyId`, item.fieldOfStudyId)
              : null
            )
            val.map((item, i) => 
              item.semester ? 
                data.append(`listMember[${i}].semester`, item.semester)
              : null
            )
          }
        }
        if(name === 'additionalInfo'){
          if(val){
            data.append(name, val)
          }
        }
      }
    }else{
      for (const [val, name] of Object.entries(payload)) {
        data.append(val, name);
      }
    }

    axios.post(`${env}${path}`, data, {
      headers: {
        'Content-Type' : 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
        'Cache-Control': 'no-cache',
        'X-Authorization': `Bearer ${getToken}`
      },
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data);
    })
  }) 
}

// export const POST_FORM_DATA_ARRAY = (path, payload) => {
//   const getToken = Cookie.get('user');
//   return new Promise((resolve, reject) => {
//     const data = new FormData();
//     for (const [index,name] of Object.entries(payload)) {
//       data.append('programId', name.programId);
//       if(name.positionsId){
//         data.append('positionsId', name.positionsId);
//       }
//       if(name.fieldOfStudyId){
//         data.append('fieldOfStudyId', name.fieldOfStudyId);
//       }
//       if(name.listDocument){
//         name.listDocument.map((item, i) => 
//           data.append(`listDocument[${i}].name`, item.name )
//         )
//         name.listDocument.map((item, i) => 
//           data.append(`listDocument[${i}].file`, item.file.file )
//         )
//       }
//       data.append('additionalInfo', name.additionalInfo);
//     }
//     axios.post(`${env}${path}`, data, {
//       headers: {
//         'Content-Type' : 'multipart/form-data',
//         'X-Requested-With': 'XMLHttpRequest',
//         'Cache-Control': 'no-cache',
//         'X-Authorization': `Bearer ${getToken}`
//       },
//     }).then((response) => {
//       if(response.status === 200){
//         return resolve(response.data);
//       }else{
//         return reject();
//       }
//     }).catch((err) => {
//       return reject(err.response.data.message);
//     })
//   }) 
// }

export const UPLOAD = async (path, payload) => {
  var getToken = Cookie.get('user');
  return new Promise((resolve, reject) => {
    var data = new FormData();
    for (const [val, name] of Object.entries(payload)) {
      data.append(val, name);
    } 
    axios.post(`${env}${path}`, data, {
      headers: {
        "Accept": 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${getToken}`,
      },
    }).then((response) => {
      let data = response.data;
      if(data.status.code === 200){
        return resolve(data);
      }else{
        var _err = { message: data.status.message }
        return reject(_err);
      }
    }).catch((err) => {
      var _err = err.response.data.status
      return reject(_err);
    })
  })   
}

export const POST_CHECK_TOKEN = (path, payload) => {
  const header = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With':' XMLHttpRequest'
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header,
      params: payload
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data);
    })
  })
}