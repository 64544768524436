export const courseCategory = [
    {
      "value": "MAJOR_COURSE",
      "name": "Mata Kuliah Kejuruan"
    },
    {
      "value": "GENERAL_COURSE",
      "name": "Mata Kuliah Umum"
    },
    
  ]