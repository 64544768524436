import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Card, Modal, Button, Row, Form, Col, Input, Space, message, Typography} from 'antd';
import { EditFilled } from '@ant-design/icons';
import { updateAbout } from '../../../../redux/actions/profile/profileAction';

const { Text } = Typography;
const { TextArea } = Input;

export class AboutMe extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      submitLoading: false,
    }
  }
 
  showModal = () => {
    this.setState({
      visible: true
    })
  };
  
  handleCancel = () => {
    this.setState({
      visible: false
    })
  };

  onFinish = async (values) => {
    const { actionUpdateAbout, actionGetProfile } = this.props

    return this.setState({ submitLoading: true }, () => {
      return actionUpdateAbout(values, response => {
        if(response.code === 1000){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
      })
    })
  }
  
  render() {
    const { visible, submitLoading } = this.state
    const { data: { about } } = this.props
    const isFilled = about
    return (
      <React.Fragment>
        <Card
          className="border-radius-6 box-shadow" 
          title="Tentang Saya" 
          bordered={false}
          extra={<Button type="link" icon={<EditFilled />} onClick={this.showModal}>Edit</Button>}
          headStyle={{ color: '#32AB6D', fontSize: 17 }}
         bodyStyle={isFilled ? {} : { textAlign: 'center', padding: 60 }}
        >
        {
          isFilled ?
          <React.Fragment>
            <Row>
              <Col Span={24}>
                <Text>{about ? about : '-'}</Text>
              </Col>
            </Row>
          </React.Fragment>
          : 
          <Text>Perkuat profil Anda dengan menambahkan kepribadian, Perjalanan karier, dan tindakan inspiratif Anda</Text>
        }
        </Card>

        <Modal
          title="Tentang Saya"
          visible={visible} 
          onCancel={this.handleCancel} 
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={600}
          maskClosable={false}
          destroyOnClose
        >
        <Form
          ref={this.formRef}
          layout="vertical"
          onFinish={this.onFinish}
          initialValues={{
            about: about
          }}
        >
            <Form.Item
              name="about"
              validateFirst
              rules={[
                {max: 1000, message: 'Maksimal 1000 karakter'}
              ]}
            >
              <TextArea rows={3}/>
            </Form.Item>
          <Form.Item>
            <Space className="float-right">
              <Button onClick={this.handleCancel}>Kembali</Button>
              <Button htmlType="submit" type="primary" loading={submitLoading} >Simpan</Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionUpdateAbout: updateAbout
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutMe)