import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, Modal, Form, Input, Space, Button, Row, Col, message } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { updateHeaderName } from '../../../../../redux/actions/profile/profileAction';
import { indexHeader } from '../../../../../redux/actions/header/headerAction';

const { Title, Text } = Typography;

export class Name extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
       submitLoading: false,
       visible: false
    }
  }
  
  showModal = () => {
    this.setState({
      visible: true,
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      isActivation: false,
      newEmail: null
    })
  };

  onFinish = async (values) => {
    const { actionUpdateHeaderName, actionGetProfile, actionGetHeader } = this.props
    
    return this.setState({ submitLoading: true }, () => {
      return actionUpdateHeaderName(values, response => {
        if(response.code === 1000){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return (actionGetProfile(), actionGetHeader())
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { submitLoading, visible } = this.state
    const { data } = this.props

    return (
      <React.Fragment>
        <Space>
          <Title className="info-color" level={3}>{data.fullName}</Title>
          <EditFilled className="fs-16 dark-color cursor-pointer" onClick={this.showModal} />
        </Space>

        <Modal 
          title="Nama"
          visible={visible} 
          onCancel={this.handleCancel}
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          width={600}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              firstName: data.firstName,
              lastName: data.lastName
            }}
          >
            <Row>
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">Nama Depan</Text>
                </Space>
                <Form.Item 
                  name="firstName"
                  validateFirst
                  rules={[
                    { required: true, message: 'Kolom ini wajib diisi!' },
                    { pattern: /^[a-zA-Z ]*$/, message: 'Tidak dapat menggunakan karakter khusus dan angka!' },
                    { min: 3, message: 'Kolom ini harus memiliki paling sedikit 3 karakter' },
                    { max: 25, message: 'Kolom ini tidak boleh lebih dari 25 karakter' }
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">Nama Belakang</Text>
                </Space>
                <Form.Item 
                  name="lastName"
                  validateFirst
                  rules={[
                    { required: true, message: 'Kolom ini wajib diisi!' },
                    { pattern: /^[a-zA-Z ]*$/, message: 'Tidak dapat menggunakan karakter khusus dan angka!' },
                    { min: 3, message: 'Kolom ini harus memiliki paling sedikit 3 karakter' },
                    { max: 25, message: 'Kolom ini tidak boleh lebih dari 25 karakter' }
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space className="float-right">
                <Button onClick={this.handleCancel}>Kembali</Button>
                <Button htmlType="submit" type="primary" loading={submitLoading}>Simpan</Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
  actionUpdateHeaderName: updateHeaderName,
  actionGetHeader: indexHeader
}

export default connect(mapStateToProps, mapDispatchToProps)(Name)