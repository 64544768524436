import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import moment from "moment";
import debounce from 'lodash/debounce';
import { setRegister } from '../../redux/actions/register/registerAction';
import { checkEmail, checkMobile } from '../../redux/actions/check/checkAction';
import { listInstitution } from '../../redux/actions/master/institution/institutionAction';
import { listFieldOfStudy } from '../../redux/actions/master/fieldOfStudy/fieldOfStudyAction';
import { Form, Input, Button, Row, Col, Card, Typography, Select, DatePicker, Space, Tooltip, Checkbox, Spin, message } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import './Register.css';

const { Option } = Select;
const { Text } = Typography;

export class Register extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      nextRegister: false,
      submitLoading: false,
      idCallingCode: 99,
      callingCode: 62,
      agree: false,
      visible: false,
      uniqueEmail: false,
      uniquePhoneNumber: false,
      valuesFirst: null,
      metaInstitution: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: ''
      },
    }
    this.onSearchInstitution = debounce(this.onSearchInstitution.bind(this), 800)
    this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
  }
  
  componentDidMount() {
  }

  onSearchInstitution = value => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;

    metaInstitution.page = 1
    metaInstitution.perpage = 10
    metaInstitution.search = value

    return actionListInstitution(metaInstitution)
  }

  onFocusInstitution = () => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;
    return actionListInstitution(metaInstitution)
  }

  onSearchFieldOfStudy = value => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;

    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;
    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onFinish = (values) => {
    const { nextRegister, valuesFirst } = this.state
    const { actionSetRegister } = this.props

    if(!nextRegister){
      values.birthdate = moment(values.birthdate).format('DD/MM/YYYY')
      this.setState({ nextRegister: true, valuesFirst: values })
    }else{
      delete values.agreement
      delete values.confirmPassword

      const allValues = {
        ...valuesFirst, ...values
      }

      return this.setState({ submitLoading: true }, () => {
        return actionSetRegister(allValues, response => {
          if(response.code === 1000){
            return this.setState({ submitLoading: false }, () => {
              localStorage.setItem("email", values.email)
              localStorage.setItem("password", values.password)
              this.props.history.push('/daftar/konfirmasi-email')
            })
          }else{
            message.error(response.message)
          }
        }, (err) => {
          return this.setState({ submitLoading: false }, () => message.error(err.message))
        })
      })
    }
  }

  onChangeCheck = (e) => {
    this.setState({
      agree: e.target.checked
    })
  }

  onChangeCode = (value, option) => {
    this.setState({
      callingCode: Number(option.text),
      idCallingCode: Number(option.key)
    })
    this.formRef.current.setFieldsValue({
      mobile: null
    })
  }

  onCheckEmail = (e) => {
    const { actionCheckEmail } = this.props
    return actionCheckEmail(e, response => {
      if(response){
        this.formRef.current.setFieldsValue({
          email: null
        })
        this.setState({ uniqueEmail: false })
        message.error('Email sudah ada')
      }else{
        this.setState({ uniqueEmail: true })
      }
    })
  }

  onCheckMobile = (e) => {
    const { actionCheckMobile } = this.props

    this.formRef.current.setFieldsValue({
      mobile: e.replace(/^0+/, '')
    })

    e = `${e.replace(/^0+/, '')}`
    
    return actionCheckMobile(e, response => {
      if(response){
        this.formRef.current.setFieldsValue({
          mobile: null
        })
        this.setState({ uniquePhoneNumber: false })
        message.error('Nomor Telepon sudah ada')
      }else{
        this.setState({ uniquePhoneNumber: true })
      }
    })
  }
  
  onFocusPassword = (value) => {
    this.setState({
      visible: value
    })
  }

  render() {
    const { getInstitution, getFieldOfStudy } = this.props
    const { nextRegister, submitLoading, visible } = this.state

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'MBKM - Daftar');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Daftar</title>
        </Helmet>
        <Row className="display-flex" align="middle" justify="center">
          <Col>
            <Card className="card-register" bodyStyle={{ padding: 0, display: 'flex' }}>
              <Form 
                ref={this.formRef}
                initialValues={{ 
                  code: '+62'
                }}
                onFinish={this.onFinish}
                >
                  {
                    nextRegister ?
                      <Row gutter={8}>
                        <Col className="fs-28" xs={24} sm={24} md={24}>
                          <Text className="primary-color">Daftar</Text>
                          <Space className="float-right">
                            <Text type="secondary" className="fs-12"><Text className="primary-color">2</Text> / 2</Text>
                          </Space>
                        </Col>

                        <Col className="fs-13 mb-16" xs={24} sm={24} md={24}>
                          <Text type="secondary">Ayo lengkapi sedikit lagi untuk bisa mendaftar ke berbagai program menariknya</Text>
                        </Col>

                        <Col span={24}>
                          <Form.Item 
                            className="mb-16"
                            name="email"
                            validateFirst
                            rules={[
                              { required: true, message: 'Kolom ini wajib diisi!' },
                              { type: 'email', message: 'Email tidak valid!' }
                            ]}
                          >
                            <Input type="email" onBlur={(e) => this.onCheckEmail(e.target.value)} placeholder="Alamat email yang valid" />
                          </Form.Item>
                        </Col>
                        
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item 
                            className="mb-16"
                            name="password"
                            validateFirst
                            hasFeedback
                            rules={[
                              { required: true, message: 'Kolom ini wajib diisi!' },
                              { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, message: 'Kata sandi tidak memenuhi syarat' },
                              { min: 8, message: 'Kolom ini harus memiliki paling sedikit 8 karakter' },
                            ]}
                          >
                            <Input.Password onFocus={() => this.onFocusPassword(true)} onBlur={() => this.onFocusPassword(false)} placeholder="Kata Sandi" />
                          </Form.Item>
                        </Col>
                        
                        <Col xs={22} sm={22} md={11}>
                          <Form.Item 
                            className="mb-16"
                            name="confirmPassword" 
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                              { required: true, message: 'Kolom ini wajib diisi!' },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('Kata sandi tidak cocok!'));
                                },
                              })
                            ]}
                          >
                            <Input.Password placeholder="Konfirmasi Kata Sandi" />
                          </Form.Item>
                        </Col>

                        <Col className="pt-4 dark-color pl-0" xs={2} sm={2} md={1}>
                          <Tooltip open={visible} onMouseLeave={() => this.onFocusPassword(false)} onMouseEnter={() => this.onFocusPassword(true)} placement="right" title="Kata Sandi minimal 8 karakter, 1 huruf kapital dan 1 angka">
                            <InfoCircleTwoTone twoToneColor="#0076de" />
                          </Tooltip>
                        </Col>
                        
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item 
                            className="mb-16"
                            name="mobile"
                            validateFirst
                            rules={[
                              { required: true, message: 'Kolom ini wajib diisi!' },
                              { pattern: /^[0-9]*$/, message: 'Kolom ini hanya boleh berisi angka!' },
                              { min: 7, max: 13, message: 'Nomor Telepon Anda tidak valid!' }
                            ]}
                          >
                            <Input onBlur={(e) => this.onCheckMobile(e.target.value)} addonBefore="+62" placeholder="Masukan Nomor Telepon Kamu" />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24}>
                          <Form.Item 
                            className="mb-16"
                            name="agreement"
                            valuePropName="checked"
                            rules={[
                              {
                                validator: (_, value) =>
                                  value ? Promise.resolve() : Promise.reject(new Error('Harap centang kotak ini untuk melanjutkan akun anda')),
                              }
                            ]}
                          >
                            <Checkbox className="fs-11 dark-color">
                              Dengan membuat akun Anda setuju dengan
                              <Link className="info-color" to="/terms-and-conditions" target="_blank"> persyaratan penggunaan </Link>
                              dan
                              <Link className="info-color" to="/privacy-policy" target="_blank"> kebijakan privasi</Link>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item className="mb-8">
                            <Button type="primary" htmlType="submit" className="login-form-button" loading={submitLoading} block>
                              Daftar Akun
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    :
                      <Row gutter={8}>
                        <Col className="fs-28" xs={24} sm={24} md={24}>
                          <Text className="primary-color">Daftar</Text>
                          <Space className="float-right">
                            <Text type="secondary" className="fs-12"><Text className="primary-color">1</Text> / 2</Text>
                          </Space>
                        </Col>

                        <Col className="fs-13 mb-16" xs={24} sm={24} md={24}>
                          <Text type="secondary">Lengkapi data diri agar memudahkan dalam pendaftaran dalam program MBKM</Text>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item 
                            className="mb-16"
                            name="firstName" 
                            validateFirst
                            rules={[
                              { required: true, message: 'Kolom ini wajib diisi!' },
                              { pattern: /^[a-zA-Z ]*$/, message: 'Tidak dapat menggunakan karakter khusus dan angka!' },
                              { min: 3, message: 'Kolom ini harus memiliki paling sedikit 3 karakter' },
                              { max: 25, message: 'Kolom ini tidak boleh lebih dari 25 karakter' }
                            ]}
                          >
                            <Input placeholder="Nama Depan" />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item 
                            className="mb-16"
                            name="lastName" 
                            validateFirst
                            rules={[
                              { required: true, message: 'Kolom ini wajib diisi!' },
                              { pattern: /^[a-zA-Z ]*$/, message: 'Tidak dapat menggunakan karakter khusus dan angka!' },
                              { min: 3, message: 'Kolom ini harus memiliki paling sedikit 3 karakter' },
                              { max: 25, message: 'Kolom ini tidak boleh lebih dari 25 karakter' }
                            ]}
                          >
                            <Input placeholder="Nama Belakang" />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item 
                            className="mb-16"
                            name="placeOfBirth" 
                            validateFirst
                            rules={[
                              { required: true, message: 'Kolom ini wajib diisi!' },
                              { pattern: /^[a-zA-Z ]*$/, message: 'Tidak dapat menggunakan karakter khusus dan angka!' },
                              { min: 3, message: 'Kolom ini harus memiliki paling sedikit 3 karakter' },
                              { max: 50, message: 'Kolom ini tidak boleh lebih dari 50 karakter' }
                            ]}
                          >
                            <Input placeholder="Kota Kelahiran" />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item 
                            className="mb-16"
                            name="birthdate" 
                            validateFirst
                            rules={[
                              { required: true, message: 'Kolom ini wajib diisi!' }
                            ]}
                          >
                            <DatePicker className="width-100" defaultPickerValue={moment('2000-01-01')} placeholder="Tanggal dan Tahun Lahir" />
                          </Form.Item>
                        </Col>
                        
                        <Col span={24}>
                          <Form.Item 
                            className="mb-16"
                            name="institutionId"
                            validateFirst
                            rules={[
                              { required: true, message: 'Kolom ini wajib diisi!' },
                              // { pattern: /^[0-9a-zA-Z ]*$/, message: 'Special characters are not allowed' },
                              // { min: 5, message: 'Kolom ini harus memiliki paling sedikit 5 karakter' },
                              // { max: 100, message: 'Kolom ini tidak boleh lebih dari 100 karakter' },
                            ]}
                          >
                            {/* <Input placeholder="Asal Perguruan Tinggi" /> */}
                            <Select
                              className="width-100"
                              onFocus={this.onFocusInstitution}
                              onSearch={this.onSearchInstitution}
                              notFoundContent={getInstitution.loading ? <Spin size="small" /> : null}
                              filterOption={false}
                              placeholder="Asal Perguruan Tinggi"
                              showSearch
                            >
                              {
                                getInstitution?.data?.map((res) => (
                                  <Option key={res.id} value={res.id}>
                                    {res.name}
                                  </Option>
                                ))
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                        
                        <Col span={24}>
                          <Form.Item 
                            className="mb-16"
                            name="fieldOfStudyId"
                            validateFirst
                            rules={[
                              { required: true, message: 'Kolom ini wajib diisi!' },
                              // { pattern: /^[0-9a-zA-Z ]*$/, message: 'Special characters are not allowed' },
                              // { min: 5, message: 'Kolom ini harus memiliki paling sedikit 5 karakter' },
                              // { max: 100, message: 'Kolom ini tidak boleh lebih dari 100 karakter' },
                            ]}
                          >
                            <Select
                              className="width-100"
                              onFocus={this.onFocusFieldOfStudy}
                              onSearch={this.onSearchFieldOfStudy}
                              notFoundContent={getFieldOfStudy.loading ? <Spin size="small" /> : null}
                              filterOption={false}
                              placeholder="Program Studi"
                              showSearch
                            >
                              {
                                getFieldOfStudy?.data?.map((res) => (
                                  <Option key={res.id} value={res.id}>
                                    {res.name}
                                  </Option>
                                ))
                              }
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item 
                            className="mb-16"
                            name="nim"
                            validateFirst
                            rules={[
                              { required: true, message: 'Kolom ini wajib diisi!' },
                              { pattern: /^[0-9]*$/, message: 'Kolom ini hanya boleh berisi angka!' },
                              { min: 3, message: 'Kolom ini harus memiliki paling sedikit 3 karakter' },
                              { max: 50, message: 'Kolom ini tidak boleh lebih dari 50 karakter' },
                            ]}
                          >
                            <Input placeholder="Nomor Induk Mahasiswa (NIM)" />
                          </Form.Item>
                        </Col>
                        
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item className="mb-8">
                            <Button type="primary" htmlType="submit" className="login-form-button" loading={submitLoading} block>
                              Selanjutnya
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                  }
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getInstitution: state.institutionReducer,
  getFieldOfStudy: state.fieldOfStudyReducer
})

const mapDispatchToProps = {
  actionListInstitution: listInstitution,
  actionListFieldOfStudy: listFieldOfStudy,
  actionCheckEmail: checkEmail,
  actionCheckMobile: checkMobile,
  actionSetRegister: setRegister,
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)