import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, Row, Col, Space, Tooltip, Button, Modal, Form, Input, Statistic, message } from 'antd';
// import { CheckCircleFilled, EditFilled } from '@ant-design/icons';
import { CheckCircleFilled } from '@ant-design/icons';
// import { updateHeader, updateEmailActivation, updateEmailResend } from '../../../../../redux/actions/profile/profileAction';
import { checkEmail } from '../../../../../redux/actions/check/checkAction';

const { Countdown } = Statistic;
const { Text } = Typography;

export class Email extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      visible: false,
      isActivation: false,
      newEmail: null,
      isCountdown: true,
      countdown: null
    }
  }
  
  showModal = () => {
    this.setState({
      visible: true,
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      isActivation: false,
      newEmail: null
    })
  };

  onClickResend = () => {
    const value = {
      email: this.state.newEmail
    }

    const { actionUpdateEmailResend } = this.props

    return this.setState({}, () => {
      return actionUpdateEmailResend(value, response => {
        if(response.code === 1000){
          message.success(response.message)
          this.setState({
            isCountdown: true,
            countdown: Date.now() + 60 * 2000
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({}, () => message.error(err.message))
      })
    })
  }

  onFinishCountdown = () => {
    this.setState({
      isCountdown: false
    })
  }

  onFinish = async (values) => {
    const { isActivation, newEmail } = this.state
    const { actionUpdateHeader, actionCheckEmail, actionUpdateEmailActivation, actionGetProfile } = this.props
    
    if(isActivation){
      values.email = isActivation ? newEmail : values.newEmail
      return this.setState({ submitLoading: true }, () => {
        return actionUpdateEmailActivation(values, response => {
          if(response.code === 1000){
            return this.setState({ 
              submitLoading: false,
              visible: false
            }, () => {
              message.success(response.message)
              return actionGetProfile()
            })
          }else{
            this.setState({ submitLoading: false })
            message.error(response.message)
          }
        }, (err) => {
          return this.setState({ submitLoading: false, visible: false }, () => message.error(err.message))
        })
      })
    }else{
      this.setState({ submitLoading: true }, () => {
        return actionCheckEmail(values.newEmail, response => {
          if(response){
            this.formRef.current.setFieldsValue({
              newEmail: null
            })
            this.setState({
              newEmail: null,
              submitLoading: false
            })
            message.error('Email sudah ada')
          }else{
            return actionUpdateHeader(values, response => {
              if(response.code === 1000){
                return this.setState({ 
                  submitLoading: false, 
                  isActivation: true,
                  newEmail: values.newEmail,
                  countdown: Date.now() + 60 * 2000
                }, () => {
                  message.success(response.message)
                })
              }else{
                message.error(response.message)
              }
            }, (err) => {
              return this.setState({ submitLoading: false }, () => message.error(err.message))
            })
          }
        })
      })
    }
  }

  render() {
    const { submitLoading, visible, isActivation, newEmail, isCountdown, countdown } = this.state
    const { data } = this.props
    return (
      <React.Fragment>
        <Space>
          <Text className="fs-12 dark-color">{data.email}</Text>
          <Tooltip title="Email Verified">
            <CheckCircleFilled className="primary-color" />
          </Tooltip>
          {/* <EditFilled className="fs-12 dark-color cursor-pointer" onClick={this.showModal} /> */}
        </Space>

        <Modal 
          title="Email" 
          visible={visible} 
          onCancel={this.handleCancel}
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          width={600}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
            initialValues={{
              newEmail: null
            }}
          >
            <Row className="mb-8">
              <Col span={24}>
                <Space size={0} direction="vertical">
                  <Text className="fs-12" type="secondary">Alamat email saat ini</Text>
                  <Text className="fs-13 dark-color" type="secondary">{data.email}</Text>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {
                  isActivation ?
                    <Space size={0} direction="vertical">
                      <Text className="fs-12" type="secondary">Alamat email baru</Text>
                      <Text className="fs-13 dark-color" type="secondary">{newEmail}</Text>
                    </Space>
                  :
                    <React.Fragment>
                      <Space size={4}>
                        <Text className="fs-12" type="danger">*</Text>
                        <Text className="fs-12" type="secondary">Alamat email baru</Text>
                      </Space>
                      <Form.Item 
                        name="newEmail"
                        validateFirst
                        rules={[
                          { required: true, message: 'Kolom ini wajib diisi!' },
                          { type: 'email', message: 'Email tidak valid!' },
                          // Hanya untuk di production jika di staging akan di matikan
                          // { pattern:  /.+@(gmail|yahoo)\.com$/, message: 'Preferred using Gmail or Yahoo' }
                        ]}
                      >
                        <Input type="email" size="small" />
                      </Form.Item>
                    </React.Fragment>
                }
              </Col>
            </Row>
            {
              isActivation ?
                <React.Fragment>
                  <Row className="mb-min-8">
                    <Col span={24}>
                      <React.Fragment>
                        <Space size={4}>
                          <Text className="fs-12" type="danger">*</Text>
                          <Text className="fs-12" type="secondary">Masukkan kode yang kami kirim untuk memverifikasi email anda</Text>
                        </Space>
                        <Form.Item 
                          name="activationCode"
                          rules={[
                            { required: true, message: 'Kolom ini wajib diisi!' }
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </React.Fragment>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Space size={4}>
                        <Text className="fs-12 dark-color">Tidak mendapatkan kode?</Text>
                        {
                          isCountdown ?
                            <Countdown value={countdown} onFinish={this.onFinishCountdown} format="mm:ss" valueStyle={{ fontSize: 11 }} />
                          :
                            <Text className="fs-12 info-color cursor-pointer" onClick={this.onClickResend}>Kirim kode lagi</Text>
                        }
                      </Space>
                    </Col>
                  </Row>
                </React.Fragment>
              :
                null
            }
            <Form.Item>
              <Space className="float-right">
                {
                  isActivation ?
                    <Button htmlType="submit" type="primary" loading={submitLoading}>Verifikasi</Button>
                  :
                    <React.Fragment>
                      <Button onClick={this.handleCancel}>Kembali</Button>
                      <Button htmlType="submit" type="primary" loading={submitLoading}>Simpan</Button>
                    </React.Fragment>
                }
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionCheckEmail: checkEmail,
  // actionUpdateHeader: updateHeader,
  // actionUpdateEmail: updateEmail,
  // actionUpdateEmailActivation : updateEmailActivation,
  // actionUpdateEmailResend : updateEmailResend,
}

export default connect(mapStateToProps, mapDispatchToProps)(Email)
