import React, { Component } from 'react';
import Cookie from 'js-cookie';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb, Button, Card, Space, Typography } from 'antd';
import { RightCircleFilled } from '@ant-design/icons';

const { Text } = Typography;

export default class PertukaranMahasiswaMerdeka extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'MBKM - Tentang Program - Pertukaran Mahasiswa Merdeka');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Tentang Program - Pertukaran Mahasiswa Merdeka</title>
        </Helmet>
        <Row>
          <Col span={24}>
            <Row className="pt-16 pb-64" gutter={[0,16]} style={{ width: '100%', backgroundColor: '#117137' }}>
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/" style={{ color: '#D9D9D9' }}>Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item style={{ color: '#D9D9D9' }}>Tentang Program</Breadcrumb.Item>
                  <Breadcrumb.Item style={{ color: '#FFFFFF' }}>Pertukaran Mahasiswa Merdeka</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col className='mt-32' xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Card style={{ backgroundColor: 'transparent', borderStyle: 'none none none solid', borderLeft: '8px solid #FFFFFF' }} bodyStyle={{ padding: '40px 16px' }}>
                  <Space direction="vertical" size={0}>
                    <Text className="white-color fs-28">Program</Text>
                    <Text className="white-color fs-36 font-weight-bold">Pertukaran Mahasiswa Merdeka</Text>
                  </Space>
                </Card>
              </Col>
            </Row>
            <Row className="mt-32">
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Space direction="vertical" size={16}>
                  <Text className="dark-color">Merdeka Belajar-Kampus Merdeka memberikan peluang kepada mahasiswa untuk mencari pengalaman belajar di perguruan tinggi yang lain baik yang sesuai	dengan program studi maupun lintas program studi. Pengalaman belajar tersebut dapat dilakukan dengan menempuh pembelajaran di kampus lain dengan sistem pengakuan kredit (credit earning).</Text>
                  <Text className="dark-color">Pengalaman pembelajaran lintas kampus diselenggarakan untuk membentuk beberapa sikap mahasiswa yang termaktub di dalam Peraturan Menteri Pendidikan dan Kebudayaan (Permendikbud) Nomor 3 Tahun 2020, yaitu menghargai keanekaragaman budaya, pandangan, agama, dan kepercayaan, serta pendapat atau temuan orisinal orang lain serta bekerja sama dan memiliki kepekaan sosial serta kepedulian terhadap masyarakat dan lingkungan.</Text>
                  <Text className="dark-color">Kegiatan ini diharapkan akan memberikan tambahan skill pengetahuan, sosial dan budaya bagi mahasiswa sehingga dapat mendorong pemahaman lintas sosial budaya, membangun iklim diskusi dengan mahasiswa di perguruan tinggi lain, dan mendorong kerja sama antar mahasiswa baik selama masih menjadi mahasiswa maupun kelak ketika telah selesai menempuh perkuliahan. Peluang untuk mendapatkan pengalaman di luar kampus terutama dalam mendapatkan pengetahuan di perguruan tinggi yang lain, perlu ditangkap untuk memberikan wadah bagi mahasiswa untuk saling bertukar kelas melalui program pertukaran mahasiswa credit earning. Oleh karena itu implementasi program pertukaran mahasiswa sebagai salah satu bagian kegiatan pembelajaran perlu dilakukan untuk memberikan peluang bagi mahasiswa merasakan iklim akademik, perkuliahan yang berbeda yang dapat menambah pengalaman pengetahuan dan sosial. Model implementasi program pertukaran mahasiswa dilaksanakan mutualisme, artinya tidak hanya Universitas Binawan yang mengirim mahasiswa, tetapi juga Universitas Binawan juga siap menerima mahasiswa dari perguruan tinggi yang lain.</Text>
                  <Text className="dark-color font-weight-bold">Tujuan program kegiatan pertukaran mahasiswa antara lain :</Text>
                  <ul>
                    <li>Meningkatkan wawasan kebangsaan, integritas, dan kapabilitas mahasiswa sebagai warga masyarakat yang mencintai tanah air negara kesatuan bangsa Indonesia,</li>
                    <li>Mengembangkan karakter mahasiswa yang	memiliki soft skill, kemampuan berkolaborasi, dan adaptif dalam pergaulan di masyarakat Indonesia yang multikultur,</li>
                    <li>Memperkaya pengalaman belajar siswa di perguruan tinggi lain yang memiliki atmosfer akademik berbeda melalui transfer kredit dan perolehan kredit, dan</li>
                    <li>Meningkatkan kapabilitas mahasiswa melalui perkuliahan yang lebih mendalam atau mungkin tidak tersedia di program studinya</li>
                  </ul>
                </Space>
              </Col>
            </Row>
            <Row className="mt-32 text-align-center">
              <Col xs={{ span: 24}} lg={{ span: 10 , offset: 7 }} xxl={{ span: 6, offset: 9 }}>
                <Link className="dark-color" to={Cookie.get("user") ? "/kandidat/program" : "/daftar"}>
                  <Button type="primary" className="border-radius-8 text-align-left pt-16 pb-16 card-next-pertukaran">
                    <Row justify="space-around" align="middle">
                      <Col>
                        <Space direction='vertical' size={0}>
                          <Text className="white-color fs-16 text-card-next-top">INGIN MENAMBAH WAWASAN?</Text>
                          <Text className="white-color fs-18 text-card-next-bottom">AYO DAFTARKAN DIRI KAMU</Text>
                        </Space>
                      </Col>
                      <Col className="text-align-right">
                        <RightCircleFilled className="text-card-next-icon" />
                      </Col>
                    </Row>
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
