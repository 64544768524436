import React from 'react';
import Cookie from 'js-cookie';
import { Row, Col, Typography, Card, Button, Space, Divider, BackTop, Modal, Form, Tabs, Image, Tooltip } from 'antd';
import FormPendaftar from './formPendaftaran'
import { CheckOutlined, DownloadOutlined } from '@ant-design/icons';
import { programCategory } from '../../../../utils/constant/programCategory';
import EmptyLogo from '../../../../assets/img/EmptyLogo.png';
import IconVerified from '../../../../assets/img/home/ic-verified.png';

const { Text, Paragraph } = Typography;
const { TabPane } = Tabs;

export default function DetailProgramDesktop(props) {
  const {
    submitLoading,
    getProgramDetail: { data },
    // getProfile,
    // modalValidateApply,
    showModal,
    handleCancel,
    visible,
    onFinish,
    formRef,
  } = props
  
  const onClickApply = (val) => {
    // localStorage.setItem('link', val);
    // localStorage.setItem('visible', true);
    props.history.push('/masuk')
  }
  return (
    <React.Fragment>
      <BackTop />
        <Row gutter={32}>
          <Col span={24}>
            <Card 
              className="mb-16 border-radius-6 box-shadow"
              bordered={false} 
            >
              <Row className="width-100" gutter={32}>
                <Col span={17}>
                  <Space size={16}>
                    <Image
                      src={data.partnerLogoUrl ? data.partnerLogoUrl : EmptyLogo}
                      preview={false}
                      width={100}
                      height={100}
                      style={{ objectFit: 'contain' }}
                    />
                    <Space direction="vertical" size={0}>
                      <Text className="fs-18 dark-color" strong>{data.title}</Text>
                      <Text className="fs-15 dark-color">{data.partnerName}</Text>
                      <Text className="fs-15 dark-color">{data.cityName} {data.countryName ? `, ${data.countryName}` : null}</Text>
                      <Text className="fs-15 dark-color">{programCategory.find(res => res.value === data.programCategory)?.name}</Text>
                      <Space size={4}>
                        <Text className="info-color fs-15">{data.creditHour} SKS</Text>
                        <Text className="dark-color fs-15">• {data.duration} bulan</Text>
                      </Space>
                      {
                        data.hasCertificate ?
                          <Space size={4} align='middle'>
                            <Image
                              width={18}
                              height={18}
                              src={IconVerified}
                              preview={false}
                            />
                            <Text className="fs-15 font-weight-500" style={{ color: '#32AB6D' }}>
                              Bersertifikat
                            </Text>
                          </Space>
                        :
                          null
                      }
                    </Space>
                  </Space>
                </Col>
                <Col span={7}>
                  <Space direction="vertical" size={4} className="float-right">
                    <Text className="float-right fs-12 dark-color">{`Diposting pada ${data.openDate}`}</Text>
                    <Text className="float-right fs-12 primary-color">{`Daftar sebelum ${data.closeDate}`}</Text>
                  </Space>
                </Col>
              </Row>

              <Divider />

              <Row className="mb-16">
                <Col span={12}>
                  <Space direction="vertical">
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12 dark-color">Tanggal Pendaftaran</Text>
                      <Text className="dark-color" strong>{data.openDate} - {data.closeDate}</Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12 dark-color">Jumlah Maksimal Peserta</Text>
                      <Text className="dark-color" strong>{data.positionNumber}</Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical">
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12 dark-color">Tanggal Kegiatan</Text>
                      <Text className="dark-color" strong>{data.startDate} - {data.endDate}</Text>
                    </Space>
                    <Space direction="vertical" size={0}>
                      <Text className="fs-12 dark-color">Jenis Kepesertaan</Text>
                      <Text className="dark-color" strong>{data.isGroup ? 'Kelompok' : 'Individu'}</Text>
                    </Space>
                  </Space>
                </Col>
              </Row>

              <Divider />

              <Row>
                <Col span={24}>
                  <Space className="float-right" size={8}>
                    {
                      data.isApply ?
                        <Text className="float-right fs-14 primary-color mr-32"><CheckOutlined className="fs-16" /> Terdaftar</Text>
                      :
                        <Button 
                          type="primary" 
                          className="float-right border-radius-6" 
                          onClick={
                            Cookie.get('user') ? 
                              // getProfile?.data?.semester || getProfile?.data?.sksTaken || getProfile?.data?.gpa  ?
                                showModal 
                              // :
                              //   modalValidateApply
                            : 
                              () => onClickApply(window.location.pathname)} 
                          style={{ width: 100 }}
                        >
                          Daftar
                        </Button>
                    }
                  </Space>
                  <Modal 
                    width={1000} 
                    title={`Daftar Program MBKM ${programCategory.find(res => res.value === data.programCategory)?.name} di ${data.partnerName}`} 
                    visible={visible} 
                    onCancel={handleCancel}
                    footer={false}
                    maskClosable={false}
                    destroyOnClose
                  >
                    <Form 
                      layout="vertical"
                      ref={formRef}
                      onFinish={onFinish}
                    >
                      <FormPendaftar {...props} />

                      <Divider />

                      <Row>
                        <Col span={24}>
                          <Space className="float-right">
                            <Button key="next" type="primary" htmlType="submit" loading={submitLoading}>
                              Daftar
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Form>
                  </Modal>
                </Col>
              </Row>
            </Card>
            
            {
              data.programDescription || data.listPositions || data.listFieldOfStudy || data.programProcedure || data.programConversion || data.listAttachment ?
                <Card 
                  className="mb-16 border-radius-6 box-shadow"
                  bordered={false}
                >
                  <Tabs defaultActiveKey="1" type='card'>
                    {
                      data.programDescription ?
                        <TabPane tab="Deskripsi Program" key="1">
                          <Row className="mb-16">
                            <Col span={24}>
                              {
                                data.programDescription ?
                                  <div style={{ color: '#5f6163' }} dangerouslySetInnerHTML={{ __html: data.programDescription }}></div>
                                :
                                  null
                              }
                            </Col>
                          </Row>
                        </TabPane>
                      : null
                    }
                    {
                      data.listPositions ?
                        <TabPane tab="Posisi Pilihan" key="2">
                          <Row className="mb-16">
                            <Col span={24}>
                              <ul>
                                {
                                  data.listPositions.map((item, i) => 
                                    <li key={i}>{item.positionName}</li>
                                  )
                                }
                              </ul>
                            </Col>
                          </Row>
                        </TabPane>
                      : null
                    }
                    {
                      data.listFieldOfStudy ?
                        <TabPane tab="Jurusan Pilihan" key="3">
                          <Row className="mb-16">
                            <Col span={24}>
                              <ul>
                                {
                                  data.listFieldOfStudy.map((item, i) => 
                                    <li key={i}>{item.fieldOfStudyName}</li>
                                  )
                                }
                              </ul>
                            </Col>
                          </Row>
                        </TabPane>
                      : null
                    }
                    {
                      data.programRequirement ?
                        <TabPane tab="Kriteria Persyaratan" key="4">
                          <Row className="mb-16">
                            <Col span={24}>
                              {
                                data.programRequirement ?
                                  <div style={{ color: '#5f6163' }} dangerouslySetInnerHTML={{ __html: data.programRequirement }}></div>
                                :
                                  null
                              }
                            </Col>
                          </Row>
                        </TabPane>
                      : null
                    }
                    {
                      data.programProcedure ?
                        <TabPane tab="Prosedur Pelaksanaan" key="5">
                          <Row className="mb-16">
                            <Col span={24}>
                              {
                                data.programProcedure ?
                                  <div style={{ color: '#5f6163' }} dangerouslySetInnerHTML={{ __html: data.programProcedure }}></div>
                                :
                                  null
                              }
                            </Col>
                          </Row>
                        </TabPane>
                      : null
                    }
                    {
                      data.programConversion ?
                        <TabPane tab="Mata Kuliah Konversi" key="6">
                          <Row className="mb-16">
                            <Col span={24}>
                              {
                                data.programConversion ?
                                  <div style={{ color: '#5f6163' }} dangerouslySetInnerHTML={{ __html: data.programConversion }}></div>
                                :
                                  null
                              }
                            </Col>
                          </Row>
                        </TabPane>
                      : null
                    }
                    {
                      data.listAttachment ?
                        <TabPane tab="Lampiran" key="7">
                          {
                            data?.listAttachment?.map((item,i) => 
                              <Row key={i} className="mb-16">
                                <Col span={23}>
                                  <Text className="primary-color" strong>{item.name}</Text>
                                </Col>
                                <Col span={1} className="float-right">
                                  <Tooltip className="float-right" title="click to download file">
                                    <a className="float-right" href={item.fileUrl} target="_blank" rel="noreferrer"><DownloadOutlined /></a>
                                  </Tooltip>
                                </Col>
                                <Divider />
                              </Row>
                            )
                          }
                        </TabPane>
                      : null
                    }
                  </Tabs>
                </Card>
              : null
            }

            <Card 
              className="mb-16 border-radius-6 box-shadow"
              title="Halaman Mitra"
              bordered={false}
            >
              <Row gutter={[0,32]}>
                <Col className="width-100" lg={6} xxl={4}>
                  <Image
                    src={data.partnerLogoUrl ? data.partnerLogoUrl : EmptyLogo}
                    preview={false}
                    width={150}
                    height={150}
                    style={{ objectFit: 'contain' }}
                  />
                </Col>
                <Col lg={18} xxl={20}>
                  <Space className="width-100" direction="vertical">
                    <Text className="fs-18 dark-color" strong>{data.partnerName}</Text>
                    <Row className='mb-min-8'>
                      <Col xs={8} sm={6} xl={4}>
                        <Text className="dark-color">Jenis Mitra</Text>
                      </Col>
                      <Col xs={16} sm={18} xl={20}>
                        <Text className="dark-color" strong>{data.partnerIndustryName ? data.partnerIndustryName : '-'}</Text>
                      </Col>
                    </Row>
                    <Row className='mb-min-8'>
                      <Col xs={8} sm={6} xl={4}>
                        <Text className="dark-color">Kota</Text>
                      </Col>
                      <Col xs={16} sm={18} xl={20}>
                        <Text className="dark-color" strong>{data.partnerCityName ? data.partnerCityName : '-'}</Text>
                      </Col>
                    </Row>
                    <Row className='mb-min-8'>
                      <Col xs={8} sm={6} xl={4}>
                        <Text className="dark-color">Provinsi</Text>
                      </Col>
                      <Col xs={16} sm={18} xl={20}>
                        <Text className="dark-color" strong>{data.partnerProvinceName ? data.partnerProvinceName : '-'}</Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={8} sm={6} xl={4}>
                        <Text className="dark-color">Negara</Text>
                      </Col>
                      <Col xs={16} sm={18} xl={20}>
                        <Text className="dark-color" strong>{data.partnerCountryName ? data.partnerCountryName : '-'}</Text>
                      </Col>
                    </Row>
                  </Space>
                </Col>
                <Col className="width-100 mb-min-16" span={24}>
                  <Paragraph className="dark-color" ellipsis={{ rows: 3, expandable: true, symbol: 'show more' }}>{data.partnerAbout ? data.partnerAbout : null}</Paragraph>
                </Col>
                {
                  data?.listCompanyImage?.length > 0 ? 
                    <>
                      <Col className='mb-min-8' span={24}>
                        <Text className="dark-color fs-16" style={{ fontWeight: 500 }}>Foto Perusahaan</Text>
                      </Col>
                      {/* <Col span={24}>
                        <Carousel
                          arrows={data?.listCompanyImage?.length > 1}
                          swipeable={data?.listCompanyImage?.length > 1}
                          draggable={false}
                          // showDots={true}
                          responsive={responsive}
                          ssr={true} // means to render carousel on server-side.
                          infinite={true}
                          // autoPlay={true}
                          // autoPlaySpeed={2000}
                          // keyBoardControl={true}
                          // transitionDuration={5000}
                        >
                          {
                            data.listCompanyImage.map((item,i) =>
                              <Row key={i}>
                                <Col span={20} offset={2}>
                                  <Image
                                    src={item.fileUrl}
                                    width={'100%'}
                                    height={174}
                                    style={{ objectFit: 'cover' }}
                                  />
                                </Col>
                              </Row>
                            )
                          }
                        </Carousel>
                      </Col> */}
                    </>
                  : 
                    null
                }
                {/* <Col className='text-align-center' span={24}>
                  <Link className="fs-16" to={Cookie.get('user') ? `/candidate/company/${data.companyId}/${data.companyType}` : `/company/${data.companyId}/${data.companyType}`} style={{ fontWeight: 500 }}>Lihat Selengkapnya</Link>
                </Col> */}
              </Row>
            </Card>
          </Col>
          {/* <Col span={24}>
            {
              getJob?.loading ?
                <Skeleton.Input style={{ width: '100%' }} active />
              :
                <Text className="dark-color fs-13 mb-32" style={{ paddingLeft: xs ? 16 : 0 }}>{`Showing ${data?.listJobSimiliar?.meta?.total === 0 ? data?.listJobSimiliar?.meta?.total : (data?.listJobSimiliar?.meta?.page*data?.listJobSimiliar?.meta?.perpage)-(data?.listJobSimiliar?.meta?.perpage-1)}-${data?.listJobSimiliar?.meta?.page === data?.listJobSimiliar?.meta?.pages || data?.listJobSimiliar?.meta?.total === 0 ? data?.listJobSimiliar?.meta?.total : data?.listJobSimiliar?.meta?.page*data?.listJobSimiliar?.meta?.perpage} of ${data?.listJobSimiliar?.meta?.total} job openings`}</Text>
            }
            <Card 
              className="mb-16 border-radius-6 box-shadow"
              title="Similiar Jobs"
              bordered={false}
            >
              <List
                className="mt-16"
                size="large"
                pagination={{
                  style: { textAlign: 'center', paddingBottom: 24 },
                  pageSize: data?.listJobSimiliar?.meta?.perpage,
                  total: data?.listJobSimiliar?.meta?.total,
                  current: data?.listJobSimiliar?.meta?.page,
                  onChange: (pageNumber) => onChangePagination(pageNumber)
                }}
                dataSource={data.listJobSimiliar}
                renderItem={item => (
                  <List.Item key={item.id}>
                    <Row className="width-100" gutter={16}>
                      <Col className="col-img-job" xs={24} sm={4} md={4}>
                        <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                          <Image
                            src={item.companyLogoUrl ? item.companyLogoUrl : EmptyLogo}
                            preview={false}
                            width={'100%'}
                            height={100}
                            style={{ objectFit: 'contain' }}
                          />
                        </Link>
                      </Col>
                      <Col xs={24} sm={13} md={12}>
                        <Space direction="vertical" size={0}>
                          <Link to={Cookie.get('user') ? `/candidate/job/detail/${item.slug}` : `/job/detail/${item.slug}`}>
                            <Text className="title-job dark-color fs-17">{item.title}</Text>
                          </Link>
                          <Link to={Cookie.get('user') ? `/candidate/company/${item.companyId}/${item.companyType}` : `/company/${item.companyId}/${item.companyType}`}>
                            <Text className="company-job fs-13 dark-color">{item.companyName} {item.isVerified ? <SafetyOutlined className="primary-color" /> : null}</Text>
                          </Link>
                          <Text className="fs-13 dark-color"><EnvironmentOutlined /> {item.jobDestinationParentId ? `${item.jobDestinationName}, ${item.jobDestinationParentName}` : item.jobDestinationName}</Text>
                          <Text className="fs-13 primary-color">
                            {
                              item.isDisplaySalary ?
                                item.maxSalary ? 
                                  `${item.salary ? item.salary.toLocaleString() : null} - ${item.maxSalary ? item.maxSalary.toLocaleString() : null} ${item.currencyCode}` 
                                : 
                                  `${item.salary ? item.salary.toLocaleString() : null} ${item.currencyCode}`
                              :
                                'Salary not disclosed'
                            }
                          </Text>
                        </Space>
                      </Col>
                      <Col className='float-right' xs={24} sm={7} md={8}>
                        <Space className="width-100 float-right" direction="vertical" size={16}>
                          <Text className="fs-11 dark-color float-right">{`Posted on ${item.postDate}`}</Text>
                          {
                            Cookie.get('user') ?
                              <Space className='float-right' size={8}>
                                <Tooltip title={item.isFavorite ? 'Already added to favorites' : 'Add to favorites' }>
                                  <Button type="primary" className="border-radius-6" icon={item.isFavorite ? <StarFilled /> : <StarOutlined />} onClick={() => handleFavorite(item.id, item.isFavorite)} ghost />
                                </Tooltip>
                                {
                                  item.isApply ?
                                    <Text className="float-right fs-14 primary-color"><CheckOutlined /> Applied</Text>
                                  :
                                    <Link to={`/candidate/job/detail/${item.slug}`}>
                                      <Button type="primary" className="float-right border-radius-6">Apply</Button>
                                    </Link>
                                }         
                              </Space>
                            :
                              <Button type="primary" className="float-right border-radius-6" onClick={() => onClickApply(window.location.pathname)}>Apply</Button>
                          }
                        </Space>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            </Card>
          </Col> */}
        </Row>
    </React.Fragment>
  )
}