import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const unmountDetailBerita = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_BERITA'})
}

export const unmountListBerita = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_BERITA'})
}

export const listBerita = (params, successCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_BERITA' })
  
  return API.GET('/news/list', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_BERITA_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
    return successCB(params)
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_BERITA_FAILED' }), 
    ))
  })
}

export const detailBerita = (id) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DETAIL_BERITA' })
  const params = {
    'id': id
  }
  return API.GET('/news/getById', params).then((response) => {
    dispatch({
      type: 'LOAD_DETAIL_BERITA_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DETAIL_BERITA_FAILED' }), 
    ))
  })
}