import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const unmountListApplications = () => (dispatch) => {
	return dispatch({type: 'UNMOUNT_LIST_APPLICATIONS'})
}

export const unmountDetailApplications = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_APPLICATIONS'})
}

export const listApplications = (params) => async (dispatch) => {
	await dispatch({ type: 'LOAD_LIST_APPLICATIONS' })
	return API.GET('/participant/list', params).then((response) => {
		dispatch({
			type: 'LOAD_LIST_APPLICATIONS_SUCCESS', 
			payload: {
				data: response.data,
				meta: response.meta
		}})
	}).catch((err) => {
		return dispatch(errorHandler(
			err.error || err.message, 
			dispatch({ type: 'LOAD_LIST_APPLICATIONS_FAILED' }), 
		))
	})
}

export const detailApplications = (id) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DETAIL_APPLICATIONS' })
  const params = {
    'id': id
  }
  return API.GET('/participant/getApplicationDetail', params).then((response) => {
    dispatch({
      type: 'LOAD_DETAIL_APPLICATIONS_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DETAIL_APPLICATIONS_FAILED' }), 
    ))
  })
}