import React, { Component } from 'react';
import Cookie from 'js-cookie';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb, Button, Card, Space, Typography } from 'antd';
import { RightCircleFilled } from '@ant-design/icons';

const { Text } = Typography;

export default class Magang extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'MBKM - Tentang Program - Magang');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Tentang Program - Magang</title>
        </Helmet>
        <Row>
          <Col span={24}>
            <Row className="pt-16 pb-64" gutter={[0,16]} style={{ width: '100%', backgroundColor: '#117137' }}>
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/" style={{ color: '#D9D9D9' }}>Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item style={{ color: '#D9D9D9' }}>Tentang Program</Breadcrumb.Item>
                  <Breadcrumb.Item style={{ color: '#FFFFFF' }}>Magang</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col className='mt-32' xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Card style={{ backgroundColor: 'transparent', borderStyle: 'none none none solid', borderLeft: '8px solid #FFFFFF' }} bodyStyle={{ padding: '40px 16px' }}>
                  <Space direction="vertical" size={0}>
                    <Text className="white-color fs-28">Program</Text>
                    <Text className="white-color fs-36 font-weight-bold">Magang</Text>
                  </Space>
                </Card>
              </Col>
            </Row>
            <Row className="mt-32">
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Space direction="vertical" size={16}>
                  <Text className="dark-color">Kebijakan Merdeka Belajar-Kampus Merdeka bertujuan untuk mendorong mahasiswa untuk mampu menguasai berbagai keilmuan sebelum memasuki dunia kerja. Dalam rangka menyiapkan mahasiswa dalam menghadapi tantangan perubahan sosial, budaya, dunia kerja dan kemajuan teknologi yang pesat, kompetensi mahasiswa juga harus disiapkan agar mampu beradaptasi dengan kebutuhan zaman. Kebijakan Merdeka Belajar-Kampus Merdeka diharapkan dapat menjadi jawaban atas tuntutan tersebut. Kampus Merdeka merupakan wujud pembelajaran di perguruan tinggi yang otonom dan fleksibel sehingga tercipta kultur belajar yang inovatif, tidak mengekang, dan sesuai dengan kebutuhan mahasiswa. Mahasiswa diberikan kebebasan mengambil SKS di luar program studi, tiga semester yang dimaksud berupa 1 semester kesempatan mengambil mata kuliah di luar program studi dan 2 semester melaksanakan aktivitas pembelajaran di luar perguruan tinggi.</Text>
                  <Text className="dark-color">Salah satu bentuk kegiatan belajar di luar Perguruan Tinggi adalah magang/praktik kerja di industri atau tempat kerja lainnya. Selama ini mahasiswa kurang mendapat pengalaman kerja di industri/dunia profesi nyata sehingga kurang siap bekerja. Sementara magang yang berjangka pendek (kurang dari 6 bulan) sangat tidak cukup untuk memberikan pengalaman dan kompetensi industri bagi mahasiswa. Perusahaan yang menerima magang juga menyatakan magang dalam waktu sangat pendek tidak bermanfaat, bahkan mengganggu aktivitas di Industri. Kegiatan ini harus dilaksanakan dengan bimbingan dari dosen. Dengan adanya kegiatan ini diharapkan dapat memberikan pengalaman kontekstual lapangan yang nantinya dapat meningkatkan kompetensi mahasiswa secara utuh, siap kerja dan atau menciptakan lapangan kerja baru.</Text>
                  <Text className="dark-color">Program magang industri ini bertujuan untuk memberikan pengalaman kepada mahasiswa berupa pembelajaran langsung di tempat kerja. Selama melaksanakan magang mahasiswa mendapatkan hardskills (keterampilan, complex problem solving, analytical skills, dsb.), maupun soft skills (etika profesi/kerja, komunikasi, kerjasama, dsb.). Sementara industri mendapatkan talenta yang bila cocok nantinya bisa langsung di-recruit, sehingga mengurangi biaya recruitment dan training awal/induksi. Mahasiswa yang sudah mengenal tempat kerja tersebut akan lebih mantap dalam memasuki dunia kerja dan karirnya. Melalui kegiatan ini, permasalahan industri akan mengalir ke perguruan tinggi sehingga meng-update bahan ajar dan pembelajaran dosen serta topik-topik riset di perguruan tinggi akan makin relevan.</Text>
                  <Text className="dark-color font-weight-bold">Tujuan program kegiatan magang antara lain :</Text>
                  <ul>
                    <li>Memberikan pengalaman yang kepada mahasiswa dengan pembelajaran langsung di tempat kerja (experiential learning) sehingga akan lebih mantap dalam memasuki dunia kerja dan karirnya</li>
                    <li>Meningkatkan hard skills (keterampilan, complex problem solving, analytical skills, dsb.) dan soft skills (etika profesi/kerja, komunikasi, kerjasama, dsb.) mahasiswa sesuai dengan bidang keahlian</li>
                    <li>Mempercepat transfer ilmu dan teknologi dari lingkungan IDUKA ke lingkungan Perguruan Tinggi maupun sebaliknya, sehingga perkembangan pembelajaran dan riset di perguruan tinggi juga makin relevan</li>
                  </ul>
                </Space>
              </Col>
            </Row>
            <Row className="mt-32 text-align-center">
              <Col xs={{ span: 24}} lg={{ span: 10 , offset: 7 }} xxl={{ span: 6, offset: 9 }}>
                <Link className="dark-color" to={Cookie.get("user") ? "/kandidat/program" : "/daftar"}>
                  <Button type="primary" className="border-radius-8 text-align-left pt-16 pb-16 card-next-magang" >
                    <Row justify="space-around" align="middle">
                      <Col>
                        <Space direction='vertical' size={0}>
                          <Text className="white-color text-card-next-top">INGIN MENCOBA DUNIA KERJA?</Text>
                          <Text className="white-color text-card-next-bottom">AYO DAFTARKAN DIRI KAMU</Text>
                        </Space>
                      </Col>
                      <Col className="text-align-right" span={4}>
                        <RightCircleFilled className="text-card-next-icon" />
                      </Col>
                    </Row>
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
