import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Loading } from '../../components';
import { Row, Col, Breadcrumb, Image, Card, Space, Typography, Tooltip } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import UnduhanBackground from '../../assets/img/background-unduhan.png';
import { indexFileManagement, updateDownloader, unmountIndexFileManagement } from '../../redux/actions/fileManagement/fileManagementAction';

const { Text } = Typography;

export class Galeri extends Component {
  componentDidMount() {
    const { actionGetFileManagement } = this.props

    return actionGetFileManagement()
  }
  onClickLink = (id) => {
    const { actionUpdateDownloader } = this.props
    actionUpdateDownloader(id)
  }
  render() {
    const { getFileManagement: { data, loading } } = this.props
    
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'MBKM - Unduhan');
    }

    if(loading){
      return <Loading />
    }
    
    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Unduhan</title>
        </Helmet>
        <Row className="mt-16" gutter={[0,16]}>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Unduhan</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24}>
            <Image
              src={UnduhanBackground}
              preview={false}
              height={320}
              width={'100%'}
              style={{ objectFit: 'cover' }}
            />
          </Col>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            <Card style={{ backgroundColor: '#F1F1F1' }} bodyStyle={{ padding: 16 }}>
              <Space direction="vertical" size={16}>
                <Text className="info-color fs-18">Panduan unduhan untuk mahasiswa</Text>
              </Space>
            </Card>
          </Col>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            <Space className="ml-32 fs-16" direction='vertical'>
              {
                data.map((item,i) => 
                  <Tooltip key={i} title="click to download file" onClick={() => this.onClickLink(item.id)}>
                    <a href={item.fileUrl} target="_blank" rel="noreferrer"><PaperClipOutlined /> {item.name}</a>
                  </Tooltip>
                )
              }
            </Space>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountIndexFileManagement } = this.props
    return unmountIndexFileManagement()
  }
}

const mapStateToProps = (state) => ({
  getFileManagement: state.fileManagementReducer
})

const mapDispatchToProps = {
  actionGetFileManagement: indexFileManagement,
  actionUpdateDownloader: updateDownloader,
  unmountIndexFileManagement: unmountIndexFileManagement
}

export default connect(mapStateToProps, mapDispatchToProps)(Galeri)