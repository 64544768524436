import React from 'react'
import { Link } from 'react-router-dom';
import { Carousel, Image, Grid } from 'antd';
import ImgBanner from '../../assets/img/home/banner-mbkm1.png';
// import Banner1 from '../../assets/img/home/banner-xpert1.png';
// import Banner2 from '../../assets/img/home/banner-xpert2.png';
// import Banner3 from '../../assets/img/home/banner-xpert3.png';
// import MBanner1 from '../../assets/img/home/m-banner-xpert1.png';
// import MBanner2 from '../../assets/img/home/m-banner-xpert2.png';
// import MBanner3 from '../../assets/img/home/m-banner-xpert3.png';

const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function Banner() {
  const { xs, xxl } = GetBreakPoint()

  return (
    <React.Fragment>
      <Carousel autoplay>
        <div>
          <Link to='/daftar'>
            <Image
              width={'100%'}
              height={ xxl ? 460 : xs ? 180 : 350 }
              // src={ xs ? MBanner1 : Banner1 }
              src={ ImgBanner }
              preview={false}
              style={{ objectFit: xs ? 'containt' : 'cover' }}
            />
          </Link>
        </div>
        {/* <div>
          <Link to='/xpert/list'>
            <Image
              width={'100%'}
              height={ xxl ? 460 : xs ? 180 : 350 }
              src={ xs ? MBanner2 : Banner2 }
              preview={false}
              style={{ objectFit: xs ? 'containt' : 'cover' }}
            />
          </Link>
        </div>
        <div>
          <a href="https://www.instagram.com/jobshub_id" target="_blank" rel="noreferrer">
            <Image
              width={'100%'}
              height={ xxl ? 460 : xs ? 180 : 350 }
              src={ xs ? MBanner3 : Banner3 }
              preview={false}
              style={{ objectFit: xs ? 'containt' : 'cover' }}
            />
          </a>
        </div> */}
      </Carousel>
    </React.Fragment>
  )
}