import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import Cookie from 'js-cookie';
import { Link } from 'react-router-dom';
import { Loading } from '../../../components';
import { Row, Col, Space, Breadcrumb } from 'antd';
import { indexProfile, unmountIndexProfile } from '../../../redux/actions/profile/profileAction'
import Header from './Header';
import Sider from './Sider';
import AboutMe from './AboutMe';
import PersonalInfo from './PersonalInfo';
import Education from './Education';
import Document from './Document';

export class Profile extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      // nameVisible: null
    }
  }
  
  componentDidMount() {
    const { actionGetProfile } = this.props

    return actionGetProfile()
  }
  
  render() {
    // const { nameVisible } = this.state
    const { getProfile: { data, loading }, actionGetProfile } = this.props
    
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'MBKM - Profil');
    }

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Profil</title>
        </Helmet>
        <Row className="mb-32">
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            <Breadcrumb className="mb-32 mt-32">
              <Breadcrumb.Item>
                <Link to={Cookie.get('user') ? '/beranda' : '/'}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Profil</Breadcrumb.Item>
            </Breadcrumb>
            <Row className='mt-32' gutter={32}>
              <Col xs={24} lg={4}>
                <Sider pathname={this.props.location.pathname} history={this.props.history}/>
              </Col>
              <Col xs={24} lg={20}>
                <Space className="width-100" direction="vertical" size={32}>
                  <Header data={data} actionGetProfile={actionGetProfile} />
                  <PersonalInfo data={data} actionGetProfile={actionGetProfile} />
                  <Education data={data} actionGetProfile={actionGetProfile} />
                  <AboutMe data={data} actionGetProfile={actionGetProfile}/>
                  <Document data={data} actionGetProfile={actionGetProfile} />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountIndexProfile } = this.props
    return unmountIndexProfile()
  }
}

const mapStateToProps = (state) => ({
  getProfile: state.profileReducer
})

const mapDispatchToProps = {
  actionGetProfile: indexProfile,
  unmountIndexProfile: unmountIndexProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
