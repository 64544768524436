import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb, Image, Card, Space, Typography, Carousel } from 'antd';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import G1 from '../../assets/img/galeri/kewirausahaan/20221203_194231.jpg';
import G2 from '../../assets/img/galeri/kewirausahaan/customer.jpeg';
import G3 from '../../assets/img/galeri/kewirausahaan/Menicure 2.png';
import G4 from '../../assets/img/galeri/kewirausahaan/Proses Pembuatan Produk SweetFit ~ Health Cookies and Tea(5).jpg';
import G5 from '../../assets/img/galeri/magang/FOTO-FOTO-MAGANG_PAULINA-LEDI-SAFITRI_101911001_PSIKOLOGI-2019_-1.png';
import G6 from '../../assets/img/galeri/magang/FOTO-FOTO-MAGANG_PAULINA-LEDI-SAFITRI_101911001_PSIKOLOGI-2019_-2.png';
import G7 from '../../assets/img/galeri/magang/FOTO-FOTO-MAGANG_PAULINA-LEDI-SAFITRI_101911001_PSIKOLOGI-2019_-4.png';
import G8 from '../../assets/img/galeri/pertukaran_pelajar/PHOTO-2022-10-10-14-38-50.jpg';
import G9 from '../../assets/img/galeri/pertukaran_pelajar/PHOTO-2022-10-25-13-20-30 2.jpg';
import G10 from '../../assets/img/galeri/proyek_kemanusiaan/WhatsApp Image 2022-11-20 at 14.24.57.jpeg';
import G11 from '../../assets/img/galeri/proyek_kemanusiaan/WhatsApp Image 2022-12-11 at 14.45.42.jpeg';
import G12 from '../../assets/img/galeri/proyek_kemanusiaan/WhatsApp Image 2022-12-11 at 20.57.24.jpeg';

const { Text } = Typography;

export class Galeri extends Component {
  ref = React.createRef()

  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      openLightbox: false,
      slides: []
    }
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.ref.current.next();
  }

  previous() {
    this.ref.current.prev();
  }

  render() {
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'MBKM - Galeri');
    }

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1
    };

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Galeri</title>
        </Helmet>
        <Row className="mt-16" gutter={[0,16]}>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Galeri</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            <Card 
              className="mb-16 box-shadow" 
              title='Galeri'
              extra={
                <Space size={32}>
                  <Text className="dark-color cursor-pointer" onClick={this.previous}><LeftCircleFilled /> Sebelumnya</Text>
                  <Text className="dark-color cursor-pointer" onClick={this.next}>Berikutnya <RightCircleFilled /></Text>
                </Space>
              }
            >
              <Carousel ref={this.ref} {...settings}>
                {/* Kewirausahaan */}
                <Row gutter={8}>
                  <Col span={24}>
                    <div>
                      {/* <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(data.listJobStory,i)} bodyStyle={{ padding: 8 }}> */}
                      <Card bordered={false} bodyStyle={{ padding: 8 }}>
                        {/* {
                          item.fileExtension === 'mp4' ?
                            <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                              <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                            </Card>
                          : */}
                            <Image
                              src={G1}
                              preview={false}
                              width={'100%'}
                              height={300}
                              style={{ objectFit: 'cover' }}
                            />
                        {/* } */}
                      </Card>
                    </div>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <div>
                      {/* <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(data.listJobStory,i)} bodyStyle={{ padding: 8 }}> */}
                      <Card bordered={false} bodyStyle={{ padding: 8 }}>
                        {/* {
                          item.fileExtension === 'mp4' ?
                            <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                              <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                            </Card>
                          : */}
                            <Image
                              src={G2}
                              preview={false}
                              width={'100%'}
                              height={300}
                              style={{ objectFit: 'cover' }}
                            />
                        {/* } */}
                      </Card>
                    </div>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <div>
                      {/* <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(data.listJobStory,i)} bodyStyle={{ padding: 8 }}> */}
                      <Card bordered={false} bodyStyle={{ padding: 8 }}>
                        {/* {
                          item.fileExtension === 'mp4' ?
                            <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                              <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                            </Card>
                          : */}
                            <Image
                              src={G3}
                              preview={false}
                              width={'100%'}
                              height={300}
                              style={{ objectFit: 'cover' }}
                            />
                        {/* } */}
                      </Card>
                    </div>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <div>
                      {/* <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(data.listJobStory,i)} bodyStyle={{ padding: 8 }}> */}
                      <Card bordered={false} bodyStyle={{ padding: 8 }}>
                        {/* {
                          item.fileExtension === 'mp4' ?
                            <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                              <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                            </Card>
                          : */}
                            <Image
                              src={G4}
                              preview={false}
                              width={'100%'}
                              height={300}
                              style={{ objectFit: 'cover' }}
                            />
                        {/* } */}
                      </Card>
                    </div>
                  </Col>
                </Row>

                {/* Magang */}
                <Row gutter={8}>
                  <Col span={24}>
                    <div>
                      {/* <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(data.listJobStory,i)} bodyStyle={{ padding: 8 }}> */}
                      <Card bordered={false} bodyStyle={{ padding: 8 }}>
                        {/* {
                          item.fileExtension === 'mp4' ?
                            <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                              <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                            </Card>
                          : */}
                            <Image
                              src={G5}
                              preview={false}
                              width={'100%'}
                              height={300}
                              style={{ objectFit: 'cover' }}
                            />
                        {/* } */}
                      </Card>
                    </div>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <div>
                      {/* <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(data.listJobStory,i)} bodyStyle={{ padding: 8 }}> */}
                      <Card bordered={false} bodyStyle={{ padding: 8 }}>
                        {/* {
                          item.fileExtension === 'mp4' ?
                            <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                              <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                            </Card>
                          : */}
                            <Image
                              src={G6}
                              preview={false}
                              width={'100%'}
                              height={300}
                              style={{ objectFit: 'cover' }}
                            />
                        {/* } */}
                      </Card>
                    </div>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <div>
                      {/* <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(data.listJobStory,i)} bodyStyle={{ padding: 8 }}> */}
                      <Card bordered={false} bodyStyle={{ padding: 8 }}>
                        {/* {
                          item.fileExtension === 'mp4' ?
                            <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                              <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                            </Card>
                          : */}
                            <Image
                              src={G7}
                              preview={false}
                              width={'100%'}
                              height={300}
                              style={{ objectFit: 'cover' }}
                            />
                        {/* } */}
                      </Card>
                    </div>
                  </Col>
                </Row>
                
                {/* Pertukaran Pelajar */}
                <Row gutter={8}>
                  <Col span={24}>
                    <div>
                      {/* <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(data.listJobStory,i)} bodyStyle={{ padding: 8 }}> */}
                      <Card bordered={false} bodyStyle={{ padding: 8 }}>
                        {/* {
                          item.fileExtension === 'mp4' ?
                            <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                              <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                            </Card>
                          : */}
                            <Image
                              src={G8}
                              preview={false}
                              width={'100%'}
                              height={300}
                              style={{ objectFit: 'cover' }}
                            />
                        {/* } */}
                      </Card>
                    </div>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <div>
                      {/* <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(data.listJobStory,i)} bodyStyle={{ padding: 8 }}> */}
                      <Card bordered={false} bodyStyle={{ padding: 8 }}>
                        {/* {
                          item.fileExtension === 'mp4' ?
                            <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                              <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                            </Card>
                          : */}
                            <Image
                              src={G9}
                              preview={false}
                              width={'100%'}
                              height={300}
                              style={{ objectFit: 'cover' }}
                            />
                        {/* } */}
                      </Card>
                    </div>
                  </Col>
                </Row>

                {/* Proyek Kemanusiaan */}
                <Row gutter={8}>
                  <Col span={24}>
                    <div>
                      {/* <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(data.listJobStory,i)} bodyStyle={{ padding: 8 }}> */}
                      <Card bordered={false} bodyStyle={{ padding: 8 }}>
                        {/* {
                          item.fileExtension === 'mp4' ?
                            <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                              <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                            </Card>
                          : */}
                            <Image
                              src={G10}
                              preview={false}
                              width={'100%'}
                              height={300}
                              style={{ objectFit: 'cover' }}
                            />
                        {/* } */}
                      </Card>
                    </div>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <div>
                      {/* <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(data.listJobStory,i)} bodyStyle={{ padding: 8 }}> */}
                      <Card bordered={false} bodyStyle={{ padding: 8 }}>
                        {/* {
                          item.fileExtension === 'mp4' ?
                            <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                              <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                            </Card>
                          : */}
                            <Image
                              src={G11}
                              preview={false}
                              width={'100%'}
                              height={300}
                              style={{ objectFit: 'cover' }}
                            />
                        {/* } */}
                      </Card>
                    </div>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <div>
                      {/* <Card bordered={false} className="cursor-pointer" onClick={() => handleOpenLightbox(data.listJobStory,i)} bodyStyle={{ padding: 8 }}> */}
                      <Card bordered={false} bodyStyle={{ padding: 8 }}>
                        {/* {
                          item.fileExtension === 'mp4' ?
                            <Card className='width-100' style={{ height: 160, background: 'black' }} bodyStyle={{ height: 160, textAlign: 'center' }}>
                              <PlayCircleOutlined style={{ color: 'white', fontSize: 60, marginTop: 25  }} />
                            </Card>
                          : */}
                            <Image
                              src={G12}
                              preview={false}
                              width={'100%'}
                              height={300}
                              style={{ objectFit: 'cover' }}
                            />
                        {/* } */}
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Carousel>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Galeri)