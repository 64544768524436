import { API } from '../../../config'

export const addActivity = (value, successCB, failedCB) => async dispatch => {
  API.POST_FORM_DATA('/participant/activity/add', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const updateActivity = (value, successCB, failedCB) => async dispatch => {
  API.POST_FORM_DATA('/participant/activity/update', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}