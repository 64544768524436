import React from 'react';
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import { Row, Col, Space, Typography, Image, Button, Tag, Grid, Divider } from 'antd';
import { ClockCircleOutlined, ArrowRightOutlined, DoubleRightOutlined } from '@ant-design/icons';
// import ExmapleImageDetail from '../../assets/img/home/exm-img-detail.jpeg';
// import ExmapleImageList from '../../assets/img/home/exm-img-list.png';
import moment from 'moment';
import 'moment/locale/id';

// const { CheckableTag } = Tag;
const { Text } = Typography;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function BeritaDanPengumuman(props) {
  const { getDataLanding: { data } } = props
  const { xxl, xs } = GetBreakPoint()
  // const { selectedTag, onChangeTag } = props
  // const tagsData = ['Semua', 'Berita', 'Pengumuman'];
  const onClickMore = (id) => {
    const { history } = props
    history.push(Cookie.get('user') ? `/kandidat/berita-dan-pengumuman/detail/${id}` : `/berita-dan-pengumuman/detail/${id}`)
  }
  return (
    <React.Fragment>
      <Row className={xs ? "mt-16 mb-32" : "mt-32 mb-64"} gutter={xxl ? 128 : xs ? 8 : 32}>
        <Col className={xs ? "mb-16" : null} xs={24} lg={12}>
          <Text className={xs ? "fs-20 font-weight-500" : "fs-24 font-weight-500"}>Berita & Pengumuman</Text>
        </Col>
        <Col className={xs ? null : "float-right"} xs={24} lg={12}>
          {/* <Space className={xs ? null : "float-right"}>
            <span className={xs ? "fs-12" : "fs-16"}>Kategori:</span>
            {tagsData.map(tag => (
              <CheckableTag
                className={xs ? "pt-4 pb-6 pr-8 pl-8 fs-11 border-radius-8" : "pt-4 pb-6 pr-16 pl-16 fs-15 border-radius-8"}
                key={tag}
                checked={selectedTag === tag}
                onChange={() => onChangeTag(tag)}
              >
                {tag}
              </CheckableTag>
            ))}
          </Space> */}
        </Col>
        <Divider style={{ border: xs ? '1px solid #000000' : '2px solid #000000', marginBottom: 32 }} />
        {
          data.listNews.map((item,i) => 
            i === 0 ?
              <Col key={i} xs={24} lg={14}>
                <Image
                  width={'100%'}
                  height={xs ? 250 : 500}
                  src={item.mainImageUrl}
                  preview={false}
                  style={{ borderRadius: 16, objectFit: 'content' }}
                />

                <Row className="mt-16">
                  <Col span={24}>
                    <Space className='width-100' direction="vertical" size={4}>
                      <Tag color={item.newsCategory === "NEWS" ? "#633FB5" : "#FF8811"} className={"pr-8 pl-8 fs-11 border-radius-8" }>{item.newsCategory === "NEWS" ? "Berita" : "Pengumuman"}</Tag>
                      <Text className={xs ? "dark-color font-weight-500 fs-16 cursor-pointer" : "dark-color font-weight-500 fs-20 cursor-pointer"} onClick={() => onClickMore(item.id)}>{item.title}</Text>
                      <Space size={32}>
                        {/* <Text className="light-color fs-14">Oleh John Doe</Text> */}
                        <Space size={4}>
                          <ClockCircleOutlined className="fs-12" />
                          <Text className="dark-color fs-12">{moment(item.publishedDate, 'DD/MM/YYYY').format('LL')}</Text>
                        </Space>
                      </Space>
                      <div className='news-ellpisis' dangerouslySetInnerHTML={{ __html: item.content }}></div>
                      <Text className="primary-color cursor-pointer" onClick={() => onClickMore(item.id)}>Baca Selengkapnya <DoubleRightOutlined /></Text>
                    </Space>
                  </Col>
                </Row>
              </Col>
            : null
          )
        }
        <Col xs={24} lg={10}>
          {
            data.listNews.map((item,i) => 
              i > 0 ?
                <Row key={i} className={xs ? "mt-16 cursor-pointer" : "mb-16 cursor-pointer"} justify="space-around" align="middle" gutter={xs ? [8,16] : [32,32]} onClick={() => onClickMore(item.id)}>
                  <Col xs={7} lg={8}>
                    <Image
                      width={xs ? 100 : 132}
                      height={xs ? 100 : 132}
                      src={item.mainImageUrl}
                      preview={false}
                      style={{ borderRadius: 8, objectFit: 'containt' }}
                    />
                  </Col>
                  <Col xs={17} lg={16}>
                    <Space className='width-100' direction="vertical" size={xs ? 0: 4}>
                      <Tag color={item.newsCategory === "NEWS" ? "#633FB5" : "#FF8811"} className={"pr-8 pl-8 fs-11 border-radius-8" }>{item.newsCategory === "NEWS" ? "Berita" : "Pengumuman"}</Tag>
                      <Text className={xs ? "dark-color font-weight-500 fs-14" : "dark-color font-weight-500 fs-18"}>{item.title}</Text>
                      <Space size={32}>
                        {/* <Text className={xs ? "light-color fs-12" : "light-color fs-14"}>Oleh John Doe</Text> */}
                        <Space size={4}>
                          <ClockCircleOutlined className="fs-12" />
                          <Text className="dark-color fs-12">{moment(item.publishedDate, 'DD/MM/YYYY').format('LL')}</Text>
                        </Space>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              : null
            )
          }
        </Col>
        <Col span={24}>
          <Divider orientation="right" plain>
            <Link to={Cookie.get('user') ? "kandidat/berita-dan-pengumuman" : "/berita-dan-pengumuman"}>
              <Button type="primary" style={{ borderRadius: 32 }}>Lihat Selengkapnya <ArrowRightOutlined /></Button>
            </Link>
          </Divider>
        </Col>
      </Row>
    </React.Fragment>
  )
}