import React from 'react'
import { MainAuth, MainPrivate } from '../components'
import { Route, Redirect } from 'react-router-dom'

export const PrivateRoute = ({ component: Component, passProps, ...res }) => {
  return (
    <React.Fragment>
      <Route 
        {...res} 
        render = {
          (props) => passProps.authed ? <MainPrivate {...res} {...props}> <Component {...res} {...props} /> </MainPrivate> : <Redirect to="/" /> 
        } 
      />
    </React.Fragment>
  )
}

export const AuthRoute = ({ component: Component, passProps, ...res }) => {
  return (
    <Route 
      {...res}
      render = {
        (props) => !passProps.authed ? <MainAuth {...res} {...props}> <Component {...res} {...props} /> </MainAuth> : <Redirect to="/beranda"/>
       }
    />
  )
}
