import { API } from '../../../config'
import { errorHandler } from '../auth/errorAction'

export const unmountListProgram = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_PROGRAM'})
}

export const unmountDetailProgram = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_PROGRAM'})
}

export const listProgram = (params, successCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_PROGRAM' })
  
  return API.GET('/program/list', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_PROGRAM_SUCCESS', 
      payload: {
        data: response.data,
        meta: response.meta
    }})
    return successCB(params)
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_PROGRAM_FAILED' }), 
    ))
  })
}

export const detailProgram = (id) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DETAIL_PROGRAM' })
  const params = {
    'id': id
  }
  return API.GET('/program/getById', params).then((response) => {
    dispatch({
      type: 'LOAD_DETAIL_PROGRAM_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DETAIL_PROGRAM_FAILED' }), 
    ))
  })
}