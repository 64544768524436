import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Form, Input, Card, Button, Row, Col, Typography, Space, Tooltip, message } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { forgotPass } from '../../../redux/actions/forgotPassword/forgotPasswordAction';

const { Text } = Typography

export class ChangePassword extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      visible: false
    }
  }
  
  onFocusPassword = (value) => {
    this.setState({
      visible: value
    })
  }

  onFinish =  (values) => {
    const { actionForgotPass } = this.props

    values.username = localStorage.getItem('emailForgotPass')

    return this.setState({ submitLoading: true }, () => {
      actionForgotPass(values, response => {
        if(response.code === 1000){
          return this.setState({ submitLoading: false }, () => {
            message.success('Password updated successfully', () => {
              localStorage.removeItem('emailForgotPass')
              this.props.history.push('/masuk')
            })
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { submitLoading, visible } = this.state

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'MBKM - Ganti Kata Sandi');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Ganti Kata Sandi</title>
        </Helmet>
        <Row className="display-flex" align="middle" justify="center">
          <Col>
            <Card className="card-forgot-password" bodyStyle={{ padding: 0, display: 'flex' }}>
              <Form 
                layout="vertical" 
                onFinish={this.onFinish}
              >
                <Row gutter={[16,16]}>
                  <Col span={24}>
                    <Space className="width-100" direction="vertical" size="small">
                      <Text className="fs-28" type="secondary">Masukan Kata Sandi Baru</Text>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Form.Item 
                      className="mb-16"
                      label="Kata Sandi Baru"
                      name="newPassword"
                      validateFirst
                      hasFeedback
                      rules={[
                        { required: true, message: 'Kolom ini wajib diisi!' },
                        { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, message: 'Kata sandi tidak memenuhi syarat' },
                        { min: 8, message: 'Kolom ini harus memiliki paling sedikit 8 karakter' },
                      ]}
                    >
                      <Input.Password onFocus={() => this.onFocusPassword(true)} onBlur={() => this.onFocusPassword(false)} />
                    </Form.Item>
                  </Col>
                  
                  <Col span={22}>
                    <Form.Item 
                      className="width-100 mb-16"
                      label="Konfirmasi Kata Sandi Baru"
                      name="retypeNewPassword" 
                      dependencies={['newPassword']}
                      hasFeedback
                      rules={[
                        { required: true, message: 'Kolom ini wajib diisi!' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Kata sandi tidak cocok!'));
                          }
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>

                  <Col className="dark-color" span={2} style={{ paddingTop: 36, marginLeft: -8 }}>
                    <Tooltip visible={visible} placement="right" title="Kata sandi minimal 8 karakter, 1 HURUF KAPITAL dan 1 angka">
                      <InfoCircleTwoTone className="fs-16" onMouseLeave={() => this.onFocusPassword(false)} onMouseEnter={() => this.onFocusPassword(true)} twoToneColor="#0076de" />
                    </Tooltip>
                  </Col>

                  <Col span={24}>
                    <Space className="width-100" direction="vertical">
                      <Form.Item className="mb-0">
                        <Button type="primary" htmlType="submit" block loading={submitLoading}>
                          Simpan
                        </Button>
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionForgotPass: forgotPass
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)