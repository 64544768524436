import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Card, Skeleton, Image, Space, Tag, Pagination, Empty, Breadcrumb, Modal } from 'antd';
import {FileTextOutlined } from '@ant-design/icons';
import { listApplications, unmountListApplications } from '../../../../redux/actions/statusLamaran/statusLamaranAction';
import EmptyLogo from '../../../../assets/img/EmptyLogo.png';
import Sider from '../../Profile/Sider';
// import MenuApplications from '../Menu';
const { Text } = Typography;
export class index extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      open: false,
      rejectNote: null,
      params: {
        page: 1,
        perpage: 10,
        status: 'REJECTED'
      },
      // activeKey: "Ditolak",
      visible: false,
      submitLoading: false,
    }
  }
  
  componentDidMount() {
    const { params } = this.state
    const { actionListApplications } = this.props

    return actionListApplications(params)
  }

  handlePagination = (pageNumber) => {
    const { params: { perpage, status } } = this.state
    const params = {
      page: pageNumber,
      perpage: perpage,
      status: status
    }
    this.setState({ params })
    window.scrollTo(0, 0);
    const { actionListApplications } = this.props
    return actionListApplications(params)
  }

  showModal = (val) => {
    this.setState({ open: true, rejectNote: val })
  };

  handleOk = () => {
    this.setState({ open: false, rejectNote: null})
  };

  handleCancel = () => {
    this.setState({ open: false, rejectNote: null })
  };

  render() {
    const { open, rejectNote } = this.state
    const { getListApplications } = this.props

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'MBKM - Status Lamaran - Ditolak');
    }

    // const initialProps = {
    //   activeKey: activeKey
    // }
    
    return (
      
      <React.Fragment>
        <Helmet>
          <title>MBKM - Status Lamaran - Ditolak</title>
        </Helmet>
        <Row className="mb-32">
          <Col className="width-100" md={{ span: 20, offset: 2 }}>
            <Breadcrumb className="mb-32 mt-32">
              <Breadcrumb.Item>
                <Link to='/beranda'>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Program Saya</Breadcrumb.Item>
              <Breadcrumb.Item>Ditolak</Breadcrumb.Item>
            </Breadcrumb>
            <Row gutter={32}>
              <Col xs={24} lg={4}>
                <Sider pathname={this.props.location.pathname} history={this.props.history} />
              </Col>
              <Col xs={24} lg={20}>
                <Row className="width-100 mb-8" gutter={8}>
                  {/* <Col span={24}>
                    <MenuApplications {...this.props} {...initialProps} />
                  </Col> */}
                  <Col span={24}>
                    {
                      getListApplications.data ?
                        <Text className="dark-color fs-13">{`Menampilkan ${getListApplications?.meta?.total === 0 ? getListApplications?.meta?.total : (getListApplications?.meta?.page*getListApplications?.meta?.perpage)-(getListApplications?.meta?.perpage-1)}-${getListApplications?.meta?.page === getListApplications?.meta?.pages || getListApplications?.meta?.total === 0 ? getListApplications?.meta?.total : getListApplications?.meta?.page*getListApplications?.meta?.perpage} dari ${getListApplications?.meta?.total} program`}</Text>
                      :
                        <Skeleton.Input active />
                    }
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col className="width-100" span={24}>
                    {
                      getListApplications.loading ?
                        <Card className="border-radius-3 box-shadow mb-6">
                          <Skeleton active avatar />
                        </Card>
                      :
                        getListApplications?.data?.length > 0 ?
                          getListApplications?.data?.map((item, i) => 
                            <Card key={i} className="border-radius-3 box-shadow mb-6">
                              <Row className="width-100" gutter={16} align="middle">
                                <Col className="col-img-job" xs={24} sm={4} md={4}>
                                  <Image
                                    src={item.partnerLogoUrl ? item.partnerLogoUrl : EmptyLogo}
                                    preview={false}
                                    width={100}
                                    height={100}
                                    style={{ objectFit: 'contain' }}
                                  />
                                </Col>
                                <Col xs={24} sm={13} md={12}>
                                  <Space direction="vertical" size={0}>
                                    <Space>
                                      {/* <Link to={`/kandidat/program/detail/${item.id}`}> */}
                                        <Text className="info-color fs-17">{item.title}</Text>
                                      {/* </Link> */}
                                      <Tag className="application-date border-radius-3 fs-13" color={item.status === 'ACTIVE' ? 'success' : 'error'}>{item.status === 'ACTIVE' ? 'Aktif' : 'Tidak Aktif'}</Tag>
                                    </Space>
                                    <Text className="fs-13 dark-color">{item.partnerName}</Text>
                                    <Text className="fs-13 dark-color">{item.cityName}, {item.countryName}</Text>
                                    
                                  </Space>
                                </Col>
                                <Col xs={24} sm={7} md={8} className="application-date">
                                  <Space direction="vertical" size={8} className="application-date">
                                    <Text className="application-date fs-13 dark-color">Mengajukan pada <Text className="fs-13 dark-color font-weight-bold">{item.createdDate}</Text></Text>
                                    <Text onClick={() => this.showModal(item.rejectNote)} style={{ color: '#32AB6D', float: 'right', cursor: 'pointer' }}>Lihat Catatan <FileTextOutlined /></Text>
                                    <Modal
                                      title="Catatan"
                                      open={open}
                                      onOk={this.handleOk}
                                      onCancel={this.handleCancel}
                                      footer={false}
                                    >
                                      {/* <Text>{rejectNote}</Text> */}
                                      <div dangerouslySetInnerHTML={{ __html: rejectNote ? rejectNote : 'N/A' }}></div>
                                    </Modal>
                                  </Space>
                                </Col>
                              </Row>
                            </Card>
                          )
                        :
                          <Card className="border-radius-3 box-shadow mb-6">
                            <Empty />
                          </Card>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col className="text-align-center" span={24}>
                    <Pagination onChange={this.handlePagination} current={getListApplications?.meta?.page} pageSize={getListApplications?.meta?.perpage || 10} total={getListApplications?.meta?.total} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountListApplications } = this.props
    return unmountListApplications()
  }
}

const mapStateToProps = (state) => ({
  getListApplications: state.listApplicationsReducer
})

const mapDispatchToProps = {
  actionListApplications: listApplications,
  unmountListApplications: unmountListApplications,
}

export default connect(mapStateToProps, mapDispatchToProps)(index)