import React from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Grid, Form, Input, Collapse, Checkbox, Button, Card, Typography, Space, Badge, Skeleton, Pagination, Empty } from 'antd';
import { UserOutlined, TeamOutlined } from '@ant-design/icons';
import Background from '../../../../assets/img/EmptyCompany.png';
// import IconVerified from '../../../../assets/img/home/ic-verified.png';
import { programCategory } from '../../../../utils/constant/programCategory';

const { Search } = Input;
const { Panel } = Collapse;
const { Text } = Typography;
const { useBreakpoint } = Grid;
function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function ProgramListDesktop(props) {
  const { 
    getProgram,
    formRef,
    onSearch,
    handleCategory,
    // handleDate,
    handlePagination,
    handleClearFilter
  } = props
  
  const { xxl } = GetBreakPoint()

  const handleClearForm = () => {
    window.scrollTo(0, 0);
    formRef.current.setFieldsValue({
      search: null,
      category: null,
    });
  }
  return (
    <React.Fragment>
      <Form className="mb-32" ref={formRef}>
        <Row className="mt-32">
          <Col lg={{ span: 20, offset: 2 }} xxl={{ span: 20, offset: 2 }}>
            <Row>
              <Col span={24}>
                <Text className="dark-color font-weight-500 fs-28">Program MBKM</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='mt-32'>
          <Col lg={{ span: 20, offset: 2 }} xxl={{ span: 20, offset: 2 }}>
            <Row gutter={32}>
              <Col lg={6} xl={5}></Col>
              <Col lg={18} xl={19}>
                <Form.Item name="search">
                  <Search
                    placeholder="Cari Program MBKM"
                    allowClear
                    enterButton="Cari"
                    onSearch={onSearch}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 20, offset: 2 }} xxl={{ span: 20, offset: 2 }}>
            <Row gutter={32}>
              <Col lg={6} xl={5}>
                <Collapse className="border-radius-6 dark-color box-shadow" style={{ backgroundColor: '#fff', border: '1px solid #d9d9d9' }} defaultActiveKey={['1','2','3','4']} expandIconPosition="end" ghost>
                <Panel header="Kategori Program" key="1">
                    {/* <Form.Item className="margin-0 mt-min-16 mb-min-16" name="eventType"> */}
                    <Form.Item className="margin-0 mt-min-16" name="category">
                      <Checkbox.Group className="checkbox-filter-program" onChange={(checkedValues) => handleCategory(checkedValues)}>
                        <Checkbox key="1" className="fs-12 dark-color width-100 margin-0" value="ENTREPRENEURSHIP">Kewirausahaan</Checkbox>
                        <Checkbox key="2" className="fs-12 dark-color width-100 margin-0" value="STUDENT_EXCHANGE">Pertukaran Mahasiswa</Checkbox>
                        <Checkbox key="3" className="fs-12 dark-color width-100 margin-0" value="INTERNSHIP">Magang</Checkbox>
                        <Checkbox key="4" className="fs-12 dark-color width-100 margin-0" value="HUMANITY">Proyek Kemanusiaan</Checkbox>
                        <Checkbox key="5" className="fs-12 dark-color width-100 margin-0" value="COMMUNITY_SERVICE">Pembangunan Desa/KKN</Checkbox>
                      </Checkbox.Group>
                    </Form.Item>
                  </Panel>
                  {/* <Divider className="dark-color" /> */}
                  {/* <Panel header="Urutan" key="2">
                    <Form.Item className="margin-0 mt-min-16" name="date">
                      <Radio.Group className="width-100" optionType="button" onChange={(e) => handleDate(e)}>
                        <Space className="width-100 text-align-center" direction="vertical">
                          <Radio.Button key="1" className="width-100 text-align-center border-radius-6 dark-color fs-12" value="today">Akhir pekan ini</Radio.Button>
                          <Radio.Button key="2" className="width-100 text-align-center border-radius-6 dark-color fs-12" value="week">Minggu Ini</Radio.Button>
                          <Radio.Button key="3" className="width-100 text-align-center border-radius-6 dark-color fs-12" value="month">Minggu depan</Radio.Button>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Panel> */}
                </Collapse>
                <Card className="border-radius-6 box-shadow mt-8" bordered={false}>
                  <Button className="width-100 border-radius-6" type="primary" onClick={() => {handleClearFilter(); handleClearForm()}}>Clear Filter</Button>
                </Card>
              </Col>
              <Col lg={18} xl={19}>
                <Row className="mb-32" gutter={[16, 16]}>
                  {
                    getProgram?.loading ?
                      <Card className="border-radius-6 box-shadow mb-8" style={{ width: '100%' }}>
                        <Skeleton active avatar />
                      </Card>
                    :
                      getProgram?.meta?.total > 0 ?
                        getProgram?.data?.map((item,i) => 
                          <Col className="xpert" key={i} lg={12} xxl={8}>
                            <Badge.Ribbon placement='start' text={programCategory.find(res => res.value === item.programCategory)?.name} color={programCategory.find(res => res.value === item.programCategory)?.color} style={{ marginTop: 116 }}>
                              <Card
                                className="border-radius-8 dark-color box-shadow item bg-color-white" 
                                cover={
                                  <img
                                    alt="example"
                                    src={item.imageUrl ? item.imageUrl : Background}
                                    height={150}
                                    style={{ borderRadius: '8px 8px 0 0', objectFit: 'cover', boxShadow: '0 1px 1px #e6e6e6' }}
                                  />
                                }
                                bordered={false}
                              >
                                <Space className="mb-8" direction="vertical" size={0}>
                                  <Text className="info-color" ellipsis={true} style={{ fontSize: xxl ? 20 : 18, width: 320 }}>{item.title}</Text>
                                </Space>

                                <Row>
                                  <Col span={24}>
                                    <Space className='width-100' direction="vertical" size={4}>
                                      <Text className="dark-color font-weight-500 fs-15">{item.partnerName}</Text>
                                      <Space size={4}>
                                        <Text className="info-color fs-14">{item.creditHour} SKS</Text>
                                        <Text className="dark-color fs-14">• {item.duration} bulan</Text>
                                      </Space>
                                      <Row align="middle">
                                        <Col span={20}>
                                          {/* {
                                            item.hasCertificate ?
                                              <Space size={4} align='middle'>
                                                <Image
                                                  width={25}
                                                  height={25}
                                                  src={IconVerified}
                                                  preview={false}
                                                />
                                                <Text className="fs-16 font-weight-500" style={{ color: '#32AB6D' }}>
                                                  Bersertifikat
                                                </Text>
                                              </Space>
                                            :
                                              null
                                          } */}
                                          {
                                            item.isGroup ?
                                              <Text className="info-color">
                                                <TeamOutlined /> Kelompok
                                              </Text>
                                            :
                                              <Text className="info-color">
                                                <UserOutlined /> Individu
                                              </Text>
                                          }
                                        </Col>
                                        <Col span={4}>
                                          <Link to={`program/detail/${item.id}`}>
                                            <Button className='float-right' type="primary" style={{ width: 100, borderRadius: 16 }} ghost={item.isApply}>{item.isApply ? 'Terdaftar': 'Daftar'}</Button>
                                          </Link>
                                        </Col>
                                      </Row>
                                    </Space>
                                  </Col>
                                </Row>
                              </Card>
                            </Badge.Ribbon>
                          </Col>
                        )
                      :
                        <Card className="width-100 border-radius-3 box-shadow mb-6">
                          <Empty />
                        </Card>
                  }
                </Row>
                <Row>
                  <Col className="text-align-center" span={24}>
                    <Pagination onChange={handlePagination} current={getProgram?.meta?.page} pageSize={getProgram?.meta?.perpage || 12} total={getProgram?.meta?.total} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}
