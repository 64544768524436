import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { Row, Col } from 'antd';
import Banner from './banner';
import AboutUs from './aboutUs';
import Program from './program';
import News from './news';
import Cta from './cta';
import Loading from '../../components/Loading';
import { dataLandingPage, unmountDataLanding } from '../../redux/actions/landingPage/landingPageAction';

export class Home extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      active: '1',
      selectedTag: "Semua"
    }
  }
  
  componentDidMount() {
    const { actionDataLanding } = this.props

    window.scrollTo(0, 0);

    return actionDataLanding()
  }

  handleMenuProgram = (e) => {
    this.setState({ active: e })
  }

  onChangeTag = (tag) => {
    this.setState({ selectedTag: tag })
  };

  render() {
    const { getDataLanding } = this.props

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'KBKM');
    }
    
    const { active, selectedTag } = this.state

    const initialProps = {
      active: active,
      handleMenuProgram: this.handleMenuProgram,
      selectedTag: selectedTag,
      onChangeTag: this.onChangeTag
    }
    
    if(getDataLanding.loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM</title>
        </Helmet>
        <Banner />
        <Row>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
            <AboutUs {...initialProps} />
            <Program {...this.props} {...initialProps} />
            <News {...this.props} {...initialProps} />
          </Col>
        </Row>
        <Cta />
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountDataLanding } = this.props

    return unmountDataLanding()
  }
}

const mapStateToProps = (state) => ({
  getDataLanding: state.landingPageReducer
})

const mapDispatchToProps = {
  actionDataLanding: dataLandingPage,
  unmountDataLanding: unmountDataLanding
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
