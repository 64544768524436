import React from 'react'
import { Menu } from 'antd';

export default function Sider(props) {
  const { history, pathname } = props
  const handleMenu = (e) => {
    history.push(e.key)
  }
  return (
    <Menu className='menu-profile' mode="inline" defaultSelectedKeys={['/profil']} defaultOpenKeys={['/profil/status-lamaran']} selectedKeys={pathname} onClick={handleMenu} style={{ backgroundColor: 'transparent' }}>
      <Menu.Item key="/profil">
        Profil Saya
      </Menu.Item>
      <Menu.SubMenu key="/profil/status-lamaran" title="Program Saya">
        <Menu.Item key="/profil/status-lamaran/pengajuan">
          Pengajuan
        </Menu.Item>
        <Menu.Item key="/profil/status-lamaran/peninjauan">
          Peninjauan
        </Menu.Item>
        <Menu.Item key="/profil/status-lamaran/diterima">
          Diterima
        </Menu.Item>
        <Menu.Item key="/profil/status-lamaran/ditolak">
          Ditolak
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="/profil/status-lamaran/selesai">
          Selesai
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  )
}
