export const maritalStatus = [
  {
    "value": "BELUM_KAWIN",
    "name": "Lajang"
  },
  {
    "value": "KAWIN",
    "name": "Kawin"
  },
  {
    "value": "CERAI_HIDUP",
    "name": "Bercerai"
  },
  {
    "value": "CERAI_MATI",
    "name": "Janda/Duda"
  }
]