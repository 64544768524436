import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography, Modal, Form, Input, Space, Button, Row, Col, message } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { checkMobile } from '../../../../../redux/actions/check/checkAction';
import { updateHeaderPhone } from '../../../../../redux/actions/profile/profileAction';

const { Text } = Typography;

export class MobileNumber extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      visible: false
    }
  }
  
  showModal = () => {
    this.setState({
      visible: true
    })
  };

  handleCancel = () => {
    this.setState({
      visible: false
    })
  };

  onCheckMobile = (e) => {
    const { actionCheckMobile, data } = this.props

    this.formRef.current.setFieldsValue({
      phoneNo: e.replace(/^0+/, '')
    })

    e = `${e.replace(/^0+/, '')}`
    
    if(e !== `${data.phoneNo}`){
      return actionCheckMobile(e, response => {
        if(response){
          this.formRef.current.setFieldsValue({
            phoneNo: null
          })
          message.error('Nomor Telepon sudah ada')
        }
      })
    }
  }

  onFinish = async (values) => {
    const { actionUpdateHeaderPhone, actionGetProfile } = this.props
    
    this.setState({ submitLoading: true }, () => {
      return actionUpdateHeaderPhone(values, response => {
        if(response.code === 1000){
          return this.setState({ submitLoading: false, visible: false }, () => {
            message.success(response.message)
            return actionGetProfile()
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { submitLoading, visible } = this.state
    const { data } = this.props

    return (
      <React.Fragment>
        <Space>
          <Text className="fs-12 dark-color">{`+62${data.phoneNo}`}</Text>
          <EditFilled className="fs-12 dark-color cursor-pointer" onClick={this.showModal} />
        </Space>

        <Modal 
          title="Nomor Telepon"
          visible={visible} 
          onCancel={this.handleCancel}
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          width={600}
          destroyOnClose
        >
          <Form 
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFinish}
          >
            <Row className="mb-8">
              <Col span={24}>
                <Space size={0} direction="vertical">
                  <Text className="fs-12" type="secondary">Nomor telepon saat ini</Text>
                  <Text className="fs-13 dark-color" type="secondary">{`+${data.phoneNo}`}</Text>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Space size={4}>
                  <Text className="fs-12" type="danger">*</Text>
                  <Text className="fs-12" type="secondary">Nomor telepon baru</Text>
                </Space>
                <Form.Item 
                  className="mb-16"
                  name="phoneNo"
                  validateFirst
                  rules={[
                    { required: true, message: 'Kolom ini wajib diisi!' },
                    { pattern: /^[0-9]*$/, message: 'Kolom ini hanya boleh berisi angka!' },
                    { min: 7, max: 13, message: 'Nomor Telepon Anda tidak valid!' },
                    // { validator(rule, value, callback){
                    //     if(idCallingCode === 99)
                    //       if(value.charAt(0) === '0' || value.charAt(0) === '8' || value.charAt(0) === ''){
                    //         callback()
                    //       }else{
                    //         callback('Invalid phone number')
                    //       }
                    //   }
                    // }
                  ]}
                >
                  <Input onBlur={(e) => this.onCheckMobile(e.target.value)} addonBefore="+62" placeholder='Masukan nomor telepon utama anda' />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space className="float-right">
                <Button onClick={this.handleCancel}>Kembali</Button>
                <Button htmlType="submit" type="primary" loading={submitLoading}>Simpan</Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionCheckMobile: checkMobile,
  actionUpdateHeaderPhone: updateHeaderPhone
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileNumber)