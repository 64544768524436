import { API } from '../../../config'

export const checkEmail = (value, successCB, failedCB) => async dispatch => {
  const params = {
    email: value
  }
  API.POST_EMAIL('/checkEmail', params).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const checkMobile = (value, successCB, failedCB) => async dispatch => {
  const params = {
    mobile: value
  }
  API.POST_EMAIL('/checkMobile', params).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}


export const setResend = (value, successCB, failedCB) => async dispatch => {
  API.POST('/resend', value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}