import React, { Component } from 'react';
import Cookie from 'js-cookie';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb, Button, Card, Space, Typography } from 'antd';
import { RightCircleFilled } from '@ant-design/icons';

const { Text } = Typography;

export default class Kewirausahaan extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'MBKM - Tentang Program - Kewirausahaan');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Tentang Program - Kewirausahaan</title>
        </Helmet>
        <Row>
          <Col span={24}>
            <Row className="pt-16 pb-64" gutter={[0,16]} style={{ width: '100%', backgroundColor: '#117137' }}>
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/" style={{ color: '#D9D9D9' }}>Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item style={{ color: '#D9D9D9' }}>Tentang Program</Breadcrumb.Item>
                  <Breadcrumb.Item style={{ color: '#FFFFFF' }}>Kewirausahaan</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col className='mt-32' xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Card style={{ backgroundColor: 'transparent', borderStyle: 'none none none solid', borderLeft: '8px solid #FFFFFF' }} bodyStyle={{ padding: '40px 16px' }}>
                  <Space direction="vertical" size={0}>
                    <Text className="white-color fs-28">Program</Text>
                    <Text className="white-color fs-36 font-weight-bold">Kewirausahaan</Text>
                  </Space>
                </Card>
              </Col>
            </Row>
            <Row className="mt-32">
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Space direction="vertical" size={16}>
                  <Text className="dark-color">Kebijakan Merdeka Belajar-Kampus Merdeka bertujuan untuk mendorong mahasiswa untuk mampu menguasai berbagai keilmuan sebelum memasuki dunia kerja. Dalam rangka menyiapkan mahasiswa dalam menghadapi tantangan perubahan sosial, budaya, dunia kerja dan kemajuan teknologi yang pesat, kompetensi mahasiswa juga harus disiapkan agar mampu beradaptasi dengan kebutuhan zaman. Kebijakan Merdeka Belajar-Kampus Merdeka diharapkan dapat menjadi jawaban atas tuntutan tersebut. Kampus Merdeka merupakan wujud pembelajaran di perguruan tinggi yang otonom dan fleksibel sehingga tercipta kultur belajar yang inovatif, tidak mengekang, dan sesuai dengan kebutuhan mahasiswa. Mahasiswa diberikan kebebasan mengambil SKS di luar program studi, tiga semester yang dimaksud berupa 1 semester kesempatan mengambil mata kuliah di luar program studi dan 2 semester melaksanakan aktivitas pembelajaran di luar perguruan tinggi.</Text>
                  <Text className="dark-color">Berdasarkan Global Entrepreneurship Index (GEI) pada tahun 2018, Indonesia hanya memiliki skor 21% wirausahawan dari berbagai bidang pekerjaan, atau peringkat 94 dari 137 negara yang disurvei. Sementara menurut riset darn IDN Research Institute tahun 2019, 69,1% millennial di Indonesia memiliki minat untuk berwirausaha. Sayangnya, potensi wirausaha bagi generasi milenial tersebut belum dapat dikelola dengan baik selama ini. Kebijakan Kampus Merdeka mendorong pengembangan minat wirausaha Mahasiswa dengan program kegiatan belajar yang sesuai.</Text>
                  <Text className="dark-color font-weight-bold">Tujuan program kegiatan wirausaha antara lain :</Text>
                  <ul>
                    <li>Memberikan Mahasiswa yang memiliki minat berwirausaha untuk mengembangkan usahanya lebih dini dan terbimbing</li>
                    <li>Menangani permasalahan pengangguran yang menghasilkan pengangguran intelektual dari kalangan sarjana</li>
                  </ul>
                  <Text className="dark-color">Kegiatan pembelajaran dalam bentuk kewirausahaan dilaksanakan dengan bimbingan baik dari pembimbing dari kampus, serta pembimbing lapang yang ada di masing-masing mitra atau mentor dari instansi/organisasi luar kampus untuk membimbing mahasiswa dalam mewujudkan program kewirausahaannya.</Text>
                </Space>
              </Col>
            </Row>
            <Row className="mt-32 text-align-center">
            <Col xs={{ span: 24 }} lg={{ span: 10 , offset: 7 }} xxl={{ span: 6, offset: 9 }}>
                <Link className="dark-color" to={Cookie.get("user") ? "/kandidat/program" : "/daftar"}>
                  <Button type="primary" className="border-radius-8 text-align-left pt-16 pb-16 card-next" >
                    <Row justify="space-around" align="middle">
                      <Col>
                        <Space direction='vertical' size={0}>
                          <Text className="white-color text-card-next-top">BERANI BERWIRAUSAHA?</Text>
                          <Text className="white-color text-card-next-bottom">AYO DAFTARKAN DIRI KAMU</Text>
                        </Space>
                      </Col>
                      <Col className="text-align-right" >
                        <RightCircleFilled className="text-card-next-icon" />
                      </Col>
                    </Row>
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
