import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImgCrop from 'antd-img-crop';
import { Row, Col, Card, Avatar, Image, Space, Button, Upload, Spin, message } from 'antd';
import { UserOutlined, CameraOutlined } from '@ant-design/icons';
import './Header.css';
import Email from './Email';
import Name from './Name';
import MobileNumber from './MobileNumber';
import Location from './Location';
import { uploadAvatar } from '../../../../redux/actions/profile/profileAction';
import { indexHeader } from '../../../../redux/actions/header/headerAction';

export class Header extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false
    }
  }
  
  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:".png,.jpg,.jpeg",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const { actionUploadAvatar, actionGetProfile, actionGetHeader } = this.props
          const value = {
            photoFile: file
          }
          return this.setState({ submitLoading: true }, () => {
            return actionUploadAvatar(value, response => {
              if(response.code === 1000){
                return this.setState({ submitLoading: false, visible: false }, () => {
                  message.success(response.message)
                  actionGetHeader()
                  actionGetProfile()
                })
              }else{
                message.error(response.message)
              }
            }, (err) => {
              return this.setState({ submitLoading: false, visible: false }, () => message.error(err.message))
            })
          })
        }
        return false;
      },
    }
  }

  render() {
    const { submitLoading } = this.state
    const { data, actionGetProfile } = this.props
    return (
      <React.Fragment>
        <Card 
          className="border-radius-6 box-shadow"
          bordered={false} 
        >
          <Row>
            <Col span={22} offset={1}>
                <Row gutter={32}>
                  <Col sm={8} md={6} lg={7} xxl={5}>
                    {
                      submitLoading ?
                        <Spin />
                      :
                        <React.Fragment>
                          {
                            data.photoUrl ?
                              <Image
                                className="img-avatar"
                                preview={{
                                  maskClassName: 'img-avatar'
                                }}
                                width={140}
                                src={data.photoUrl} 
                              />
                            :
                              <Avatar size={140} icon={<UserOutlined />} />
                          }
                          <ImgCrop shape="round" rotate={true}>
                            <Upload {...this.handleUpload()} multiple={false} showUploadList={false}>
                              <Button type="primary" size="large" shape="circle" icon={<CameraOutlined />} style={{ position: 'absolute', marginTop: 20, marginLeft: -40 }} />
                            </Upload>
                          </ImgCrop>
                        </React.Fragment>
                    }
                  </Col>
                  <Col sm={16} md={6} lg={17} xxl={19} className="pt-16">
                    <Space direction="vertical" size={2}>
                      <Name data={data} actionGetProfile={actionGetProfile} />
                      <Email data={data} actionGetProfile={actionGetProfile} />
                      <MobileNumber data={data} actionGetProfile={actionGetProfile} />
                      <Location data={data} actionGetProfile={actionGetProfile} />
                    </Space>
                  </Col>
                </Row>
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
  actionUploadAvatar: uploadAvatar,
  actionGetHeader: indexHeader,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)