let env = "https://mymbkmfo.binawan.ac.id/api"

if(process.env.REACT_APP_ENV === "development"){
  env = "https://stg-mbkmfo.binawan.ac.id/api"
}

if(process.env.REACT_APP_ENV === "production"){
  env = "https://mymbkmfo.binawan.ac.id/api"
}

export default env;