import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { listNews, unmountListNews } from '../../redux/actions/news/newsAction';
import { dataLandingPage, unmountDataLanding } from '../../redux/actions/landingPage/landingPageAction';
import BeritaComponent from '../../components/Berita/List';
import { validatePost } from '../../utils/postValidationFunction';

class Berita extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
  
    this.state = {
      active: '1',
      selectedTag: "ALL",
      meta: {
       page: 1,
       perpage: 3,
       category: null
      },
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const { meta } = this.state;
    const { actionGetNews, actionDataLanding } = this.props

    actionGetNews(meta, (res) => {
      var queryString = Object.keys(validatePost(res)).map(key => key + '=' + meta[key]).join('&');
      this.props.history.push({
        search: queryString
      })
    })
    actionDataLanding()
  }

  componentDidUpdate(prevProps, prevState) {
    const { meta } = this.state

    if(prevState.meta !== meta){
      var queryString = Object.keys(validatePost(meta)).map(key => key + '=' + meta[key]).join('&');
      this.props.history.push({
        search: queryString
      })
    }
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionGetNews } = this.props;
    meta.page = 1
    meta.perpage = 3
    meta.search = value === '' ? null : value

    return actionGetNews(meta, (res) => {
      this.setState({ meta: res })
      window.scrollTo(0, 0);
    })
  }

  onChangeTag = (val) => {
    const { meta } = this.state;
    const { actionGetNews } = this.props;
    this.setState({ selectedTag: val })
    meta.page = 1
    meta.perpage = 3
    if (val!== 'ALL') {
      meta.newsCategory = val
    }
    return actionGetNews(meta, (res) => {
      this.setState({ meta: res })
      window.scrollTo(0, 0);
    })
  };

  handlePagination = (e) => {
    const { meta } = this.state;
    const { actionGetNews } = this.props;
    meta.page = e
    actionGetNews(meta, (res) => {
      this.setState({ meta: res })
      window.scrollTo(0, 0);
    })
  }

  render() {
    const { getNews, getDataLanding } = this.props
    const { selectedTag } = this.state

    const initialProps = {
      formRef: this.formRef,
      getNews: getNews,
      getDataLanding:getDataLanding,
      onSearch: this.onSearch,
      handleCategory: (checkedValues) => this.handleCategory(checkedValues),
      handlePagination: this.handlePagination,
      onChangeTag: this.onChangeTag,
      selectedTag: selectedTag
      // onChangeTag: (val) => this.onChangeTag(val)
      // handleClearFilter: this.handleClearFilter,
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Berita</title>
        </Helmet>
        <BeritaComponent {...initialProps} {...this.props} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getNews: state.newsReducer,
  getDataLanding: state.landingPageReducer
})

const mapDispatchToProps = {
  actionGetNews: listNews,
  unmountListNews: unmountListNews,
  actionDataLanding: dataLandingPage,
  unmountDataLanding: unmountDataLanding
}

export default connect(mapStateToProps, mapDispatchToProps)(Berita)