import React, { Component } from 'react';
import Cookie from 'js-cookie';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb, Button, Card, Space, Typography } from 'antd';
import { RightCircleFilled } from '@ant-design/icons';

const { Text } = Typography;

export default class ProyekKemanusiaan extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'MBKM - Tentang Program - Proyek Kemanusiaan');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Tentang Program - Proyek Kemanusiaan</title>
        </Helmet>
        <Row>
          <Col span={24}>
            <Row className="pt-16 pb-64" gutter={[0,16]} style={{ width: '100%', backgroundColor: '#117137' }}>
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/" style={{ color: '#D9D9D9' }}>Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item style={{ color: '#D9D9D9' }}>Tentang Program</Breadcrumb.Item>
                  <Breadcrumb.Item style={{ color: '#FFFFFF' }}>Proyek Kemanusiaan</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col className='mt-32' xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Card style={{ backgroundColor: 'transparent', borderStyle: 'none none none solid', borderLeft: '8px solid #FFFFFF' }} bodyStyle={{ padding: '40px 16px' }}>
                  <Space direction="vertical" size={0}>
                    <Text className="white-color fs-28">Program</Text>
                    <Text className="white-color fs-36 font-weight-bold">Proyek Kemanusiaan</Text>
                  </Space>
                </Card>
              </Col>
            </Row>
            <Row className="mt-32">
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Space direction="vertical" size={16}>
                  <Text className="dark-color">Salah satu bentuk kegiatan belajar di luar Perguruan Tinggi adalah proyek kemanusiaan dengan mitra baik dalam negeri maupun dari lembaga luar negeri. Selama ini mahasiswa kurang mendapat pengalaman kerja di lembaga mitra secara nyata sehingga kurang siap bekerja dan terjun ke lapangan. Sementara magang yang berjangka pendek (kurang dari 6 bulan) sangat tidak cukup untuk memberikan pengalaman dan kompetensi bagi mahasiswa. Mitra yang menerima magang dalam kurun waktu yang pendek kurang memberikan manfaat yang signifikan untuk Industri dan Mahasiswa yang bersangkutan. Kegiatan ini harus dilaksanakan dengan bimbingan dari Dosen. Dengan adanya kegiatan ini diharapkan dapat memberikan pengalaman kontekstual lapangan yang nantinya dapat meningkatkan kompetensi mahasiswa secara utuh, siap kerja dan atau menciptakan lapangan kerja baru. Indonesia banyak mengalami bencana alam, baik berupa gempa bumi, erupsi gunung berapi, tsunami, bencana hidrologi, dsb. Perguruan tinggi selama ini banyak membantu mengatasi bencana melalui program-program kemanusiaan. Pelibatan mahasiswa selama ini bersifat voluntary dan hanya berjangka pendek. Selain itu, banyak lembaga Internasional maupun Nasional yang telah melakukan kajian mendalam dan membuat pilot project pembangunan di Indonesia maupun negara berkembang lainnya. Mahasiswa dengan jiwa muda, kompetensi ilmu, dan minatnya dapat menjadi “foot soldiers” dalam proyek-proyek kemanusiaan dan pembangunan lainnya baik di Indonesia maupun di luar negeri.</Text>
                  <Text className="dark-color">Selama melaksanakan proyek kemanusiaan mahasiswa mendapatkan hard skills (keterampilan, complex problem solving, analytical skills, dsb.), maupun soft skills (etika profesi/kerja, komunikasi, kerjasama, dsb.). Sementara mitra akan mendapatkan dampak / manfaat secara langsung dengan SDM dari mahasiswa sehingga peran mahasiswa dalam lingkungan akan memberikan dampak secara langsung.</Text>
                  <Text className="dark-color font-weight-bold">Tujuan program kegiatan proyek kemanusiaan antara lain :</Text>
                  <ul>
                    <li>Menyiapkan mahasiswa unggul yang menjunjung tinggi nilai kemanusiaan dalam menjalankan tugas berdasarkan agama, moral, dan etika</li>
                    <li>Melatih mahasiswa memiliki kepekaan sosial untuk menggali dan mendalami permasalahan yang ada serta turut memberikan solusi sesuai dengan minat dan keahliannya masing-masing</li>
                  </ul>
                </Space>
              </Col>
            </Row>
            <Row className="mt-32 text-align-center">
              <Col xs={{ span: 24 }} lg={{ span: 10 , offset: 7 }} xxl={{ span: 6, offset: 9 }}>
                <Link className="dark-color" to={Cookie.get("user") ? "/kandidat/program" : "/daftar"}>
                  <Button type="primary" className="border-radius-8 text-align-left pt-16 pb-16 card-next-proyek">
                    <Row justify="space-around" align="middle">
                      <Col>
                        <Space direction='vertical' size={0}>
                          <Text className="white-color text-card-next-top">COBA PENGALAMAN BARU?</Text>
                          <Text className="white-color text-card-next-bottom">AYO DAFTARKAN DIRI KAMU</Text>
                        </Space>
                      </Col>
                      <Col className="text-align-right" span={4}>
                        <RightCircleFilled className="text-card-next-icon" />
                      </Col>
                    </Row>
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
