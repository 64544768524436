import React from 'react';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { Grid } from 'antd';
import ProgramListDesktop from './Desktop';
import ProgramListMobile from './Mobile';
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function ProgramList(initialProps) {
  const { lg } = GetBreakPoint()
  
  if(process.env.REACT_APP_ENV === "production"){
    ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'Programshub - Program Opening');
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>MBKM - Program List</title>
      </Helmet>
      {
        lg ?
          <ProgramListDesktop {...initialProps} />
        :
          <ProgramListMobile {...initialProps} />
      }
    </React.Fragment>
  )
}