import React, { Suspense } from 'react';
import './index.less';
import store from './redux';
import AppRoute from './router';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { Row, Col, Spin } from 'antd';

const loadingMarkup = (
  <Row align="middle">
    <Col span={24}>
      <Spin size="large" />
    </Col>
  </Row>
)

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <Provider store={store}>
      <AppRoute />
    </Provider>
  </Suspense>,
  document.getElementById('root')
);
reportWebVitals();