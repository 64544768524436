import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Space, Image, Grid } from 'antd';
// import { MailOutlined } from '@ant-design/icons';
import Logo from '../../../assets/img/logo/LogoMBKM.png';
import LogoUniversitasBinawan from '../../../assets/img/logo-universitas-binawan.png';
import KampusMerdeka from '../../../assets/img/kampus-merdeka.png';

const { Text } = Typography;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function index() {
  const { pathname } = window.location
  const { md, lg } = GetBreakPoint()

  return (
    <React.Fragment>
      {
        pathname === '/daftar' || pathname === '/masuk' || pathname === '/daftar/konfirmasi-email' || pathname === '/lupa-kata-sandi' || pathname === '/lupa-kata-sandi/verify' || pathname === '/ganti-kata-sandi' || pathname === '/privacy-policy' || pathname === '/terms-and-conditions' || pathname === '/masuk-or-register' || pathname === '/employer/registration' || pathname === '/employer/registration/success' ?
          null
        :
          <React.Fragment>
            <Row style={{ paddingBottom: md ? 40 : 24, paddingTop: md ? 80 : 24 }}>
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
                <Row gutter={8}>
                  <Col className={!lg ? 'text-align-center' : null} xs={24} lg={9}>
                    <Space direction="vertical" size={16}>
                      <Link to="/">
                        <Image
                          width={120}
                          src={Logo}
                          preview={false}
                        />
                      </Link>
                      <Space className="mb-16" direction="vertical" size={0}>
                        <Text className="dark-color">Gedung Perkantoran Binawan Lobby 1, Lantai 3</Text>
                        <Text className="dark-color">Jl. Raya Kalibata - Dewi Sartika, No 25 - 30</Text>
                        <Text className="dark-color">DKI Jakarta - 13630</Text>
                      </Space>
                    </Space>
                  </Col>
                  {
                    md ?
                      <React.Fragment>
                        <Col xs={12} lg={3} >
                          <Space direction="vertical" size={16}>
                            <Text className="fs-16 primary-color">Program MBKM</Text>
                            <Space direction="vertical" size={4}>
                              <Link className="dark-color" to="/tentang-program/kewirausahaan">Kewirausahaan</Link>
                              <Link className="dark-color" to="/tentang-program/pertukaran-mahasiswa-merdeka">Pertukaran Pelajar</Link>
                              <Link className="dark-color" to="/tentang-program/magang">Magang</Link>
                              <Link className="dark-color" to="/tentang-program/proyek-kemanusiaan">Proyek Kemanusiaan</Link>
                              <Link className="dark-color" to="/tentang-program/membangun-desa-kkn">Membangun Desa/KKN</Link>
                            </Space>
                          </Space>
                        </Col>

                        <Col xs={12} lg={4}>
                          {/* <Space direction="vertical" size={16}>
                            <Text className="fs-16 primary-color">Kontak Info</Text>
                            <a href="mailto:support@jobshub.id?subject=What is your problem?"><MailOutlined /> mbkm@binawan.ac.id</a>
                          </Space> */}
                        </Col>

                        <Col xs={12} lg={3} style={{ textAlign: 'center', margin: 'auto' }}>
                          <Image
                            width={'100%'}
                            src={LogoUniversitasBinawan}
                            preview={false}
                            style={{ objectFit: 'cover' }}
                          />
                        </Col>

                        <Col xs={12} lg={3} style={{ textAlign: 'center', margin: 'auto' }}>
                          <Image
                            width={'100%'}
                            src={KampusMerdeka}
                            preview={false}
                            style={{ objectFit: 'cover' }}
                          />
                        </Col>
                      </React.Fragment>
                    :
                      <React.Fragment>
                        <Col xs={1}></Col>
                        <Col xs={11} >
                          <Space direction="vertical" size={6}>
                            <Text className="fs-16 primary-color">Program MBKM</Text>
                            <Space direction="vertical" size={4}>
                              <Link className="dark-color" to="/tentang-program/kewirausahaan">Kewirausahaan</Link>
                              <Link className="dark-color" to="/tentang-program/pertukaran-mahasiswa-merdeka">Pertukaran Pelajar</Link>
                              <Link className="dark-color" to="/tentang-program/magang">Magang</Link>
                              <Link className="dark-color" to="/tentang-program/proyek-kemanusiaan">Proyek Kemanusiaan</Link>
                              <Link className="dark-color" to="/tentang-program/membangun-desa-kkn">Membangun Desa/KKN</Link>
                            </Space>
                        </Space>
                        </Col>

                        <Col xs={11}>
                          {/* <Space direction="vertical" size={6}>
                            <Text className="fs-16 primary-color">Kontak Info</Text>
                            <a href="mailto:support@jobshub.id?subject=What is your problem?"><MailOutlined /> support@MBKM.id</a>
                          </Space> */}
                        </Col>
                        <Col xs={1}></Col>

                        <Col xs={1}></Col>
                        <Col xs={11} className="mt-16">
                          <Image
                            width={'100%'}
                            src={LogoUniversitasBinawan}
                            preview={false}
                            style={{ objectFit: 'cover' }}
                          />
                        </Col>
                        <Col xs={11} className="mt-16">
                          <Image
                            width={'100%'}
                            src={KampusMerdeka}
                            preview={false}
                            style={{ objectFit: 'cover' }}
                          />
                        </Col>
                        <Col xs={1}></Col>

                      </React.Fragment>
                  }
                </Row>
              </Col>
            </Row>
            <Row style={{ height: 40 }}>
              <Col span={24} style={{ background: '#32AB6D', textAlign: 'center', paddingTop: 10 }}>
                <Text style={{ color: '#ffffff', fontSize: md ? 14 : 10 }}>© 2022 MBKM Universitas Binawan. All Right Reserved</Text>
              </Col>
            </Row>
          </React.Fragment>
      }
    </React.Fragment>
  )
}