import React from 'react';
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
// import { Row, Col, Breadcrumb, Grid } from 'antd';
import { Row, Col, Breadcrumb } from 'antd';
import DetailProgramDesktop from './Desktop';
// import DetailProgramMobile from './Mobile';
// const { useBreakpoint } = Grid;

// function GetBreakPoint() {
//   const screens = useBreakpoint()
//   return screens;
// }

export default function ProgramDetail(initialProps) {
  const { getProgramDetail } = initialProps
  // const { xs } = GetBreakPoint()

  if(process.env.REACT_APP_ENV === "production"){
    ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], `${getProgramDetail?.data?.title} | MBKM`);
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{getProgramDetail.data.title} | MBKM</title>
      </Helmet>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          <Breadcrumb className="mb-16 mt-16">
            <Breadcrumb.Item>
              <Link to={Cookie.get('user') ? '/beranda' : '/'}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={Cookie.get('user') ? `/kandidat/program` : '/program'}>Program</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{getProgramDetail.data.title}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} xxl={{ span: 16, offset: 4 }}>
          {
            // xs ?
            //   <DetailProgramMobile {...initialProps} />
            // :
              <DetailProgramDesktop {...initialProps} />
          }
        </Col>
      </Row>
    </React.Fragment>
  )
}