import React from 'react';
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import { Row, Col, Grid, Card, Typography, Space, Image, Badge, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import Background from '../../assets/img/EmptyCompany.png';
import IconVerified from '../../assets/img/home/ic-verified.png';
import { programCategory } from '../../utils/constant/programCategory';

const { Text } = Typography;
const { useBreakpoint } = Grid;
function GetBreakPoint() {
  const screens = useBreakpoint()
  return screens;
}

export default function Program(props) {
  const { xs, xxl } = GetBreakPoint()
  const { getDataLanding } = props
  return (
    <React.Fragment>
      <Row className={xs ? "mt-32" : "mt-64"}>
        <Col className="text-align-center mb-32" span={24}>
          <Text className="fs-24 font-weight-500">Program MBKM</Text>
        </Col>
        <Col span={24}>
          <Row className="mb-32" gutter={[16, 16]}>
            {
              getDataLanding.data.listProgram.map((item,i) => 
                <Col className="xpert" key={i} xs={24} lg={6}>
                  <Badge.Ribbon placement='start' text={programCategory.find(res => res.value === item.programCategory)?.name} color={programCategory.find(res => res.value === item.programCategory)?.color} style={{ marginTop: 116 }}>
                    <Card
                      className="border-radius-8 dark-color box-shadow item bg-color-white" 
                      cover={
                        <img
                          alt="example"
                          src={item.imageUrl ? item.imageUrl : Background}
                          height={150}
                          style={{ borderRadius: '8px 8px 0 0', objectFit: 'cover', boxShadow: '0 1px 1px #e6e6e6' }}
                        />
                      }
                      bordered={false}
                    >
                      <Space className="mb-8" direction="vertical" size={0}>
                        {/* <Tooltip title={item.title}> */}
                          <Text className="info-color" ellipsis={true} style={{ fontSize: xxl ? 18 : 17, width: xxl ? 260 : 240 }}>{item.title}</Text>
                        {/* </Tooltip> */}
                      </Space>

                      <Row>
                        <Col span={24}>
                          <Space className='width-100' direction="vertical" size={4}>
                            {/* <Tooltip title={item.partnerName}> */}
                              <Text className="dark-color font-weight-500 fs-15" ellipsis={true} style={{ width: xxl ? 260 : 240 }}>{item.partnerName}</Text>
                            {/* </Tooltip> */}
                            <Space size={4}>
                              <Text className="info-color fs-14">{item.creditHour} SKS</Text>
                              <Text className="dark-color fs-14">• {item.duration} bulan</Text>
                            </Space>
                            <Row align="middle">
                              <Col span={20}>
                                {
                                  item.hasCertificate ?
                                    <Space size={4} align='middle'>
                                      <Image
                                        width={xxl ? 25 : 20}
                                        height={xxl ? 25 : 20}
                                        src={IconVerified}
                                        preview={false}
                                      />
                                      <Text className={xxl ? "fs-16 font-weight-500" : "fs-15 font-weight-500"} style={{ color: '#32AB6D' }}>
                                        Bersertifikat
                                      </Text>
                                    </Space>
                                  :
                                    null
                                }
                              </Col>
                              <Col span={4}>
                                <Link to={`${Cookie.get('user') ? '/kandidat' : ''}/program/detail/${item.id}`}>
                                  <Button className='float-right' type="primary" style={{ width: 100, borderRadius: 16 }} ghost={item.isApply}>{item.isApply ? 'Terdaftar': 'Daftar'}</Button>
                                </Link>
                              </Col>
                            </Row>
                          </Space>
                        </Col>
                      </Row>
                    </Card>
                  </Badge.Ribbon>
                </Col>
              )
            }
            <Col span={12}>
              <Row  align="middle" style={{ height: "100%" }}>
                <Col span={14} offset={5}>
                  <Space direction="vertical" size={32}>
                    <Space direction="vertical" size={0}>
                      <Text className="dark-color font-weight-500 fs-18">Kamu bisa memilih berbagai pilihan program</Text>
                      <Space>
                        <Text className="primary-color font-weight-500 fs-18">MBKM</Text>
                        <Text className="dark-color font-weight-500 fs-18">yang tersedia untukmu</Text>
                      </Space>
                    </Space>
                    <Link to={`${Cookie.get('user') ? '/kandidat' : ''}/program`}>
                      <Button type="primary" size="large" style={{ borderRadius: 32 }}>Lihat Program MBKM Lainnya <ArrowRightOutlined /></Button>
                    </Link>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}
