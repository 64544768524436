import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Button, Typography, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import DocumentForm from './form'
import moment from 'moment'
import { updateDocument, addDocument } from '../../../../redux/actions/profile/profileAction';
import { indexDocumentType, unmountIndexDocumentType } from '../../../../redux/actions/master/documentTypes/documentTypesAction';

const { Text } = Typography;

export class Document extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      submitLoading: false,
      deleteLoading: false,
      issueDate: null,
      expirationDate: null,
      current: false,
      isDate: false,
      file: null,
      showFileEdit: false,
      id: null,
      dataEdit: null

    }
  }

  componentDidMount() {
    const { actionGetDocumentTypes } = this.props

    return actionGetDocumentTypes()
  }
 
  showModal = (id) => {
    const { data } = this.props
    const dataEdit = data?.listDocument?.filter(item => item.id === id).pop()

    this.setState({
      visible: true,
      file: null,
      showFileEdit: id ? true : false,
      id: id,
      startDate: id ? dataEdit?.startDate : null,
      endDate: id ? dataEdit?.endDate : null,
      current: id ? dataEdit?.current : false,
      dataEdit: dataEdit,
      requiredFile: false
    })
  };
  
  handleCancel = () => {
    this.setState({
      visible: false,
      file: null,
      showFileEdit: false,
      current: false,
      isDate: false
    })
  };

  onChangeStartDate = (date, dateString) => {
    const { endDate } = this.state

    this.setState({
      startDate: dateString
    })
    
    if(endDate){
      if(endDate < dateString){
        message.error(('validationEndDate'), 5)
        this.formRef.current.setFieldsValue({
          endDate: null
        })
        this.setState({
          isDate: true
        })
      }else{
        this.setState({
          isDate: false
        })
      }
    }
  }

  onChangeEndDate = (date, dateString) => {
    const { startDate } = this.state

    this.setState({
      endDate: dateString
    })

    if(dateString < startDate){
      message.error('validationEndDate', 5)
      this.formRef.current.setFieldsValue({
        endDate: null
      })
      this.setState({
        isDate: true
      })
    }else{
      this.setState({
        isDate: false
      })
    }
  }

  handleChecked = (e) => {
    this.setState({
      current: e.target.checked
    })
    
    if(e.target.checked){
      this.setState({
        endDate: null
      })

      this.formRef.current.setFieldsValue({
        endDate: null
      })
    }
  }

  handleUpload(){
    return {
      withCredentials: true,
      // accept:".jpg, .jpeg, .pdf, .png",
      beforeUpload: file => {
        const validateSize = file.size >= 5000000;
        if (validateSize) {
          message.error('Max file size is 5MB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            file: file
          })
          message.info('Upload Success!');
        }
        return false;
      },
    }
  }

  handleRemove = (file) => {
    this.setState({ 
      file: null,
      showFileEdit: false,
      requiredFile: true
    })
    message.success(`${file.name} file removed successfully`);
  }

  // handleDelete = () => {
  //   const { actionDeleteDocument, actionGetProfile } = this.props
  //   const { id } = this.state
    
  //   return this.setState({ deleteLoading: true }, () => {
  //     return actionDeleteDocument(id, response => {
  //       if(response.code === 1000){
  //         return this.setState({ deleteLoading: false, visible: false, showFileEdit: false }, () => {
  //           message.success(response.message)
  //           return actionGetProfile()
  //         })
  //       }else{
  //         message.error(response.message)
  //       }
  //     }, (err) => {
  //       return this.setState({ deleteLoading: false, visible: false, showFileEdit: false }, () => message.error(err))
  //     })
  //   })
  // }

  onFinish = async (values) => {
    const { actionAddDocument, actionUpdateDocument, actionGetProfile } = this.props
    const { file, current, id, showFileEdit } = this.state

    values.documentNumber = values.documentNumber ? values.documentNumber : ""
    values.current = current
    values.issueDate = values.issueDate ? moment(values.issueDate).format('MM/YYYY') : ""
    if(current){
      delete values.expirationDate
    }else{
      values.expirationDate = values.expirationDate ? moment(values.expirationDate).format('MM/YYYY') : ""
    }
    if(file){
      values.file = file
    }else{
      delete values.file
    }
    values.note = values.note ? values.note : ""
    
    if(values.file || showFileEdit){
      if(id){
        values.id = id
        return this.setState({ submitLoading: true }, () => {
          return actionUpdateDocument(values, response => {
            if(response.code === 1000){
              return this.setState({ submitLoading: false, visible: false, showFileEdit: false }, () => {
                message.success(response.message)
                return actionGetProfile()
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false, visible: false, showFileEdit: false }, () => message.error(err))
          })
        })
      }else{
        return this.setState({ submitLoading: true }, () => {
          return actionAddDocument(values, response => {
            if(response.code === 1000){
              return this.setState({ submitLoading: false, visible: false }, () => {
                message.success(response.message)
                return actionGetProfile()
              })
            }else{
              message.error(response.message)
            }
          }, (err) => {
            return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
          })
        })
      }
    }else{
      message.error('Dokumen tidak boleh kosong!')
    }
  }

  render() {
    const { visible, submitLoading, deleteLoading, current, isDate, file, showFileEdit, id, dataEdit } = this.state
    const { data, getDocumentTypes } = this.props

    const addFileList = [
      {
        uid: '-1',
        name: file?.name,
        status: 'done'
      }
    ]

    const editFileList = [
      {
        uid: '-1',
        name: dataEdit?.fileName,
        status: 'done',
        url: dataEdit?.fileUrl,
        thumbUrl: dataEdit?.fileUrl,
      }
    ]

    const uploadDocument = (
      <Upload 
        {...this.handleUpload()} 
        onRemove={this.handleRemove}
        listType="picture" 
        maxCount={1}
        showUploadList={file || dataEdit ? true : false}
        fileList={file ? addFileList : showFileEdit ? editFileList : []}
      >
        <Button size="small" icon={<UploadOutlined />}><Text className="fs-12">Upload Dokumen</Text></Button>
      </Upload>
    )

    const initialProps = {
      data: data,
      getDocumentTypes: getDocumentTypes,
      visible: visible,
      current: current,
      formRef: this.formRef,
      isDate: isDate,
      submitLoading: submitLoading,
      deleteLoading: deleteLoading,
      id: id,
      documentFile: file,
      dataEdit: dataEdit,
      showModal:  this.showModal,
      handleCancel: this.handleCancel,
      onChangeStartDate: this.onChangeStartDate,
      onChangeEndDate: this.onChangeEndDate,
      handleChecked: this.handleChecked,
      onFinish: this.onFinish,
      // handleDelete: this.handleDelete,
      uploadDocument: uploadDocument,
      showFileEdit: showFileEdit,
      requiredFile: false
    }
    return (
      <React.Fragment>
        <DocumentForm {...initialProps} {...this.props}/>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountIndexDocumentType } = this.props
    return unmountIndexDocumentType()
  }

}

const mapStateToProps = (state) => ({
  getDocumentTypes: state.documentTypeReducer
})

const mapDispatchToProps = {
  actionUpdateDocument: updateDocument,
  actionAddDocument: addDocument,
  actionGetDocumentTypes: indexDocumentType,
  unmountIndexDocumentType: unmountIndexDocumentType
}

export default connect(mapStateToProps, mapDispatchToProps)(Document)