export const categoryReligion = [
  {
    "value": "ISLAM",
    "name": "Islam"
  },
  {
    "value": "CATHOLIC",
    "name": "Katolik"
  },
  {
    "value": "PROTESTAN",
    "name": "Protestan"
  },
  {
    "value": "BUDDHA",
    "name": "Buddha"
  },
  {
    "value": "HINDU",
    "name": "Hindu"
  },
  {
    "value": "OTHERS",
    "name": "Lainnya"
  }
]