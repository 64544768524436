const LOAD_DATA_HEADER         = 'LOAD_DATA_HEADER'
const LOAD_DATA_HEADER_SUCCESS = 'LOAD_DATA_HEADER_SUCCESS'
const LOAD_DATA_HEADER_FAILED  = 'LOAD_DATA_HEADER_FAILED'
const UNMOUNT_DATA_HEADER      = 'UNMOUNT_DATA_HEADER'

const initialState = {
  loading: true,
  data: null,
  message: null
}

const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DATA_HEADER:
      return {
        ...state,
        loading: true,
        data: null
      }
    case LOAD_DATA_HEADER_SUCCESS:
      return {
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS'
      }
    case LOAD_DATA_HEADER_FAILED:
      return {
        ...state, 
        loading: false,
        data: null,
        message: 'FAILED'
      }
    case UNMOUNT_DATA_HEADER:
      return { 
        ...state, 
        loading: true,
        data: null
      }
    default:
      return state
  }
}
export default headerReducer