import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { Loading } from '../../../components';
import Cookie from 'js-cookie';
import { Modal, message } from 'antd';
import ProgramDetailComponent from '../../../components/Program/Detail';
import { detailProgram, unmountDetailProgram } from '../../../redux/actions/program/programAction';
import { applyProgram } from '../../../redux/actions/applyProgram/applyProgramAction';
import { indexProfile, unmountIndexProfile } from '../../../redux/actions/profile/profileAction';
// import { addOrDeleteFavorite } from '../../../../redux/actions/favorite/favoriteAction';
import { listInstitution, unmountIndexInstitution } from '../../../redux/actions/master/institution/institutionAction';
import { listFieldOfStudy, unmountIndexFieldOfStudy } from '../../../redux/actions/master/fieldOfStudy/fieldOfStudyAction';
import ModalSuccess from './modal'

export class ProgramDetail extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      visible: false,
      visibleSuccess: false,
      programId: null,
      metaInstitution: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: ''
      },
      institutionId: [],
      fieldOfStudyId: [],
    }
    this.onSearchInstitution = debounce(this.onSearchInstitution.bind(this), 800)
    this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
  }
  componentDidMount() {
    const { actionGetProgramDetail, actionGetProfile, match: { params } } = this.props

    window.scrollTo(0, 0);
    
    actionGetProgramDetail(params.id)
    if(localStorage.getItem('visible')){
      this.setState({ visible: true })
    }
    if(Cookie.get('user')){
      actionGetProfile()
    }
    
  }
  
  onSearchInstitution = value => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;

    metaInstitution.page = 1
    metaInstitution.perpage = 10
    metaInstitution.search = value

    return actionListInstitution(metaInstitution)
  }

  onFocusInstitution = () => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;
    return actionListInstitution(metaInstitution)
  }

  onSearchFieldOfStudy = value => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;

    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;
    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onClickInstitution = (val, e) => {
    const { institutionId } = this.state;
    const addInstitutionId = [...institutionId, Number(e.key)]
    this.setState({ institutionId: addInstitutionId })
  }

  onClickFieldOfStudy = (val, e) => {
    const { fieldOfStudyId } = this.state;
    const addFieldOfStudyId = [...fieldOfStudyId, Number(e.key)]
    this.setState({ fieldOfStudyId: addFieldOfStudyId })
  }

  onDelete = (val) => {
    const { institutionId } = this.state;
    const deleteInstitutionId = institutionId.filter((item,i) => i !== val)
    this.setState({ institutionId: deleteInstitutionId })
  }


  showModal = () => {
    const { getProgramDetail, getProfile } = this.props
    this.setState({
      visible: true,
      programId: getProgramDetail.data.id,
      institutionId: [getProfile?.data?.institutionId],
      fieldOfStudyId: [getProfile?.data?.fieldOfStudyId]
    })
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      institutionId: [],
      fieldOfStudyId: []
    })
    if(localStorage.getItem('visible')){
      localStorage.removeItem('visible')
    }
  }

  modalValidateApply = () => {
    const { history } = this.props;
    Modal.info({
      title: 'Anda belum bisa daftar program',
      content: (
        <div>
          <p>harap lengkapi biodata dan data pendidikan terlebih dahulu!</p>
        </div>
      ),
      onOk() {
        return history.push('/profil')
      },
    });
  };

  handleUpload = () => {
    return {
      showUploadList: false,
      withCredentials: true,
      accept:".png,.jpg,.jpeg",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            profilePicFile: file, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      },
    }
  }
  
  onFinish = (values) => {
    const { institutionId, fieldOfStudyId } = this.state
    const { actionApplyProgram, actionGetProgramDetail, match: { params }  } = this.props
    values.programId = params.id
    if(values.listMember){
      values?.listMember?.map((item,i) => {
        return values.listMember[i].institutionId = institutionId[i]
      })
    }
    if(values.listMember){
      values?.listMember?.map((item,i) => {
        return values.listMember[i].fieldOfStudyId = fieldOfStudyId[i]
      })
    }
    return this.setState({ submitLoading: true }, () => {
      return actionApplyProgram(values, response => {
        if(response.code === 1000){
          setTimeout(() => {
            return this.setState({ submitLoading: false, visible: false, visibleSuccess: true })
          }, actionGetProgramDetail(params.id), 500)
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  handleCancelSuccess = () => {
    this.setState({ visibleSuccess: false });
  };

  handleFavorite = (id, isFavorite) => {
    const values = {
      jobId: id
    }

    const { actionAddOrDeleteFavorite, actionGetProgramDetail, match: { params } } = this.props
    
    return this.setState({ submitLoading: true }, () => {
      return actionAddOrDeleteFavorite(values, response => {
        if(response.code === 1000){
          return this.setState({ submitLoading: false }, () => {
            message.success(isFavorite ? 'Job has been removed from your favorite' : 'Job has been added to favorites')
            return (actionGetProgramDetail(params.slug))
          })
        }else{
          message.error(response.message)
        }
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err.message))
      })
    })
  }

  render() {
    const { 
      submitLoading,
      visible, 
      visibleSuccess
    } = this.state

    const { getProgramDetail } = this.props

    const initialProps = {
      submitLoading: submitLoading,
      modalValidateApply: this.modalValidateApply,
      showModal: this.showModal,
      handleCancel: this.handleCancel,
      visible: visible,
      onFinish: this.onFinish,
      handleUpload: this.handleUpload,
      formRef: this.formRef,
      // handleFavorite: (id, isFavorite) => this.handleFavorite(id, isFavorite)
      onFocusInstitution: this.onFocusInstitution,
      onSearchInstitution: this.onSearchInstitution,
      onClickInstitution: this.onClickInstitution,
      onFocusFieldOfStudy: this.onFocusFieldOfStudy,
      onSearchFieldOfStudy: this.onSearchFieldOfStudy,
      onClickFieldOfStudy: this.onClickFieldOfStudy,
      onDelete: this.onDelete
    }

    if(getProgramDetail.loading){
      return <Loading />
    }
    
    return (
      <React.Fragment>
        <Modal
          visible={visibleSuccess}
          onCancel={this.handleCancelSuccess}
          footer={false}
          width={600}
          bodyStyle={{ padding: 0, height: 250 }}
        >
          <ModalSuccess {...this.props} />
        </Modal>
        <ProgramDetailComponent {...initialProps} {...this.props} />
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountDetailProgram, unmountIndexProfile } = this.props
    unmountDetailProgram()
    unmountIndexProfile()
  }
}

const mapStateToProps = (state) => ({
  getProgramDetail: state.programDetailReducer,
  getProfile: state.profileReducer,
  getInstitution: state.institutionReducer,
  getFieldOfStudy: state.fieldOfStudyReducer
})

const mapDispatchToProps = {
  actionGetProgramDetail: detailProgram,
  unmountDetailProgram: unmountDetailProgram,
  actionApplyProgram: applyProgram,
  actionGetProfile: indexProfile,
  actionListInstitution: listInstitution,
  unmountIndexInstitution: unmountIndexInstitution,
  actionListFieldOfStudy: listFieldOfStudy,
  unmountIndexFieldOfStudy: unmountIndexFieldOfStudy,
  unmountIndexProfile: unmountIndexProfile
  // actionAddOrDeleteFavorite: addOrDeleteFavorite

}

export default connect(mapStateToProps, mapDispatchToProps)(ProgramDetail)
