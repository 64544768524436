import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import Cookie from 'js-cookie';
import { Form, Input, Card, Button, Row, Col, Typography, Checkbox, message } from 'antd'
import { setLogin } from '../../redux/actions/auth/authAction';
import './Login.css';

const { Text } = Typography

export class Login extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      username: Cookie.get('remember') ? Cookie.get('email') : null,
      password: Cookie.get('remember') ? Cookie.get('password') : null,
      remember: Cookie.get('remember') ? Cookie.get('remember') : false
    }
  }
  
  onFinish =  async (values) => {
    const { remember } = this.state
    const { actionSetLogin } = this.props
    
    if(remember){
      Cookie.set('email', values.username)
      Cookie.set('password', values.password)
      Cookie.set('remember', values.remember)
    }else{
      Cookie.remove('email')
      Cookie.remove('password')
      Cookie.remove('remember')
    }
    return actionSetLogin(values, response => {
      
    }, (err) => {
      return message.error('Email atau Kata Sandi anda salah. Silakan cek kembali!');
    })
  }
  
  render() {
    const { remember, username, password } = this.state

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'MBKM - Masuk');
    }

    const onChange = (e) => {
      this.setState({
        remember: e.target.checked
      })
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Masuk</title>
        </Helmet>
        <Row className="display-flex" align="middle" justify="center">
          <Col>
            <Card className="card-login" bodyStyle={{ padding: 0, display: 'flex' }}>
              <Form 
                name="normal_login" 
                layout="vertical" 
                onFinish={this.onFinish}
              >
                <Row gutter={16}>
                  <Col className="fs-28" xs={24} sm={24} md={24}>
                    <Text className="primary-color">Masuk</Text>
                  </Col>
                  <Col className="fs-13 mb-16" xs={24} sm={24} md={24}>
                    <Text type="secondary">Bangun pengalamanmu melalui program MBKM Universitas Binawan</Text>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Text type="secondary">Email</Text>
                    <Form.Item 
                      className="mb-16"
                      name="username"
                      initialValue={username}
                      rules={[
                        { required: true, message: 'Kolom ini wajib diisi!' },
                        { type: 'email', message: 'Email tidak valid!' }
                      ]}
                    >
                      <Input 
                        type="email"
                        placeholder="Masukan Email Anda"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Text type="secondary">Kata Sandi</Text>
                    <Form.Item 
                      className="mb-8" 
                      name="password"
                      initialValue={password}
                      rules={[
                        { required: true, message: 'Kolom ini wajib diisi!' }
                      ]}
                    >
                      <Input.Password
                        placeholder="Masukan Kata Sandi Anda"
                      />
                    </Form.Item>
                  </Col>
                  <Col className="mb-8" xs={24} sm={24} md={24}>
                    <Form.Item name="remember" noStyle>
                      <Checkbox className="dark-color fs-11" onChange={onChange} checked={remember}>Ingatkan Saya</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item className="mb-8">
                      <Button type="primary" htmlType="submit" block>
                        Masuk
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className="fs-13" xs={12} sm={12} md={12}>
                    <Text type="secondary">
                    Belum memiliki akun? <Link className="info-color" to="/daftar">Daftar sekarang</Link>
                    </Text>
                  </Col>
                  <Col className="fs-13" xs={12} sm={12} md={12}>
                    <Link className="info-color float-right" to="/lupa-kata-sandi">Lupa kata sandi?</Link>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionSetLogin: setLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)