import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { setActivation } from '../../../redux/actions/register/registerAction';
import { setResend } from '../../../redux/actions/check/checkAction';
import { Form, Input, Button, Row, Col, Card, Typography, Space, Statistic, message } from 'antd';
import './ConfirmEmail.css';

const { Countdown } = Statistic;
const { Text } = Typography;

export class ConfirmEmail extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      isCountdown: true,
      countdown: Date.now() + 60 * 2000
    }
  }

  onFinish = (values) => {
    const { actionSetActivation } = this.props

    values.email = localStorage.getItem('email')
    values.password = localStorage.getItem('password')

    return actionSetActivation(values, response => {
      if(response.code === 1000){
        this.setState({ submitLoading: false }, () => {
          localStorage.removeItem('email')
          localStorage.removeItem('password')
          // this.props.history.push('/beranda')
        })
      }else{
        message.error(response.message)
      }
    }, (err) => {
      message.error(err)
    })
  }

  onClickResend = () => {
    const value = {
      email: localStorage.getItem('email')
    }

    const { actionSetResend } = this.props

    actionSetResend(value, response => {
      message.success(response.message)
      this.setState({
        isCountdown: true,
        countdown: Date.now() + 60 * 2000
      })
    }, (err) => {
      message.error(err)
    })
  }

  onFinishCountdown = () => {
    this.setState({
      isCountdown: false
    })
  }

  render() {
    const { isCountdown, countdown } = this.state

    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(`${window.location.pathname}${window.location.search}`, [], 'MBKM - Konfirmasi Email');
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>MBKM - Konfirmasi Email</title>
        </Helmet>
        <Row className="text-align-center" align="middle" justify="center">
          <Col>
            <Card className="card-confirm-email" bodyStyle={{ padding: 0, display: 'flex' }}>
              <Row>
                <Col span={24}>
                  <Form onFinish={this.onFinish}>
                    <Space className="width-100" align="center" direction="vertical" size={16}>
                      <Text className="title-confirm-email primary-color font-weight-500 mb-0">Konfirmasi email Anda</Text>
                      <Text className="dark-color font-weight-normal">Masukan kode yang telah kami kirim ke email <Text className="primary-color">{localStorage.getItem('email')}</Text></Text>
                    </Space>
                    <Form.Item name="activationCode">
                      <Input className="input-code text-align-center mt-32 mb-16" placeholder="_  _  _  _  _  _" size="large" />
                    </Form.Item>
                    <Button className="width-100 mb-8" htmlType="submit" type="primary">Konfirmasi</Button>
                    <Space size={4}>
                      <Text className="fs-11 text-align-left" type="secondary">Tidak menerima kode?</Text>
                      {
                        isCountdown ?
                        <Countdown value={countdown} onFinish={this.onFinishCountdown} format="mm:ss" valueStyle={{ fontSize: 11 }} />
                        :
                        <Text className="fs-12 info-color cursor-pointer" onClick={this.onClickResend}>Kirim ulang kode</Text>
                      }
                    </Space>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
  actionSetActivation: setActivation,
  actionSetResend: setResend,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail)
