import { combineReducers } from 'redux';
import authReducer from './auth/authReducer'
import registerReducer from './register/registerReducer';
import institutionReducer from './master/institution/institutionReducer';
import positionReducer from './master/position/positionReducer';
import fieldOfStudyReducer from './master/fieldOfStudy/fieldOfStudyReducer';
import countryReducer from './master/country/countryReducer';
import provinceReducer from './master/province/provinceReducer';
import cityReducer from './master/city/cityReducer';
import subDistrictReducer from './master/subDistrict/subDistrictReducer';
import villageReducer from './master/village/villageReducer';
import documentTypeReducer from './master/documentTypes/documentTypeReducer';
import landingPageReducer from './landingPage/landingPageReducer';

import headerReducer from './header/headerReducer';
import profileReducer from './profile/profileReducer';
import programReducer from './program/programReducer';
import programDetailReducer from './program/programDetailReducer';
import newsReducer from './news/newsReducer';
import newsDetailReducer from './news/newsDetailReducer';
import listApplicationsReducer from './statusLamaran/listApplicationsReducer';
import detailApplicationsReducer from './statusLamaran/detailApplicationsReducer';

import fileManagementReducer from './fileManagement/fileManagementReducer';
import beritaDetailReducer from './berita/beritaDetailReducer';


export default combineReducers({
    authReducer,
    registerReducer,
    institutionReducer,
    positionReducer,
    fieldOfStudyReducer,
    countryReducer,
    provinceReducer,
    cityReducer,
    subDistrictReducer,
    villageReducer,
    documentTypeReducer,
    landingPageReducer,

    headerReducer,
    profileReducer,
    programReducer,
    programDetailReducer,
    newsReducer,
    newsDetailReducer,
    listApplicationsReducer,
    detailApplicationsReducer,

    fileManagementReducer,

    beritaDetailReducer
})